import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  TableCell,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { CustomButton } from "../../../globalStyle";

export const AdDrawerStyledButton = styled(Button)<{
  borderNone?: boolean;
  textColor?: string;
}>`
  && {
    border-radius: 2rem !important;
    color: ${({ theme, textColor }) =>
      textColor ? textColor : theme.colors.black2};
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
    border: ${({ borderNone, theme }) =>
      borderNone ? "none" : `1px solid ${theme.colors.border1}`};
    padding: 0.438rem 0.75rem;
    display: flex;
    gap: 0.625rem;
  }
`;

export const StyledAccordion = styled(Accordion)`
  && {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 10px !important;
  }
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
  && {
    font-size: 1rem;
    font-weight: 600;
    min-height: 3rem;
    color: ${({ theme }) => theme.colors.black2};
    &.Mui-expanded {
      min-height: 4rem;
    }

    .MuiAccordionSummary-content {
      align-items: center;
      margin: 0 !important;
      gap: 0.625rem;
    }
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  && {
    border-top: 1px solid ${({ theme }) => theme.colors.border1};
    padding: 0.5rem 1rem 1rem;
  }
`;

export const CommentsCount = styled.div`
  padding: 0.375rem 0.938rem;
  background-color: ${({ theme }) => theme.colors.background4};
  border-radius: 25px;
`;

export const StyledGridItem = styled(Grid)`
  && {
    overflow-y: auto;
    scrollbar-width: none;
    max-height: 100%;
    display: flex;
  }
`;

export const MetadataSectionText = styled(Typography)<{ textColor: string }>`
  && {
    font-size: 1rem;
    font-weight: 500;
    color: ${({ textColor }) => textColor};
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    border-bottom: none;
    padding: 0.438rem 1.25rem 0.438rem 0;
  }
`;

export const SaveTranscriptButton = styled(CustomButton)`
  border-radius: 2rem !important;
  color: white !important;
  background: ${({ theme }) => theme.colors.backgroundPrimary} !important;
  && {
    padding: 0.25rem 0.925rem;
  }
  border: none;
`;
