import axiosClient from "../apiClient";

export const fetchDashboardAnalyticsService = async () => {
  try {
    const response = await axiosClient.get("/analytics/user/dashboard");
    return response;
  } catch (error) {
    return error;
  }
};

export const createContactUsService = async (params: any) => {
  try {
    const reqBody = params.body;
    const response = await axiosClient.post("/feedback", reqBody);
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchDashboardAdAnalyticsService = async () => {
  try {
    const response = await axiosClient.get("/analytics");
    return response;
  } catch (error) {
    return error;
  }
};

export const fetchRecommendedPlans = async () => {
  try {
    const response = await axiosClient.get("/plan/recommendedplan");

    return response;
  } catch (error) {
    return error;
  }
};

export const fetchTeamMemberSavedAds = async (memberId: string) => {
  try {
    const response = await axiosClient.get(`/team/member/${memberId}`);
    return response;
  } catch (error) {
    return error;
  }
};
