import { Box, DialogTitle, Divider, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
} from "./style";
import { CloseIcon } from "../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { DashoardAnalyticsReducerAction } from "../../store/dashboardAnalytics/slice";
import { openViewTranscriptModalSelector } from "../../store/dashboardAnalytics/selector";
import { ButtonText } from "../../pageComponents/style";
import { downloadTranscript } from "../../utils/helpers";

const ViewTranscriptModal = ({
  transcriptText,
  transcriptName,
}: {
  transcriptText: string;
  transcriptName: string;
}) => {
  const dispatch = useDispatch();
  const openModal = useSelector(openViewTranscriptModalSelector);

  const handleCloseModal = () => {
    dispatch(DashoardAnalyticsReducerAction.setOpenViewTranscriptModal(false));
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <Stack>
            <ModalTitle>{transcriptName}</ModalTitle>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <Box
        sx={{
          height: "10rem",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          padding: "1.125rem 1.875rem 1.875rem 1.875rem",
          gap: "3rem",
        }}
      >
        <ButtonText>{transcriptText}</ButtonText>
      </Box>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={() => downloadTranscript(transcriptName, transcriptText)}
      >
        Download
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default ViewTranscriptModal;
