import { Box } from "@mui/material";
import styled from "styled-components";

export const NotificationWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.938rem;
  padding: 3.125rem 13.625rem 4.25rem;

  @media (max-width: 1440px) {
    && {
      padding: 3.125rem 8rem 4.25rem
    }
  }
`;
