import styled from "styled-components";

export const HeaderNavigation = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`;

export const MainSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 11.25rem;
`;

export const MainSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainSectionHeading = styled.h1`
  font-weight: 600;
  font-size: 3.625rem;
  text-align: center;
  line-height: 5.383rem;
  color: white;
`;

export const MainSectionSubHeading = styled.p`
  padding: 1.25rem 2.875rem 0;
  text-align: center;
  font-weight: 400;
  font-size: 1.25rem;
  color: white;
`;

export const CardHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  gap: 0.938rem;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
`;

export const CardName = styled.h4`
  font-weight: 600;
  font-size: 1.125rem;
`;

export const CardPrice = styled.h3`
  font-weight: 700;
  font-size: 1.5rem;
`;

export const CardPlanDescription = styled.span`
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.25rem;
`;

export const CardDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
`;

export const CardDetailsHeader = styled.h4`
  font-weight: 600;
  font-size: 1rem;
  color: white;
  margin-bottom: 1.25rem;
`;

export const CardDetailListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 0.625rem;
`;

export const CardDetailList = styled.li`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: white;
`;

export const CardDetailListText = styled.span`
  font-weight: 400;
  font-size: 0.938rem;
`;

export const CardButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const MainSectionSubHeadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.875rem;
`;
