import { Button, Typography } from "@mui/material";
import styled from "styled-components";

export const TableToolbarSection = styled.div`
  border-bottom: 1px solid #eaecee;
  background-color: ${({ theme }) => theme.colors.background4};
  padding: 1.125rem 1.875rem 1.125rem 3.125rem;
`;

export const StyledToolbar = styled.div`
  border: 1px solid #eaecee;
  background-color: white;
  padding: 0.625rem 1.25rem;
  border-radius: 29px;
  display: flex;
  gap: 1.813rem;
  align-items: center;
`;

export const ToolbarButton = styled(Button)`
  && {
    text-transform: capitalize;
    background: none !important;
  }
`;

export const StyledText = styled(Typography)<{ textColor: string }>`
  && {
    font-size: 1rem;
    font-weight: 600;
    color: ${({ textColor }) => textColor};
  }
`;
