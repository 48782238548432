import Header from "../../components/header";
import { Container, HeaderSection, ListContainer } from "../style";
import CreateBoardModal from "../../components/modals/createBoardModal";
import UserInfoCard from "../../components/dashboardSections/userInfoCard";
import { CircularProgress, Stack } from "@mui/material";
import DownloadExtensionCard from "../../components/dashboardSections/downloadExtensionCard";
import SwipeAdSection from "../../components/dashboardSections/swipeAdSection";
import ExploreLoungeSection from "../../components/dashboardSections/exploreLoungeSection";
import TranscriptLoungeSection from "../../components/dashboardSections/transcriptSection";
import LearnSwipeLoungeSection from "../../components/dashboardSections/learnSwipeLoungeSection"
import ContactUsSection from "../../components/dashboardSections/contactUsSection"
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import {
  DashoardAnalyticsSaga,
  DashoardAnalyticsSagaActions,
  DashoardAnalyticsSagaName,
} from "../../store/dashboardAnalytics/sagas";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DashoardAnalyticsReducer,
  DashoardAnalyticsReducerName,
} from "../../store/dashboardAnalytics/slice";
import { dashboardLoadingSelector } from "../../store/dashboardAnalytics/selector";
import OpenViewFolderModal from "../../components/modals/openViewFolderModal";
import { Grid } from "@mui/material";
import CreateTeamModal from "../../components/modals/createTeamModal";
import { DiscoverySagaActions } from "../../store/discovery/sagas";
import { discoveryBrandsParamsSelector, discoveryBrandsSelector } from "../../store/discovery/selector";
import SubscriptionPlansSection from "../../components/dashboardSections/subscriptionPlansSection";

const content = {
  tabTitle: "Dashboard",
  tabSubTitle: "Your Creative Strategy Hub",
};
const Dashboard = () => {
  useInjectReducer({
    reducer: DashoardAnalyticsReducer,
    key: DashoardAnalyticsReducerName,
  });

  useInjectSaga({
    saga: DashoardAnalyticsSaga,
    key: DashoardAnalyticsSagaName,
  });

  const dispatch = useDispatch();
  const dashboardLoading = useSelector(dashboardLoadingSelector);
  const discoveryBrandLists = useSelector(discoveryBrandsSelector);
  const discoveryBrandsParams = useSelector(discoveryBrandsParamsSelector);

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchFavouriteBrands());
    dispatch(DashoardAnalyticsSagaActions.fetchDashboardAnalyticsData());
    dispatch(DashoardAnalyticsSagaActions.fetchDashboardAdAnalyticsData());
    dispatch(MetaDataSagaActions.fetchTagLists());
    dispatch(MetaDataSagaActions.fetchBoardList());
    dispatch(DiscoverySagaActions.fetchDiscoveryList());
    if (discoveryBrandLists?.data?.length === 0) {
      dispatch(
        DiscoverySagaActions.fetchDiscoveryTopBrandList(discoveryBrandsParams)
      );
    }
    dispatch(MetaDataSagaActions.teamDetails());
  }, []);

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        <ListContainer display={"flex"} flexDirection={"column"} gap="1.875rem">
          {dashboardLoading ? (
            <CircularProgress sx={{ placeSelf: "center" }} />
          ) : (
            <>
              <Stack direction="row" gap="1.875rem">
                <UserInfoCard />
                <DownloadExtensionCard />
              </Stack>
              <Grid container spacing={"1.875rem"}>
                <Grid item sm={6}>
                  <SubscriptionPlansSection />
                </Grid>
                <Grid item sm={6}>
                  <LearnSwipeLoungeSection />
                </Grid>
              </Grid>
              <SwipeAdSection />
              <ExploreLoungeSection />
              <Stack direction="row" gap="1.875rem">
                <TranscriptLoungeSection />
                <ContactUsSection />
              </Stack>
            </>
          )}
        </ListContainer>
      </HeaderSection>
      <CreateBoardModal />
      <OpenViewFolderModal />
      <CreateTeamModal />
    </Container>
  );
};

export default Dashboard;
