import { NotificationClose} from "../../assets/Icons";
import {NotificationCloseButtonWrapper} from "../../globalStyle"

const NotificationCloseButton = ({ closeToast }: { closeToast: Function }) => {
    return (
        <NotificationCloseButtonWrapper onClick={() => closeToast()}>
            <NotificationClose/>
        </NotificationCloseButtonWrapper>
    )
}
export default NotificationCloseButton;