import { Box, Grid, Stack, Typography } from "@mui/material";
import {
  CardContainer,
  CardSubTitle,
  CardTitle,
  DashboardCardButton,
  GrayBgHeader,
  GrayBgHeaderText,
} from "./style";
import {
  ArrowRightIcon,
  DownloadIcon,
  EyeIcon,
  FileIcon,
} from "../../assets/Icons";
import { useTheme } from "styled-components";
import { CustomButton, StyledDivider } from "../../globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { dashboardAnalyticsSelector } from "../../store/dashboardAnalytics/selector";
import { transcriptObject } from "../../store/discovery/types";
import ViewTranscriptModal from "../modals/viewTranscriptModal";
import { useState } from "react";
import { DashoardAnalyticsReducerAction } from "../../store/dashboardAnalytics/slice";
import { downloadTranscript } from "../../utils/helpers";
import { userTranscript } from "../../store/dashboardAnalytics/types";

const TranscriptLoungeSection = () => {
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [transcriptName, setTranscriptName] = useState<string>("");
  const theme = useTheme();
  const dispatch = useDispatch();
  const dashboardAnalytics = useSelector(dashboardAnalyticsSelector);

  const handleDownloadTranscript = (
    transcript: transcriptObject,
    fileName: string
  ) => {
    const transcriptText =
      transcript?.results?.channels?.[0]?.alternatives[0]?.transcript;
    downloadTranscript(fileName, transcriptText);
  };

  const openViewTranscriptModal = (
    transcript: transcriptObject,
    transcriptName: string
  ) => {
    const transcriptText =
      transcript?.results?.channels?.[0]?.alternatives[0]?.transcript;
    setTranscriptName(transcriptName);
    setTranscriptText(transcriptText);

    dispatch(DashoardAnalyticsReducerAction.setOpenViewTranscriptModal(true));
  };

  return (
    <>
      <CardContainer sx={{width: "50%", padding:"1.25rem 0"}}>
        <CardTitle sx={{ padding:"0 1.25rem"}}>Transcript Lounge</CardTitle>
        <CardSubTitle sx={{ padding:"0 1.25rem"}}>
          {dashboardAnalytics?.userTranscripts?.length} Transcripts
        </CardSubTitle>
        {
          dashboardAnalytics?.userTranscripts?.length > 0 ?
              (
                  <>
                <GrayBgHeader margin="1.25rem 0">
                  <GrayBgHeaderText>Saved transcript for easy access</GrayBgHeaderText>
                </GrayBgHeader>
                <Grid container spacing={"1rem"} sx={{ padding:"0 1.25rem", height:"26.625rem", overflowY: "scroll"}}>
                  {dashboardAnalytics?.userTranscripts?.map(
                      (transcript: userTranscript, index: number) => (
                          <Grid item sm={6} key={index}>
                            <CardContainer
                                display={"flex"}
                                justifyContent={"space-between"}
                                padding={"0.938rem 0.75rem 0.938rem 1.25rem"}
                            >
                              <Stack direction="row" gap="0.625rem" alignItems={"center"}>
                                <FileIcon />
                                <Typography
                                    fontSize="1rem"
                                    fontWeight="500"
                                    color={theme.colors.black2}
                                >
                                  {transcript.name}
                                </Typography>
                              </Stack>
                              <Stack direction="row" gap="0.938rem" alignItems={"stretch"}>
                                <CustomButton
                                    sx={{ background: theme.colors.background4 }}
                                    onClick={() =>
                                        handleDownloadTranscript(
                                            transcript.transcript,
                                            transcript.name
                                        )
                                    }
                                >
                                  <DownloadIcon />
                                </CustomButton>
                                <CustomButton
                                    sx={{ background: theme.colors.background4 }}
                                    onClick={() =>
                                        openViewTranscriptModal(
                                            transcript.transcript,
                                            transcript.name
                                        )
                                    }
                                >
                                  <EyeIcon />
                                </CustomButton>
                              </Stack>
                            </CardContainer>
                          </Grid>
                      )
                  )}
                </Grid>
                </>
              ) : (
                  <>
                    <StyledDivider sx={{ margin: "1.25rem 0" }} />
                    <Box sx={{
                      margin:"25% 0",
                      display: "flex", height:"inherit", alignItems: "center", justifyContent: "center"
                    }}>
                      <Typography
                          fontSize="1.5rem"
                          fontWeight="700"
                          color={theme.colors.black2}
                          sx={{opacity: "30%"}}
                      >
                        Saved Transcripts will be listed here
                      </Typography>
                    </Box>
                  </>
              )
        }


        {/* <StyledDivider sx={{ margin: "1.25rem -1.25rem" }} />
      <Box display={"flex"}>
        <DashboardCardButton
          endIcon={<ArrowRightIcon />}
          sx={{
            margin: "0.5rem auto",
          }}
        >
          View All
        </DashboardCardButton>
      </Box> */}
      </CardContainer>
      <ViewTranscriptModal
        transcriptText={transcriptText}
        transcriptName={transcriptName}
      />
    </>
  );
};

export default TranscriptLoungeSection;
