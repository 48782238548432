import axiosClient from "../apiClient";
import { commentParamType } from "../store/metadata/types";

export const addCommentService = async (params: commentParamType) => {
  try {
    const response = await axiosClient.post(
      `/ad/comment/add/${params._id}`,
      params.payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteCommentService = async (id: string) => {
  try {
    const response = await axiosClient.delete(`/ad/comment/delete/${id}`);
    return response;
  } catch (error) {
    return error;
  }
};
