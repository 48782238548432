import { Route, Routes } from "react-router-dom";
import "./App.css";
// Components
import Login from "./pageComponents/auth/login";
import Discovery from "./pageComponents/discovery";
import SwipeFiles from "./pageComponents/swipeFiles";
import SignupForm from "./pageComponents/auth/signup";
import MainLayout from "./components/mainLayout";
import GuestGuard from "./routes/GuestGuard";
import Dashboard from "./pageComponents/dashboard";
import ErrorPage from "./pageComponents/errorPage";
import routeConstant from "./utils/routeConstant";
import { CarouselProvider } from "./context/CarouselContext";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  DiscoveryReducer,
  DiscoveryReducerName,
} from "./store/discovery/slice";
import { DiscoverySaga, DiscoverySagaName } from "./store/discovery/sagas";
import { MetaDataReducer, MetaDataReducerName } from "./store/metadata/slice";
import { MetaDataSaga, MetaDataSagaName } from "./store/metadata/sagas";
import Settings from "./pageComponents/settings";
import { AccountReducer, AccountReducerName } from "./store/account/slice";
import { AccountSaga, AccountSagaName } from "./store/account/sagas";
import {
  SwipeFileReducer,
  SwipeFileReducerName,
} from "./store/swipeFile/slice";
import { SwipeFileSaga, SwipeFileSagaName } from "./store/swipeFile/sagas";
import Starred from "./pageComponents/starred";
import { TagsSaga, TagsSagaName } from "./store/tags/sagas";
import { TagsReducer, TagsReducerName } from "./store/tags/slice";
import ForgotPassword from "./pageComponents/auth/forgotPassword";
import ResetPassword from "./pageComponents/auth/resetPassword";
import Notification from "./pageComponents/notification";
import TermsConditions from "./pageComponents/termsConditions";
import Subscription from "./pageComponents/subscription";
import PaymentHistoryPage from "./pageComponents/paymentHistory";
import AdDetails from "./components/commonComponents/adDetails";

const App = () => {
  useInjectReducer({
    reducer: DiscoveryReducer,
    key: DiscoveryReducerName,
  });

  useInjectReducer({
    reducer: MetaDataReducer,
    key: MetaDataReducerName,
  });

  useInjectReducer({
    reducer: AccountReducer,
    key: AccountReducerName,
  });

  useInjectReducer({
    reducer: SwipeFileReducer,
    key: SwipeFileReducerName,
  });

  useInjectReducer({
    reducer: TagsReducer,
    key: TagsReducerName,
  });

  useInjectSaga({
    saga: DiscoverySaga,
    key: DiscoverySagaName,
  });

  useInjectSaga({
    saga: MetaDataSaga,
    key: MetaDataSagaName,
  });
  useInjectSaga({
    saga: AccountSaga,
    key: AccountSagaName,
  });

  useInjectSaga({
    saga: SwipeFileSaga,
    key: SwipeFileSagaName,
  });

  useInjectSaga({
    saga: TagsSaga,
    key: TagsSagaName,
  });

  return (
    <Routes>
      {/* Public Routes */}
      <Route
        path={routeConstant?.login}
        element={
          <CarouselProvider>
            <Login />
          </CarouselProvider>
        }
      />
      <Route
        path={routeConstant?.signup}
        element={
          <CarouselProvider>
            <SignupForm />
          </CarouselProvider>
        }
      />
      <Route
        path={routeConstant?.forgotPassword}
        element={
          <CarouselProvider>
            <ForgotPassword />
          </CarouselProvider>
        }
      />
      <Route
        path={routeConstant?.termsConditions}
        element={<TermsConditions />}
      />

      <Route
        path={`${routeConstant?.resetPassword}/:token`}
        element={
          <CarouselProvider>
            <ResetPassword />
          </CarouselProvider>
        }
      />

      <Route
        path={`${routeConstant?.subscription}`}
        element={<Subscription />}
      />

      <Route path="/" element={<MainLayout />}>
        {/* Protected Routes */}
        <Route element={<GuestGuard />}>
          <Route path={routeConstant?.dashboard} element={<Dashboard />} />
          <Route path={routeConstant?.discovery} element={<Discovery />} />
          <Route path={routeConstant?.swipeFiles} element={<SwipeFiles />} />
          <Route
            path={`/:categoryType${routeConstant?.adDetails}/:libraryId`}
            element={<AdDetails />}
          />
          <Route path={routeConstant?.team} />
          <Route path={routeConstant?.help} />
          <Route path={routeConstant?.settings} element={<Settings />} />
          <Route path={routeConstant?.starred} element={<Starred />} />
          <Route
            path={routeConstant?.notification}
            element={<Notification />}
          />
          <Route
            path={routeConstant?.paymentHistory}
            element={<PaymentHistoryPage />}
          />
        </Route>

        {/* Other Routes */}
      </Route>
      <Route path={routeConstant?.error} element={<ErrorPage />} />
    </Routes>
  );
};

export default App;
