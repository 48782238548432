import { Box, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import {
  CloseIconWrapper,
  FolderTitle,
  ModalTitle,
  StyledDialog,
} from "./style";
import { CloseIcon } from "../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import {
  folderItemSelector,
  openViewFolderModalSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { BoardItem } from "../../store/metadata/types";
import { EditableBoardCard } from "../editableBoardCard/editableBoardCard";
import { useTheme } from "styled-components";

const OpenViewFolderModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const openModal = useSelector(openViewFolderModalSelector);

  const folderItem = useSelector(folderItemSelector);

  const handleCloseModal = () => {
    dispatch(MetaDataReducerAction.setFolderItem(null));
    dispatch(MetaDataReducerAction.setOpenViewFolderModal(false));
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack
          direction="row"
          gap="0.75rem"
          alignItems="center"
          justifyContent="center"
        >
          <Stack>
            <ModalTitle>
              My Collections : <FolderTitle>{folderItem?.name}</FolderTitle>
            </ModalTitle>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <Box
        sx={{
          height: "25rem",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          padding: "1.125rem 1.875rem 1.875rem 1.875rem",
          gap: "1.25rem",
          alignItems: "center",
          position: "relative",
        }}
      >
        {Array.isArray(folderItem?.boards) && folderItem?.boards.length > 0 ? (
          folderItem?.boards?.map((board: BoardItem) => (
            <EditableBoardCard
              item={board}
              folderId={folderItem._id}
              key={board._id}
            />
          ))
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontWeight: "600",
              fontSize: "1.25rem",
              letterSpacing: "0.038rem",
              textAlign: "center",
              marginBottom: "0.938rem",
              color: theme.colors.neutral50,
              maxWidth: "29rem",
              position: "absolute",
              top: "9rem",
            }}
          >
            This Folder has no Boards currently. Please add new Boards and Save
            Ads for you and your team.
          </Typography>
        )}
      </Box>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
    </StyledDialog>
  );
};

export default OpenViewFolderModal;
