import {
    CardContainer,
    CardSubTitle,
    CardTitle,
    ContactUsWrapper,
    StyledContactUsInputLabel,
    ContactFormErrorMessage,
    StyledTextArea
} from "./style";
import { CircularProgress } from "@mui/material";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../globalStyle";
import {useDispatch} from "react-redux";
import {StyledContainedButton, StyledFormControl, StyledTextField} from "../modals/style";
import {useForm} from "react-hook-form";
import {ContactUsFormProps} from "../../utils/propTypes";
import {yupResolver} from "@hookform/resolvers/yup";
import {contactUsValidationSchema} from "../../utils/formValidationSchema";
import {DashoardAnalyticsSagaActions} from "../../store/dashboardAnalytics/sagas";
import {useState} from "react";
const ContactUsSection = () => {
    const {
        getValues,
        handleSubmit,
        register,
        reset,
        formState: { errors },
    } = useForm<ContactUsFormProps>({
        resolver: yupResolver(contactUsValidationSchema),
    });
    const theme = useTheme();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const handleLoadingState = () => {
        setLoading(false);
    };
    const onSubmit = () => {
        const values = getValues();
        setLoading(true);
        dispatch(
            DashoardAnalyticsSagaActions.createContactUs({ values: values, handleLoadingState })
        );
        reset();
    };
    return (
        <CardContainer  sx={{
            width: "50%",
            padding: "1.25rem 0"
        }}>
            <CardTitle sx={{ margin: "0 1.25rem"}}>Contact Us</CardTitle>
            <CardSubTitle sx={{ margin: "0 1.25rem"}}>Our team is happy to answer your questions.</CardSubTitle>
            <StyledDivider sx={{ margin: "1.25rem 0" }} />
            <ContactUsWrapper>
                <StyledFormControl fullWidth>
                    <StyledContactUsInputLabel htmlFor="name">Name</StyledContactUsInputLabel>
                    <StyledTextField
                        id="name"
                        variant="outlined"
                        {...register("name", { required: true })}
                        sx={{ fieldset:{height:"3.375rem"}, input:{ padding: "0.75rem 0.875rem" } }}
                    />
                    {errors.name?.message && (
                        <ContactFormErrorMessage>{errors.name?.message}</ContactFormErrorMessage>
                    )}
                </StyledFormControl>

                <StyledFormControl fullWidth>
                    <StyledContactUsInputLabel htmlFor="email">
                        Email
                    </StyledContactUsInputLabel>
                    <StyledTextField
                        id="email"
                        variant="outlined"
                        required
                        {...register("email")}
                        sx={{ fieldset:{height:"3.375rem"}, input:{ padding: "0.75rem 0.875rem" } }}
                    />
                    {errors.email?.message && (
                        <ContactFormErrorMessage>{errors.email?.message}</ContactFormErrorMessage>
                    )}
                </StyledFormControl>
                <StyledFormControl fullWidth>
                    <StyledContactUsInputLabel htmlFor="message">
                        Message
                    </StyledContactUsInputLabel>
                    <StyledTextArea
                        id="message"
                        required
                        {...register("message")}
                        minRows={6}
                        maxRows={6}
                    />
                    {errors.message?.message && (
                        <ContactFormErrorMessage>{errors.message?.message}</ContactFormErrorMessage>
                    )}
                </StyledFormControl>
                <StyledContainedButton
                    variant="contained"
                    size="small"
                    sx={{
                        width: "6.25rem",
                        height: "2.5rem",
                        padding: "0.714rem 1.428rem",
                    }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading}
                    startIcon={loading && <CircularProgress size={20} color="inherit" />}
                >
                    Submit
                </StyledContainedButton>
            </ContactUsWrapper>

        </CardContainer>
    );
};

export default ContactUsSection;
