import React, { memo } from "react";
import { StyledGridItem } from "./style";
import { useTheme } from "styled-components";
import { Box } from "@mui/material";
import AdCard from "../adCard";
import { DiscoveryItem } from "../../../store/discovery/types";

const AdCardSection = ({
  item,
  showTranscription,
  handleViewAd
}: {
  item: DiscoveryItem;
  showTranscription: boolean;
  handleViewAd?: Function
}) => {
  const theme = useTheme();

  return (
    <StyledGridItem
      item
      sm={showTranscription ? 4.7 : 7}
      sx={{
        backgroundColor: theme.colors.gray97,
        paddingBlock: "1rem",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "fit-content",
          margin: "auto",
          marginTop:"0.2rem",
          maxWidth: showTranscription ? "94%" : "68%",
        }}
      >
        <AdCard item={item} adDetailsCard={true} hideSaveButton={true} handleViewAd={handleViewAd}/>
      </Box>
    </StyledGridItem>
  );
};

export default memo(AdCardSection);
