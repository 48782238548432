import {Box} from "@mui/material";
import {toast} from "react-toastify";
import {ToastTypes} from "../../utils/helpers";
import {NotificationButton, NotificationMessage1, NotificationMessage2, StyledDivider} from "../../globalStyle";
import {theme} from "../../utils/theme";
const NotificationMessage = ({ message, toastId, type }: {
    message: string,
    toastId: string,
    type: ToastTypes
}) => {
    const renderMessage = (color: string, buttonText: string, text:string) => (
        <Box sx={{ marginTop: "3.687rem" }}>
            <NotificationMessage1 sx={{ color }}>{text}!</NotificationMessage1>
            <NotificationMessage2>{message}</NotificationMessage2>
            <StyledDivider sx={{
                width: "25rem",
                position: "absolute",
                bottom: "5.5rem",
                transform: "translateX(-29%)"
            }} />
            <NotificationButton sx={{ backgroundColor: `${color} !important`, color: theme.colors.white }} onClick={() => toast.dismiss(toastId)}>
                {buttonText}
            </NotificationButton>
        </Box>
    );

    switch (type) {
        case ToastTypes.SUCCESS:
            return renderMessage(theme.colors.green1, "Continue", "Success");
        case ToastTypes.ERROR:
            return renderMessage(theme.colors.error, "Try Again", "Oops");
        case ToastTypes.WARNING:
            return renderMessage(theme.colors.warning, "Continue", "Warning");
        case ToastTypes.INFO:
            return renderMessage(theme.colors.info, "Continue", "Information");
        default:
            return null;
    }
};

export default NotificationMessage;