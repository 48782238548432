import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.accountState || initialState;

export const accountIsLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const userDetailsSelector = createSelector(
  selectDomain,
  (state) => state.userDetails
);

export const userPhoneNumberSelector = createSelector(
  selectDomain,
  (state) => state.userPhoneNumber
);

export const openResetPasswordModalSelector = createSelector(
  selectDomain,
  (state) => state.openResetPasswordModal
);

export const planListsSelector = createSelector(
  selectDomain,
  (state) => state.planLists
);

export const isPlanLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isPlanLoading
);

export const selectPlanTypeSelector = createSelector(
  selectDomain,
  (state) => state.selectPlanType
);

export const selectPlanListSelector = createSelector(
  selectDomain,
  (state) => state.selectPlanList
);

export const activePlanSelector = createSelector(
  selectDomain,
  (state) => state.activePlan
);
