import {useTheme} from "styled-components";
import { Box } from '@mui/material';
import {TermsLogoSmall} from "../../assets/Icons";
import {
    ListItemText, TermsContainer,
    TermsContent,
    TermsContentInner,
    TermsHeader, TermsInnerHeaderText,
    TermsLogoText,
    TermsSubText,
    TermsSubTitle,
    TermsTextContainer,
    TermsTitle,
    HomeLink
} from "../style";
import LogoText from "../../assets/images/swipe-lounge.png";
import {StyledDivider} from "../../globalStyle";
const TermsConditions = () => {
    const theme = useTheme()
    return (
            <TermsContainer>
                <TermsHeader>
                    <HomeLink to={"/"}>
                        <TermsLogoSmall/>
                        <TermsLogoText src={LogoText}/>
                    </HomeLink>
                    <Box>
                        <TermsTitle>Terms & Privacy Policy</TermsTitle>
                        <TermsSubTitle>We’re here to help you!</TermsSubTitle>
                    </Box>
                </TermsHeader>
                <StyledDivider/>
                <TermsContent>
                    <TermsContentInner>
                        <Box sx={{padding: "1.875rem 16.56rem"}}>
                            <TermsInnerHeaderText gutterBottom>
                                Terms of Services
                            </TermsInnerHeaderText>
                            <StyledDivider sx={{borderColor: theme.colors.whisper}}/>
                            <Box sx={{ marginTop: '1.25rem' }}>
                                <TermsSubText>
                                    Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate molestie nisi
                                    velit ultricies vulputate et mollis. Nunc odio ornare ultrices velit fames.
                                    Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non id
                                    blandit morbi orci ultrices mi ultrices risus. Turpis donec tempor mauris felis
                                    consectetur scelerisque aliquet elit. Dictum molestie eu quis semper leo. Sed amet
                                    pharetra sit lobortis vel lacus purus. Aliquet donec arcu bibendum nunc. Blandit
                                    nunc pellentesque proin justo eu urna enim nisi. Elementum lectus nisl id maecenas
                                    morbi ut purus.
                                </TermsSubText>

                                <TermsTextContainer>
                                    <TermsInnerHeaderText sx={{fontSize:"1.25rem !important"}} gutterBottom>
                                        What do these terms explain ?
                                    </TermsInnerHeaderText>
                                    <TermsSubText sx={{marginLeft: "2rem"}}>
                                        <ul>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate molestie
                                                nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices velit fames.
                                                Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non id blandit morbi
                                                orci ultrices mi ultrices risus. Turpis donec tempor mauris felis consectetur
                                                scelerisque aliquet elit.
                                            </ListItemText>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate
                                                molestie nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices
                                                velit fames. Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non
                                                id blandit morbi orci ultrices mi ultrices risus. Turpis donec tempor mauris felis
                                                consectetur scelerisque aliquet elit.
                                            </ListItemText>
                                        </ul>
                                    </TermsSubText>
                                </TermsTextContainer>
                                <TermsTextContainer>
                                    <TermsInnerHeaderText sx={{fontSize:"1.25rem !important"}} gutterBottom>
                                        What do these terms explain ?
                                    </TermsInnerHeaderText>
                                    <TermsSubText sx={{marginLeft: "2rem"}}>
                                        <ul>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate molestie
                                                nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices velit fames.
                                                Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non id blandit morbi
                                                orci ultrices mi ultrices risus. Turpis donec tempor mauris felis consectetur
                                                scelerisque aliquet elit.
                                            </ListItemText>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate
                                                molestie nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices
                                                velit fames. Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non
                                                id blandit morbi orci ultrices mi ultrices risus. Turpis donec tempor mauris felis
                                                consectetur scelerisque aliquet elit.
                                            </ListItemText>
                                        </ul>
                                    </TermsSubText>
                                </TermsTextContainer>
                                <TermsTextContainer>
                                    <TermsInnerHeaderText sx={{fontSize:"1.25rem !important"}} gutterBottom>
                                        What do these terms explain ?
                                    </TermsInnerHeaderText>
                                    <TermsSubText sx={{marginLeft: "2rem"}}>
                                        <ul>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate molestie
                                                nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices velit fames.
                                                Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non id blandit morbi
                                                orci ultrices mi ultrices risus. Turpis donec tempor mauris felis consectetur
                                                scelerisque aliquet elit.
                                            </ListItemText>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate
                                                molestie nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices
                                                velit fames. Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non
                                                id blandit morbi orci ultrices mi ultrices risus. Turpis donec tempor mauris felis
                                                consectetur scelerisque aliquet elit.
                                            </ListItemText>
                                        </ul>
                                    </TermsSubText>
                                </TermsTextContainer>
                                <TermsTextContainer>
                                    <TermsInnerHeaderText sx={{fontSize:"1.25rem !important"}} gutterBottom>
                                        What do these terms explain ?
                                    </TermsInnerHeaderText>
                                    <TermsSubText sx={{marginLeft: "2rem"}}>
                                        <ul>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate molestie
                                                nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices velit fames.
                                                Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non id blandit morbi
                                                orci ultrices mi ultrices risus. Turpis donec tempor mauris felis consectetur
                                                scelerisque aliquet elit.
                                            </ListItemText>
                                            <ListItemText>Lorem ipsum dolor sit amet consectetur. Lectus rhoncus vulputate
                                                molestie nisi velit ultrices vulputate et mollis. Nunc odio ornare ultrices
                                                velit fames. Ac est aliquam enim pretium sed pulvinar euismod quis amet. At non
                                                id blandit morbi orci ultrices mi ultrices risus. Turpis donec tempor mauris felis
                                                consectetur scelerisque aliquet elit.
                                            </ListItemText>
                                        </ul>
                                    </TermsSubText>
                                </TermsTextContainer>
                            </Box>
                        </Box>
                    </TermsContentInner>
                </TermsContent>
            </TermsContainer>
    )
}
export default TermsConditions