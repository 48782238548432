import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import styled, { useTheme } from "styled-components";
import { Icon404, SwipeLoungeIcon } from "../../assets/Icons";
import { CustomButton } from "../../globalStyle";
import {useNavigate} from "react-router-dom";
import routeConstant from "../../utils/routeConstant";

const ErrorWrapper = styled("div")({
  maxWidth: "100%",
  margin: "0 auto",
  textAlign: "center",
});

const ErrorCard = styled(Card)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ErrorPage = () => {
  const theme = useTheme();
  const navigate = useNavigate()
  const redirectToHome = () => {
      navigate(routeConstant.dashboard);
  }
  return (
    <ErrorCard
      sx={{
        background: theme.colors.black3,
      }}
    >
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Box
            sx={{
              marginBottom: "3.438rem",
            }}
          >
            <SwipeLoungeIcon />
          </Box>
          <Grid item xs={12}>
            <ErrorWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Icon404 />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.25rem",
                      letterSpacing: "0.038rem",
                      textAlign: "center",
                      marginBottom: "0.938rem",
                      color: theme.colors.white,
                    }}
                  >
                    There isn't a Swipe Lounge site here.
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.938rem",
                      letterSpacing: "0.6px",
                      textAlign: "center",
                      color: theme.colors.white,
                    }}
                  >
                    Oops! The page you were looking for, couldn’t be found. 
                  </Typography>
                  <CustomButton
                    sx={{
                      padding: "1.25rem 3.625rem",
                      background: theme.colors.white,
                      marginTop: "2.5rem",
                      color: theme.colors.neutral100,
                      borderRadius: "3.125rem",
                      ":hover": {
                        background: theme.colors.white,
                      },
                      fontWeight: "600",
                      fontSize: "1.125rem",
                      textAlign: "center",
                    }}
                    onClick={redirectToHome}
                  >
                    Go to Home Page
                  </CustomButton>
                </Grid>
              </Grid>
            </ErrorWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ErrorCard>
  );
};

export default ErrorPage;
