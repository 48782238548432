import axiosClient from "../apiClient";
import { SaveAdTagsType } from "../types/TagTypes";

export const fetchTagsListService = async (requestBody: { tag: string }) => {
  try {
    const response = await axiosClient.post("/tags/search", requestBody);
    return response;
  } catch (error) {
    return error;
  }
};

export const SaveAdTagsService = async (params: SaveAdTagsType) => {
  try {
    const response = await axiosClient.put("/tags/update-tag", params);
    return response;
  } catch (error) {
    return error;
  }
};

export const CreateTagService = async (value: Object) => {
  try {
    const response = await axiosClient.post("/tags", value);
    return response;
  } catch (error) {
    return error;
  }
};

export const AddOrRemoveTag = async (value: {
  tagId: string;
  adId: string;
}) => {
  try {
    const reqBody = {
      tagId: value.tagId,
    };
    const response = await axiosClient.put(
      `/ad/addOrRemoveTag/${value.adId}`,
      reqBody
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const AddMultipleTagsToAdsService = async (params: {
  adIds: string[];
  tagIds: string[];
}) => {
  try {
    const response = await axiosClient.post("/ad/multiple-ad-to-tags", params);
    return response;
  } catch (error) {
    return error;
  }
};
