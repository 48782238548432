import axiosClient from "../apiClient";

export const NotificationList = async () => {
  try {
    const response = await axiosClient.get("/notifications");
    return response;
  } catch (error) {
    return error;
  }
};

export const CheckNotification = async () => {
  try {
    const response = await axiosClient.get("/notifications/check");
    return response;
  } catch (error) {
    return error;
  }
};