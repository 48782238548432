import { Box, CircularProgress, DialogTitle, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
} from "./style";
import { CloseIcon, ImageGalleryIcon } from "../../assets/Icons";
import { ModalProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";

import { StyledDivider } from "../../globalStyle";
import SelectBoardDropdown from "../commonComponents/selectBoardsDropdown";
import {
  openAddBoardModalLoadingSelector,
  openAddBoardModalSelector,
  selectedTableItemsSelector,
} from "../../store/swipeFile/selector";
import { useState } from "react";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { SwipeFileSagaActions } from "../../store/swipeFile/sagas";

const AddToBoardModal = () => {
  const selectedTableItems = useSelector(selectedTableItemsSelector);
  const [selectBoardIds, setSelectBoardIds] = useState<string[]>([]);
  const dispatch = useDispatch();

  const open = useSelector(openAddBoardModalSelector);
  const loading = useSelector(openAddBoardModalLoadingSelector);

  const handleClose = () => {
    dispatch(SwipeFileReducerAction.setOpenAddBoardModal(false));
  };

  const handleUpdateBoard = (selectedBoardIds: string[]) => {
    setSelectBoardIds(selectedBoardIds);
    dispatch(MetaDataReducerAction.setOpenBoardDropdown("false"));
  };

  const handleSubmit = () => {
    dispatch(
      SwipeFileSagaActions.addMultipleBoardsToMultipleAds({
        adIds: selectedTableItems,
        boardIds: selectBoardIds,
      })
    );
  };

  return (
    <StyledDialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <ImageGalleryIcon />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>
              Add {selectedTableItems?.length} Ads to Boards
            </ModalTitle>
            <FontGrayNormal>
              Copy these ads to one or multiple boards
            </FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <StyledDivider />
      <Box sx={{ padding: "1.875rem" }}>
        <SelectBoardDropdown
          showSelectedItemCount={2}
          selectedBoards={[]}
          handleUpdateBoard={handleUpdateBoard}
        />
      </Box>
      <StyledDivider />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 1.375rem",
        }}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
        onClick={handleSubmit}
      >
        Add to Boards
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default AddToBoardModal;
