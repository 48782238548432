import React from "react";
import { FolderCardText, ViewAllCardContainer } from "./style";
import { CreateNewIcon } from "../../../assets/Icons";
import { useTheme } from "styled-components";
import { Box } from "@mui/material";

const ViewAllCard = () => {
  const theme = useTheme();
  return (
    <ViewAllCardContainer>
      <Box
        sx={{
          padding: "0.938rem",
          backgroundColor: theme.colors.background4,
          borderRadius: "50%",
        }}
      >
        <CreateNewIcon fill={theme.colors.black2} />
      </Box>
      <FolderCardText>View All</FolderCardText>
    </ViewAllCardContainer>
  );
};

export default ViewAllCard;
