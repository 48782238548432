import {
  Avatar,
  Badge,
  CircularProgress,
  Grid,
  Input,
  Stack,
} from "@mui/material";
import {
  ProfileUploadButton,
  ResetPasswordButton,
  StyledInputLabel,
  StyledOutlinedInput,
} from "../../pageComponents/settings/style";
import { StyledContainedButton } from "../modals/style";
import { CreateNewIcon } from "../../assets/Icons";
import UserAvatar from "../../assets/images/user-avatar1.png";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { AccountSettingsFormValuesProp } from "../../types/accountSettingsTypes";
import { useDispatch, useSelector } from "react-redux";
import { AccountSagaActions } from "../../store/account/sagas";
import {
  accountIsLoadingSelector,
  userDetailsSelector,
  userPhoneNumberSelector,
} from "../../store/account/selector";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { AccountReducerAction } from "../../store/account/slice";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";

const AccountSettingsForm = () => {
  // States
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [profileImage, setProfileImage] = useState("");

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors
  const user = useSelector(userDetailsSelector);
  const userPhoneNumber = useSelector(userPhoneNumberSelector);
  const loading = useSelector(accountIsLoadingSelector);
  const profileImageInputRef = useRef<HTMLInputElement>(null);

  // React Hook Forms
  const { register, handleSubmit, getValues, setValue } =
    useForm<AccountSettingsFormValuesProp>({
      defaultValues: {
        fullName: user?.fullName,
        email: user?.email,
      },
    });

  useEffect(() => {
    if (user) {
      setValue("fullName", user.fullName);
      setValue("email", user.email);
      setProfileImage(user.profileImage);
    }
  }, [user, setValue]);

  // Functions
  const handleProfileImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleProfileUploadButtonClick = () => {
    profileImageInputRef.current?.click();
  };

  const handleNavigate = () => {
    navigate(routeConstant.dashboard, {
      replace: true,
    });
  };

  const handleResetPasswordModalAction = () => {
    dispatch(AccountReducerAction.setOpenResetPasswordModal(true));
  };

  const handleChange = (value: string) => {
    dispatch(AccountReducerAction.setUserPhoneNumber(value));
  };

  const onSubmit = () => {
    const values = getValues();
    const formData = new FormData();

    formData.append("fullName", values.fullName);
    if (selectedFile) {
      formData.append("profileImage", selectedFile);
    }

    formData.append("phoneNumber", userPhoneNumber);

    dispatch(
      AccountSagaActions.updateUserAccountDetails({
        id: user?._id,
        body: formData,
        handleNavigate: handleNavigate,
      })
    );
  };

  return (
    <div>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ marginBottom: "2.5rem" }}
        badgeContent={
          <div>
            <Input
              type="file"
              style={{ display: "none" }}
              inputRef={profileImageInputRef}
              onChange={handleProfileImageSelect}
              inputProps={{ accept: "image/*" }}
            />
            <ProfileUploadButton onClick={handleProfileUploadButtonClick}>
              <CreateNewIcon fill="white" />
            </ProfileUploadButton>
          </div>
        }
      >
        <Avatar
          src={
            selectedFile
              ? URL.createObjectURL(selectedFile)
              : profileImage
              ? profileImage
              : UserAvatar
          }
          sx={{ width: "8.625rem", height: "8.625rem" }}
        />
      </Badge>
      <Grid container spacing={2.5}>
        <Grid item sm={6}>
          <StyledInputLabel htmlFor="fullName">Name</StyledInputLabel>
          <StyledOutlinedInput
            fullWidth
            id="fullName"
            type="text"
            {...register("fullName")}
          />
        </Grid>
        <Grid item sm={6}>
          <StyledInputLabel htmlFor="email">Email</StyledInputLabel>
          <StyledOutlinedInput
            fullWidth
            id="email"
            type="text"
            {...register("email")}
            readOnly
          />
        </Grid>
        <Grid item sm={6}>
          <StyledInputLabel htmlFor="phoneNumber">
            Phone Number
          </StyledInputLabel>
          <PhoneInput
            defaultCountry="usa"
            value={userPhoneNumber}
            onChange={(phone) => handleChange(phone)}
            placeholder="Enter phone number"
          />
        </Grid>
      </Grid>
      <Stack alignItems="end" gap="1.25rem" paddingTop="1.25rem">
        <ResetPasswordButton onClick={handleResetPasswordModalAction}>
          Change Password
        </ResetPasswordButton>
        <StyledContainedButton
          sx={{ paddingInline: "1.875rem" }}
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          Save
        </StyledContainedButton>
      </Stack>
    </div>
  );
};

export default AccountSettingsForm;
