import { formatDistance } from "date-fns";
import {Id, toast} from "react-toastify";
import {NotificationErrorIcon, NotificationWarningIcon} from "../assets/Icons"
import NotificationMessage from "../components/commonComponents/NotificationMessage"

export const formatCreatedDate = (date: string) => {
  const formattedText = formatDistance(new Date(date), new Date(), {
    addSuffix: true,
  });
  if (formattedText === "less than a minute ago") return "Just now";
  return formattedText.includes("about")
      ? formattedText.replace("about ", "")
      : formattedText;
};

export enum ToastTypes {
  SUCCESS,
  ERROR,
  WARNING,
  INFO,
}

const createToast = (message: string, toastId: string, type: ToastTypes, toastProps: any): Id => {
  const content = NotificationMessage({ message, toastId, type });
  switch (type) {
    case ToastTypes.SUCCESS:
      return toast.success(content, { ...toastProps });
    case ToastTypes.ERROR:
      return toast.error(content, { ...toastProps });
    case ToastTypes.WARNING:
      return toast.warn(content, { ...toastProps });
    case ToastTypes.INFO:
      return toast.info(content, { ...toastProps });
    default:
      return toast(content, { ...toastProps });
  }
};

// General notify function
export const notify = ({
                         message,
                         type,
                       }: {
  message: string;
  type?: ToastTypes;
}) => {
  const id = Math.random().toString();

  const toastProps = {
    toastId: id,
  };

  switch (type) {
    case ToastTypes.SUCCESS:
      createToast(message, id, ToastTypes.SUCCESS, toastProps);
      break;
    case ToastTypes.ERROR:
      createToast(message, id, ToastTypes.ERROR, { ...toastProps, icon: NotificationErrorIcon });
      break;
    case ToastTypes.WARNING:
      createToast(message, id, ToastTypes.WARNING, { ...toastProps, icon: NotificationWarningIcon });
      break;
    case ToastTypes.INFO:
      createToast(message, id, ToastTypes.INFO, toastProps);
      break;
    default:
      toast(message, toastProps);
      break;
  }
};

export const formatAdCount = (count: number) => {
  if (count >= 1000000) {
    return count / 1000000 + "million";
  } else if (count >= 100000) {
    return count / 100000 + "lack";
  } else if (count >= 1000) {
    return count / 1000 + "k";
  } else {
    return count;
  }
};

export const downloadTranscript = (fileName: string, text: string) => {
  const element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export const webkitInputCSSFix = () => {
  return {
    input: {
      '&:-webkit-autofill': {
        transitionDelay: '9999s',
        transitionProperty: 'background-color, color',
      },
    },
  }
}