import { createSlice } from "@reduxjs/toolkit";
import { MetaDataState, folderItem } from "./types";
import { boardType } from "../discovery/types";

export const initialState: MetaDataState = {
  isLoading: false,
  openCreateFolder: false,
  folderLists: null,
  boardItems: [],
  boardIds: [],
  openBoardModal: false,
  selectedFolder: null,
  selectMultipleBoards: [],
  tagsLists: [],
  brandsList: [],
  saveAdPopupAnchorEl: null,
  selectedSaveAdId: "",
  adDetailsData: null,
  favouriteBrands: [],
  favouriteBrandIds: [],
  adNavigationIndex: 0,
  brandFilterSearchText: "",
  boardFilterSearchText: "",
  currenplayingVideoState: {
    currentPlayingVideo: null,
    setPlayButtonForCurrentVideo: () => {},
  },
  openSaveTranscriptModal: false,
  saveTranscriptLoader: false,
  favouriteFolderList: [],
  openSaveNewAd: false,
  openSaveNewAdLoader: false,
  modalTabIndex: 0,
  downloadMediaLoader: false,
  folderItem: null,
  openBoardDropdown: "",
  tagFilterSearchText: "",
  deleteFolderLoading: false,
  openViewFolderModal: false,
  openCreateTeamModal: false,
  createTeamLoading: false,
  teamDetails: [],
  invitationLoader: false,
  notificationList: null,
  checkNotificationStatus: false,
  acceptJoinTeamLoader: false,
  rejectJoinTeamLoader: false,
  teamMemberEmail: "",
  notificationLoader: false,
  selectedFolderId: null,
  boardAdsItems: [],
};

const metaData = createSlice({
  name: "metaDataState",
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setOpenCreateFolder(state, action) {
      state.openCreateFolder = action.payload;
    },
    setFolderItems(state, action) {
      state.folderLists = action.payload;
    },
    setBoardItems(state, action) {
      state.boardItems = action.payload;
    },
    setBoardId(state, action) {
      const boardId = action.payload;
      const index = state.boardIds.indexOf(boardId);
      if (index === -1) {
        state.boardIds.push(boardId);
      } else {
        state.boardIds.splice(index, 1);
      }
    },
    setBoardItem(state, action) {
      state.boardItems.push(action.payload);
    },
    setOpenBoardModal(state, action) {
      state.openBoardModal = action.payload;
    },
    setBoardIds(state, action) {
      const { selectAll, id, checked } = action.payload;
      if (selectAll && checked) {
        state.boardIds = state.boardItems.map((item) => item._id);
      } else if (selectAll && !checked) {
        state.boardIds = [];
      } else {
        const index = state.boardIds.indexOf(id);
        if (index === -1) {
          state.boardIds.push(id);
        } else {
          state.boardIds.splice(index, 1);
        }
      }
    },
    setSelectedFolder(state, action) {
      state.selectedFolder = action.payload;
    },
    setSelectedFolderId(state, action) {
      state.selectedFolderId = action.payload;
    },
    setNewFolderItem(state, action) {
      state.folderLists?.userFolders.push(action.payload);
      state.folderLists?.teamFolders.push(action.payload);
    },
    setSelectedMultipleBoard(state, action) {
      const selectedValue = action.payload;
      state.selectMultipleBoards =
        typeof selectedValue === "string"
          ? selectedValue.split(",")
          : selectedValue;
    },
    setTagsList(state, action) {
      state.tagsLists = action.payload;
    },
    setBrandsList(state, action) {
      state.brandsList = action.payload;
    },
    setSaveAdPopupAnchorEl(state, action) {
      state.saveAdPopupAnchorEl = action.payload;
    },
    setSelectedSaveAdId(state, action) {
      state.selectedSaveAdId = action.payload;
    },
    setAdSelectedBoardIds(state, action) {
      const boardIds = action.payload?.map((item: boardType) => item._id);
      return {
        ...state,
        boardIds: state.boardIds ? state.boardIds.concat(boardIds) : boardIds,
      };
    },
    setBoardIdstoInitialSet(state, action) {
      state.boardIds = action.payload;
    },
    setAdDetails(state, action) {
      state.adDetailsData = action.payload;
    },
    setFavouriteBrands(state, action) {
      state.favouriteBrands = action.payload;
    },
    setFavouriteBrandsIds(state, action) {
      state.favouriteBrandIds = action.payload;
    },
    setAdNavigationIndex(state, action) {
      state.adNavigationIndex = action.payload;
    },
    setBrandFilterSearchText(state, action) {
      state.brandFilterSearchText = action.payload;
    },
    setBoardFilterSearchText(state, action) {
      state.boardFilterSearchText = action.payload;
    },
    setNewTagToTagList(state, action) {
      state.tagsLists.push(action.payload);
    },
    setCurrentPlayingVideo(state, action) {
      state.currenplayingVideoState = {
        currentPlayingVideo: action.payload.video,
        setPlayButtonForCurrentVideo: action.payload.setStartVideo,
      };
    },
    setOpenSaveTranscriptModal(state, action) {
      state.openSaveTranscriptModal = action.payload;
    },
    setSaveTranscriptLoader(state, action) {
      state.saveTranscriptLoader = action.payload;
    },
    setFavouriteFolderList(state, action) {
      state.favouriteFolderList = action.payload;
    },
    setOpenSaveNewAd(state, action) {
      state.openSaveNewAd = action.payload;
    },
    setOpenSaveAdLoader(state, action) {
      state.openSaveNewAdLoader = action.payload;
    },
    setModalTabIndex(state, action) {
      state.modalTabIndex = action.payload;
    },
    setDownloadMediaLoader(state, action) {
      state.downloadMediaLoader = action.payload;
    },
    setFolderItem(state, action) {
      state.folderItem = action.payload;
    },
    setOpenBoardDropdown(state, action) {
      state.openBoardDropdown = action.payload;
    },
    setBoardIdsToInitialSet(state) {
      state.boardIds = [];
    },
    setTagFilterSearchText(state, action) {
      state.tagFilterSearchText = action.payload;
    },
    setDeleteFolderLoading(state, action) {
      state.deleteFolderLoading = action.payload;
    },
    setOpenViewFolderModal(state, action) {
      state.openViewFolderModal = action.payload;
    },
    setUpdatedFolderBoardItems(state, action) {
      state.folderItem = action.payload.updatedFolderItem;

      // Updating Main folderLists
      state.folderLists = action.payload.updatedFolderLists;
    },
    setOpenCreateTeamModal(state, action) {
      state.openCreateTeamModal = action.payload;
    },
    setCreateTeamLoading(state, action) {
      state.createTeamLoading = action.payload;
    },
    setTeamDetail(state, action) {
      state.teamDetails = action.payload;
    },
    setInvitationLoader(state, action) {
      state.invitationLoader = action.payload;
    },
    setNotificationList(state, action) {
      state.notificationList = action.payload;
    },
    setCheckNotificationStatus(state, action) {
      state.checkNotificationStatus = action.payload;
    },
    setAcceptJoinTeamLoader(state, action) {
      state.acceptJoinTeamLoader = action.payload;
    },
    setRejectJoinTeamLoader(state, action) {
      state.rejectJoinTeamLoader = action.payload;
    },
    setTeamMemberEmail(state, action) {
      state.teamMemberEmail = action.payload;
    },
    setNotificationLoader(state, action) {
      state.notificationLoader = action.payload;
    },
    setBoardAdsItems(state, action) {
      state.boardAdsItems = action.payload;
    },
  },
});

export const {
  actions: MetaDataReducerAction,
  name: MetaDataReducerName,
  reducer: MetaDataReducer,
} = metaData;
