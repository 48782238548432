import {
  CheckedBoxIcon,
  CloseSubscriptionIcon,
  SwipeLoungeIcon,
} from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { Box, CircularProgress, Grid } from "@mui/material";
import { CardContainer } from "../../components/dashboardSections/style";
import { useTheme } from "styled-components";
import { CustomButton } from "../../globalStyle";
import {
  CardButtonWrapper,
  CardDetailList,
  CardDetailListText,
  CardDetailListWrapper,
  CardDetailsHeader,
  CardDetailsWrapper,
  CardHeaderSection,
  CardName,
  CardPlanDescription,
  CardPrice,
  HeaderNavigation,
  MainSection,
  MainSectionHeading,
  MainSectionSubHeading,
  MainSectionSubHeadingWrapper,
  MainSectionWrapper,
} from "./style";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  activePlanSelector,
  isPlanLoadingSelector,
  planListsSelector,
  selectPlanListSelector,
  selectPlanTypeSelector,
} from "../../store/account/selector";
import { PlanType } from "../../store/account/types";
import { AccountSagaActions } from "../../store/account/sagas";
import { AccountReducerAction } from "../../store/account/slice";
import { monthlyPlanType, yearlyPlanType } from "../../utils/constants";

const Subscription = () => {
  // Hooks
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Selectors
  const selectedPlanList = useSelector(selectPlanListSelector);
  const isPlanLoading = useSelector(isPlanLoadingSelector);
  const selectedPlanType = useSelector(selectPlanTypeSelector);
  const selectedPlan = useSelector(activePlanSelector);
  const planLists = useSelector(planListsSelector);
  
  // Functions
  const handleClose = () => {
    navigate(`${routeConstant.dashboard}`, {
      replace: true,
    });
  };

  const handleSelectPlanType = (type: string) => {
    dispatch(AccountReducerAction.setSelectPlanType(type));
    dispatch(AccountReducerAction.setSelectPlanList(planLists[type]));
  };

  const handleSelectedPlan = (plan: PlanType) => {
    dispatch(AccountReducerAction.setActivePlan(plan));
  };

  const handleGetStarted = (plan: PlanType) => {
    handleSelectedPlan(plan);
  };

  useEffect(() => {
    dispatch(AccountSagaActions.getPlanLists());
  }, []);

  if (isPlanLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1.875rem",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div
      style={{
        margin: "1.875rem 15rem",
      }}
    >
      <HeaderNavigation>
        <div>
          <SwipeLoungeIcon />
        </div>
        <div
          onClick={handleClose}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseSubscriptionIcon />
        </div>
      </HeaderNavigation>

      <MainSection>
        <MainSectionWrapper>
          <MainSectionHeading>Subscription Plans</MainSectionHeading>
        </MainSectionWrapper>
        <MainSectionSubHeadingWrapper>
          <MainSectionSubHeading>
            Lorem ipsum dolor sit amet consectetur. A rutrum porttitor leo
            egestas. Habitasse in massa facilisis suspendisse commodo tempor
            lacus donec.
          </MainSectionSubHeading>
        </MainSectionSubHeadingWrapper>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            style={{
              borderRadius: "100px 0px 0px 100px",
              border: "1px solid rgba(255, 255, 255, 0.4)",
              background: `${
                selectedPlanType === monthlyPlanType ? "white" : "transparent"
              }`,
              color: `${
                selectedPlanType === monthlyPlanType ? "black" : "white"
              }`,
              padding: "0.75rem 4.375rem",
              cursor: "pointer",
              fontWeight: 500,
              fontSize: "1.25rem",
            }}
            onClick={() => handleSelectPlanType(monthlyPlanType)}
          >
            Monthly
          </button>
          <button
            style={{
              borderRadius: "0px 100px 100px 0px",
              border: "1px solid rgba(255, 255, 255, 0.4)",
              background: `${
                selectedPlanType === yearlyPlanType ? "white" : "transparent"
              }`,
              color: `${
                selectedPlanType === yearlyPlanType ? "black" : "white"
              }`,
              padding: "0.75rem 4.375rem",
              cursor: "pointer",
              fontWeight: 500,
              fontSize: "1.25rem",
            }}
            onClick={() => handleSelectPlanType(yearlyPlanType)}
          >
            Annually
          </button>
        </div>
      </MainSection>

      <Grid container spacing={"2.25rem"} paddingInline="3.125rem">
        {selectedPlanList?.map((plan: PlanType, index: number) => {
          const planPrice = plan.amount === null ? "Free" : `$${plan.amount}`;
          return (
            <Grid
              item
              sm={12}
              md={4}
              marginTop={index === 1 ? "3.125rem" : "5.875rem"}
            >
              <CardContainer
                key={plan._id}
                sx={{
                  padding: "1.875rem",
                  background: theme.colors.richBlack,
                  border:
                    selectedPlan?._id === plan._id
                      ? `1px solid ${theme.colors.lightBlue}`
                      : `1px solid rgba(255,255,255,0.4)`,
                  backgroundImage:
                    selectedPlan?._id === plan._id
                      ? 'url("/assets/images/Ellipse.svg")'
                      : "none",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  cursor: "pointer",
                }}
                onClick={() => handleSelectedPlan(plan)}
              >
                <CardHeaderSection>
                  <CardName>{plan.name}</CardName>
                  <CardPrice>{planPrice}</CardPrice>
                  <CardPlanDescription>{plan.description}</CardPlanDescription>
                </CardHeaderSection>
                <CardDetailsWrapper>
                  <CardDetailsHeader>Includes :</CardDetailsHeader>

                  <CardDetailListWrapper>
                    {plan.featureList.map((feature, featureIndex) => (
                      <CardDetailList key={featureIndex}>
                        <CheckedBoxIcon />
                        <CardDetailListText>{feature}</CardDetailListText>
                      </CardDetailList>
                    ))}
                  </CardDetailListWrapper>
                </CardDetailsWrapper>
                <CardButtonWrapper>
                  <CustomButton
                    sx={{
                      padding: "0.813rem 1.25rem",
                      color: theme.colors.white,
                      borderRadius: "100px",
                      border: "1px solid rgba(255,255,255,0.4)",
                      background:
                        selectedPlan?._id === plan._id
                          ? theme.colors.backgroundPrimary
                          : "none",
                      cursor: plan.currentlyActive ? "not-allowed" : "pointer",
                      opacity: plan.currentlyActive ? 0.6 : 1,
                    }}
                    onClick={() =>
                      !plan.currentlyActive && handleGetStarted(plan)
                    }
                  >
                    {plan.currentlyActive ? "Active" : "Get Started"}
                  </CustomButton>
                </CardButtonWrapper>
              </CardContainer>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default Subscription;
