import { useTheme } from "styled-components";
import { SearchIconWrapper, SearchInput, SearchWrapper } from "./style";
import SearchIcon from "@mui/icons-material/Search";
import { FilterSearchboxProps } from "../../../utils/propTypes";

const FilterSearchbox = ({
  placeholder,
  handleChange,
  isTextField,
  handleKeyDown = () => {},
  value,
  handleSearchIconClick = () => {},
}: FilterSearchboxProps) => {
  const theme = useTheme();

  return (
    <SearchWrapper variant="outlined">
      <SearchInput
        placeholder={placeholder}
        sx={{
          "& ::placeholder": {
            color: theme.colors.gray1,
            opacity: 1,
            fontWeight: 500,
            fontSize: "1rem",
          },
        }}
        endAdornment={
          isTextField ? null : (
            <SearchIconWrapper
              position="end"
              onClick={handleSearchIconClick}
              sx={{ cursor: "pointer" }}
            >
              <SearchIcon />
            </SearchIconWrapper>
          )
        }
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
      />
    </SearchWrapper>
  );
};

export default FilterSearchbox;
