import { Button } from "@mui/material";
import styled from "styled-components";

export const TextEditorButton = styled(Button)<{ selected: boolean }>`
  && {
    padding: 0;
    min-width: fit-content;
    background-color: ${({ theme, selected }) =>
      selected ? `${theme.colors.border1} !important` : ""};
  }
`;

export const ToolbarContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 0.625rem;
`;
