import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.875rem;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 15px;
  margin-bottom: 1.25rem;
`;

export const PlanType = styled.span`
  padding: 0.375rem 1.25rem;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 20px;
  margin-bottom: 1.25rem;
  background: ${({ theme }) => theme.colors.cyan};
  color: ${({ theme }) => theme.colors.green1};
`;

export const PlanDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const PlanDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
`;

export const PlanName = styled.h3`
  color: ${({ theme }) => theme.colors.black2};
  font-weight: 600;
  font-size: 1.25rem;
`;

export const PlanDescription = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray1};
  overflow-wrap: break-word;
  max-width: 45rem;
`;

export const PlanPrice = styled.span<{ weight?: number; size?: string }>`
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
`;

export const CancelPlanButton = styled.button`
  border: none;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  padding: 0.625rem 1.875rem;
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;


export const UpgradePlanButton = styled.button`
  border: none;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  padding: 0.625rem 1.875rem;
  font-weight: 500;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

export const InvoiceButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.625rem;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.black1};
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  font-size: 1rem;
`;
