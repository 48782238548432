import React, { useEffect, useState } from "react";
import { SaveTranscriptButton, StyledGridItem } from "./style";
import { useTheme } from "styled-components";
import { Box, Stack, Typography } from "@mui/material";
import {
  CustomButton,
  StyledDivider,
  StyledMenuItem,
} from "../../../globalStyle";
import { CheckMarkIcon, CopyIcon, NoVoiceIcon } from "../../../assets/Icons";
import SelectDropdown from "../../selectDropdown";
import {
  DiscoveryItem,
  utterancesObject,
} from "../../../store/discovery/types";
import { SwipeFileListItems } from "../../../store/swipeFile/types";
import { ToastTypes, notify } from "../../../utils/helpers";
import { MetaDataReducerAction } from "../../../store/metadata/slice";
import { useDispatch } from "react-redux";

const NoTranscriptComponent = () => {
  const theme = useTheme();
  return (
    <Box sx={{ margin: "auto" }}>
      <Box
        padding={"1.688rem"}
        sx={{
          background: theme.colors.backgroundPrimary,
          borderRadius: "50%",
          margin: "0 auto 2.5rem auto",
          width: "fit-content",
        }}
      >
        <NoVoiceIcon />
      </Box>
      <Typography
        fontSize="1.5rem"
        fontWeight="600"
        color={theme.colors.black2}
        textAlign="center"
        marginBottom="1.25rem"
      >
        Video has no Dialogue
      </Typography>
      <Typography
        fontSize="1.25rem"
        fontWeight="500"
        color={theme.colors.gray1}
        textAlign="center"
      >
        This video does not contain spoken word. Transcription can not be
        generated.
      </Typography>
      {/* <AdDrawerStyledButton
        sx={{ width: "fit-content", margin: "1.875rem auto 0" }}
      >
        Submit Feedback
      </AdDrawerStyledButton> */}
    </Box>
  );
};

const TranscriptionSection = ({
  item,
}: {
  item: DiscoveryItem | SwipeFileListItems;
}) => {
  const [showCheckedMarked, setShowCheckedMarked] = useState<string>("");
  const theme = useTheme();

  const dispatch = useDispatch();

  const transcriptArray = item?.transcript?.results?.utterances;

  const transcriptText = item?.transcript?.results?.channels?.[0]?.alternatives;

  const handleCopyTranscript = () => {
    navigator.clipboard.writeText(transcriptText[0].transcript);
    notify({
      message: "Transcript text copied successfully",
      type: ToastTypes.SUCCESS,
    });
  };

  const handleCopyTranscriptWithTimeStamp = () => {
    let transcriptWithTimeStampText = "";
    transcriptArray?.map((transcript: utterancesObject) => {
      transcriptWithTimeStampText += `${transcript.start} - ${transcript.end}\n${transcript.transcript}\n\n`;
    });
    navigator.clipboard.writeText(transcriptWithTimeStampText);
    notify({
      message: "Transcript text with timestamp copied successfully",
      type: ToastTypes.SUCCESS,
    });
  };

  const handleSaveTranscriptModel = () => {
    dispatch(MetaDataReducerAction.setOpenSaveTranscriptModal(true));
  };

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (showCheckedMarked) {
      timer = setTimeout(() => {
        setShowCheckedMarked("");
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [showCheckedMarked]);
  return (
    <StyledGridItem
      item
      sm={2.8}
      sx={{
        border: `1px solid ${theme.colors.border1}`,
        display: "flex",
        flexDirection: "column",
        padding: "1.25rem 1.25rem 3.75rem",
        marginTop: "1.25rem",
        borderRadius: "10px",
        background: `${theme.colors.background4}`,
      }}
    >
      <Typography fontSize="1rem" color={theme.colors.black2} fontWeight={600}>
        Transcription
      </Typography>
      <StyledDivider sx={{ margin: "1.25rem -1.25rem" }} />
      {transcriptArray?.length === 0 ? (
        <NoTranscriptComponent />
      ) : (
        <>
          {transcriptArray?.map(
            (transcript: utterancesObject, index: number) => (
              <Box
                key={index}
                sx={{
                  padding: "0.938rem",
                  borderRadius: "10px",
                  ":hover": { background: theme.colors.background4 },
                }}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Stack>
                    <Typography
                      fontSize="0.938rem"
                      color={theme.colors.gray1}
                      fontWeight={500}
                    >
                      {transcript.start} - {transcript.end}
                    </Typography>
                    <Typography
                      fontSize="1rem"
                      color={theme.colors.black2}
                      fontWeight={500}
                    >
                      {transcript.transcript}
                    </Typography>
                  </Stack>
                  <CustomButton
                    onClick={() => {
                      setShowCheckedMarked(transcript.id);
                      navigator.clipboard.writeText(transcript.transcript);
                    }}
                  >
                    {showCheckedMarked === transcript.id ? (
                      <CheckMarkIcon
                        color="#414042"
                        width="1.5rem"
                        height="1.5rem"
                      />
                    ) : (
                      <CopyIcon />
                    )}
                  </CustomButton>
                </Stack>
              </Box>
            )
          )}
          <StyledDivider sx={{ margin: "1.25rem -1.25rem" }} />
          <StyledDivider sx={{ margin: "1.25rem -1.25rem" }} />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <SelectDropdown
                startAdornmentIcon={<CopyIcon />}
                value={"copy-transcript"}
                height="6.7rem"
              >
                <StyledMenuItem
                  value={"copy-transcript"}
                  sx={{ display: "none" }}
                >
                  Copy Transcript
                </StyledMenuItem>
                <StyledMenuItem
                  value={"with-timestamps"}
                  onClick={() => handleCopyTranscriptWithTimeStamp()}
                >
                  With Timestamps
                </StyledMenuItem>
                <StyledMenuItem
                  value={"text-only"}
                  onClick={() => handleCopyTranscript()}
                >
                  Text Only
                </StyledMenuItem>
              </SelectDropdown>
            </div>

            <SaveTranscriptButton onClick={handleSaveTranscriptModel}>
              Save
            </SaveTranscriptButton>
          </Box>
        </>
      )}
    </StyledGridItem>
  );
};

export default TranscriptionSection;
