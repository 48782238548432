import {
  CircularProgress,
  DialogTitle,
  Divider,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import {
  CloseIconWrapper,
  CreateBoardWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledFormControl,
  StyledInputLabel,
  StyledTextField,
} from "./style";
import {
  CloseIcon,
  CreateFolderIcon,
  UploadImageIcon,
} from "../../assets/Icons";
import { CreateTeamValueProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createTeamValidationSchema } from "../../utils/formValidationSchema";
import { ErrorMessage } from "../../pageComponents/auth/style";
import {
  createTeamLoadingSelector,
  openCreateTeamModalSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { ChangeEvent, useRef, useState } from "react";
import {
  LogoUploadButton,
  SquareContainer,
  UploadedImage,
} from "../../pageComponents/settings/style";
import { useTheme } from "styled-components";
import { MetaDataSagaActions } from "../../store/metadata/sagas";

const CreateTeamModal = () => {
  const theme = useTheme();
  const {
    getValues,
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm<CreateTeamValueProps>({
    resolver: yupResolver(createTeamValidationSchema),
  });

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [logoErrorMessage, setLogoErrorMessage] = useState<boolean>(false);
  const dispatch = useDispatch();
  const loading = useSelector(createTeamLoadingSelector);
  const openModal = useSelector(openCreateTeamModalSelector);

  const onSubmit = () => {
    if (!selectedFile) {
      setLogoErrorMessage(true);
    } else {
      const values = getValues();

      dispatch(
        MetaDataSagaActions.createTeam({
          name: values.name,
          logo: selectedFile,
          handleCloseModal: handleCloseModal,
        })
      );
    }
  };

  const handleCloseModal = () => {
    setLogoErrorMessage(false)
    setSelectedFile(null);
    reset();
    dispatch(MetaDataReducerAction.setOpenCreateTeamModal(false));
  };

  const profileImageInputRef = useRef<HTMLInputElement | null>(null);

  const handleProfileImageSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setLogoErrorMessage(false);
    }
  };

  const handleProfileUploadButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    profileImageInputRef.current?.click();
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <CreateFolderIcon fill="white" />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>Create Team</ModalTitle>
            <FontGrayNormal>Add Name, Logo to get Going</FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <CreateBoardWrapper>
        <StyledFormControl fullWidth>
          <StyledInputLabel htmlFor="name">Team Name</StyledInputLabel>
          <StyledTextField
            id="name"
            variant="outlined"
            {...register("name", { required: true })}
          />
          {errors.name?.message && (
            <ErrorMessage>{errors.name?.message}</ErrorMessage>
          )}
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <Typography
            sx={{
              color: `${theme.colors.black2} !important`,
              fontSize: "1.125rem",
              fontWeight: "400",
            }}
          >
            Upload Image
          </Typography>
          <SquareContainer>
            <Input
              type="file"
              style={{ display: "none" }}
              inputRef={(e) => {
                profileImageInputRef.current = e;
              }}
              onChange={handleProfileImageSelect}
              inputProps={{ accept: "image/*" }}
            />
            {!selectedFile && (
              <LogoUploadButton onClick={handleProfileUploadButtonClick}>
                <UploadImageIcon />
              </LogoUploadButton>
            )}
            {selectedFile && (
              <UploadedImage
                src={URL.createObjectURL(selectedFile)}
                alt="Uploaded Image"
              />
            )}
          </SquareContainer>
          {logoErrorMessage && <ErrorMessage>Logo is required</ErrorMessage>}
        </StyledFormControl>
      </CreateBoardWrapper>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Create Team
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default CreateTeamModal;
