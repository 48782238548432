import { call, put } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import { AxiosResponse } from "axios";
import {
  fetchDashboardAnalyticsService,
  createContactUsService,
  fetchDashboardAdAnalyticsService,
  fetchRecommendedPlans,
  fetchTeamMemberSavedAds,
} from "../../services/DashboardService";
import { DashoardAnalyticsReducerAction } from "./slice";
import * as Sentry from "@sentry/react";
import { notify, ToastTypes } from "../../utils/helpers";
import { ContactUsParamType } from "../../types/accountSettingsTypes";

const saga = createSliceSaga({
  name: "dashboard-analytics-saga",
  caseSagas: {
    *fetchDashboardAnalyticsData() {
      try {
        yield put(
          DashoardAnalyticsReducerAction.setDashboardLoadingState(true)
        );
        const response: AxiosResponse = yield call(
          fetchDashboardAnalyticsService
        );
        if (response?.status === 200) {
          yield put(
            DashoardAnalyticsReducerAction.setDashboardAnalyticsData(
              response?.data
            )
          );
        }
        yield put(
          DashoardAnalyticsReducerAction.setDashboardLoadingState(false)
        );
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *createContactUs(action: {
      payload: { values: ContactUsParamType; handleLoadingState: Function };
      type: string;
    }) {
      try {
        const response: AxiosResponse = yield call(
          createContactUsService,
          action.payload.values
        );
        if (response?.status === 200) {
          notify({
            message: response?.data?.message,
            type: ToastTypes.SUCCESS,
          });
          action.payload?.handleLoadingState &&
            action.payload?.handleLoadingState();
        }
      } catch (error) {
        action.payload?.handleLoadingState &&
          action.payload?.handleLoadingState();
        throw new Error();
      }
    },
    *fetchDashboardAdAnalyticsData() {
      try {
        const response: AxiosResponse = yield call(
          fetchDashboardAdAnalyticsService
        );
        if (response?.status === 200) {
          yield put(
            DashoardAnalyticsReducerAction.setDashboardAdAnalyticsData(
              response?.data
            )
          );
        }
      } catch (error) {
        throw new Error();
      }
    },
    *fetchDashboardRecommendedPlans() {
      try {
        const response: AxiosResponse = yield call(fetchRecommendedPlans);
        if (response?.status === 200) {
          yield put(
            DashoardAnalyticsReducerAction.setDashboardRecommendedPlans(
              response?.data
            )
          );
        }
      } catch (error) {
        throw new Error();
      }
    },
    *fetchTeamMemberSavedAds(action: { payload: string }) {
      try {
        const response: AxiosResponse = yield call(
          fetchTeamMemberSavedAds,
          action.payload
        );
        if (response?.status === 200) {
          yield put(
            DashoardAnalyticsReducerAction.setTeamMemberSavedAds(
              response?.data?.data[0]
            )
          );
        }
      } catch (error) {
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: DashoardAnalyticsSagaActions,
  saga: DashoardAnalyticsSaga,
  name: DashoardAnalyticsSagaName,
} = saga;
