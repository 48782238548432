import { Box, DialogTitle, Divider, Stack } from "@mui/material";
import { SaveNewAdModalProps } from "../../utils/propTypes";
import { CloseIcon, WhiteSaveIcon } from "../../assets/Icons";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledDialog,
} from "./style";
import TabsHeader from "../tabsHeader";
import { saveAdModalTabs } from "../../utils/constants";
import { useState } from "react";
import ChromeExtensionModal from "./chromeExtensionModal";
import BulkUploadModal from "./bulkUploadModal";
import { StyledDivider } from "../../globalStyle";
import { useDispatch, useSelector } from "react-redux";
import { openSaveNewAdSelector } from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const SaveNewAdModal = ({ modalTabIndex }: { modalTabIndex: number }) => {
  const [tabIndex, setTabIndex] = useState(modalTabIndex);

  const open = useSelector(openSaveNewAdSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(MetaDataReducerAction.setOpenSaveNewAd(false));
  };

  return (
    <StyledDialog onClose={handleClose} open={open} maxWidth="md">
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <WhiteSaveIcon />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>Save a New Ad</ModalTitle>
            <FontGrayNormal>
              Ads saved to Swipe Lounge will be in your library forever!
            </FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <StyledDivider />
      <Box sx={{ padding: "0.438rem" }}>
        <TabsHeader
          tabIndex={tabIndex}
          data={saveAdModalTabs}
          setTabIndex={setTabIndex}
        />
      </Box>
      {tabIndex === 0 && <ChromeExtensionModal />}
      {tabIndex === 1 && <BulkUploadModal />}
    </StyledDialog>
  );
};

export default SaveNewAdModal;
