import { CircularProgress, DialogTitle, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledOutlinedButton,
} from "./style";
import { CloseIcon } from "../../assets/Icons";
import { DeleteModalProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";

import {
  deleteAdLoadingSelector,
  openDeleteAdsModalSelector,
  selectedTableItemsSelector,
} from "../../store/swipeFile/selector";
import { useTheme } from "styled-components";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";

const DeleteAdModal = ({
  handleDelete,
  handleMultipleDelete,
}: DeleteModalProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const selectedTableItems = useSelector(selectedTableItemsSelector);

  const open = useSelector(openDeleteAdsModalSelector);
  const handleClose = () => {
    dispatch(SwipeFileReducerAction.setDeleteAdsModal(false));
  };

  const deleteAdLoader = useSelector(deleteAdLoadingSelector);

  const handleDeleteButton = () => {
    if (
      Array.isArray(selectedTableItems) &&
      selectedTableItems.length > 0 &&
      handleMultipleDelete
    ) {
      handleMultipleDelete();
    } else {
      handleDelete();
    }
  };

  return (
    <StyledDialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
      <Stack
        sx={{
          padding: "1.25rem 1.875rem 1.25rem 1.25rem",
          gap: "1.875rem",
        }}
      >
        <DialogTitle>
          <Stack direction="row" gap="0.75rem" alignItems="start">
            <Stack>
              <ModalTitle>
                {Array.isArray(selectedTableItems) &&
                selectedTableItems?.length > 0
                  ? `Are you sure you want
                  to delete the ${selectedTableItems?.length} selected Ads?`
                  : "Are you sure you want to delete this Ad?"}
              </ModalTitle>
              <FontGrayNormal>
                Ad in the boards, comments and associated tags will be removed
              </FontGrayNormal>
            </Stack>
          </Stack>
          <CloseIconWrapper aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </CloseIconWrapper>
        </DialogTitle>
        <Stack
          direction="row"
          alignItems="center"
          gap="0.625rem"
          sx={{ marginLeft: "auto" }}
        >
          <StyledOutlinedButton
            variant="outlined"
            size="small"
            padding="0.313rem 1.5rem"
            onClick={handleClose}
          >
            Cancel
          </StyledOutlinedButton>
          <StyledContainedButton
            variant="contained"
            size="small"
            background={theme.colors.red200}
            sx={{
              padding: "0.313rem 1.5rem",
            }}
            disabled={deleteAdLoader}
            startIcon={
              deleteAdLoader && <CircularProgress size={20} color="inherit" />
            }
            onClick={() => handleDeleteButton()}
          >
            Delete
          </StyledContainedButton>
        </Stack>
      </Stack>
    </StyledDialog>
  );
};

export default DeleteAdModal;
