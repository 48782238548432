import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const CardListWrapper = ({
  children,
  columns = { 350: 1, 500: 2, 1024: 3, 1324: 4 },
}: {
  children: React.ReactNode[];
  columns?: {
    [key: number]: number;
  };
}) => {
  return (
    <ResponsiveMasonry columnsCountBreakPoints={columns}>
      <Masonry columnsCount={4} gutter="10px" style={{ gap: "10px" }}>
        {children}
      </Masonry>
    </ResponsiveMasonry>
  );
};

export default CardListWrapper;
