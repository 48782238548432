import * as yup from "yup";

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const signUpValidationSchema = yup.object({
  fullName: yup
    .string()
    .max(100, "Maximum 100 characters allowed!")
    .required("Full Name is Required!"),
  password: yup
    .string()
    .matches(
      passwordRegex,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is Required!"),
  email: yup
    .string()
    .email("Invalid Email Format!")
    .required("Email is Required!"),
});

export const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email Format!")
    .required("Email is Required!")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
  password: yup
    .string()
    .matches(
      passwordRegex,
      "Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is Required!"),
});

export const createFolderValidationSchema = yup.object().shape({
  name: yup.string().required("Folder Name is Required!"),
});

export const createBoardValidationSchema = yup.object().shape({
  name: yup.string().required("Board Name is Required!"),
  description: yup.string(),
  folderId: yup.string(),
});

export const accountSettingsValidationSchema = yup.object({
  fullName: yup.string().max(100, "Maximum 100 characters allowed!"),
  phoneNumber: yup
    .string()
    .matches(/^\d{1,10}$/, "Only numbers with a maximum of 10 digits allowed"),
  countryCode: yup.string(),
});

export const resetPasswordValidationSchema = yup.object({
  oldPassword: yup
    .string()
    .matches(
      passwordRegex,
      "Old Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is Required!"),
  newPassword: yup
    .string()
    .matches(
      passwordRegex,
      "New Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is Required!"),
  confirmPassword: yup
    .string()
    .matches(
      passwordRegex,
      "Confirm Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .oneOf(
      [yup.ref("newPassword")],
      "New Password and Confirm Password do not match"
    )
    .required("Password is Required!"),
});

export const saveTranscriptValidationSchema = yup.object().shape({
  name: yup.string().required("Transcript Name is Required!"),
});

export const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid Email Format!")
    .required("Email is Required!")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
});

export const resetPasswordFormValidationSchema = yup.object({
  password: yup
    .string()
    .matches(
      passwordRegex,
      "New Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is Required!"),
  confirmPassword: yup
    .string()
    .matches(
      passwordRegex,
      "Confirm Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, and 1 special character."
    )
    .min(8, "Confirm Password must be at least 8 characters long.")
    .oneOf([yup.ref("password")], "Password and Confirm Password do not match")
    .required("Confirm Password is Required!"),
});

export const addTeamMemberValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is Required!")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email format"),
});

export const updateBoardValidationSchema = yup.object().shape({
  name: yup.string(),
});

export const createTeamValidationSchema = yup.object().shape({
  name: yup.string().required("Folder Name is Required!"),
});

export const contactUsValidationSchema = yup.object().shape({
  name: yup.string().required("Name is Required!"),
  email: yup
    .string()
    .email("Invalid Email Format!")
    .required("Email is Required!"),
  message: yup.string().required("Message is Required!"),
});
