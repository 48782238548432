import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";

import {
    CardContainer,
    CardSubTitle,
    CardTitle,
    MainVideoWrapper,
} from "./style";
import { Card, Typography} from "@mui/material";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../globalStyle";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {theme} from "../../utils/theme";
import {VideoPlayButton} from "../commonComponents/adCard/style";
import { PauseIcon, PlayIcon} from "../../assets/Icons";
const NavigateButtonStyle = {
    fill: theme.colors.gray1,
    background: "white",
    border: `1px solid ${theme.colors.border1}`,
    borderRadius: "50%",
    padding: "0.57rem",
    width: "1.714rem",
    height: "1.714rem",
    top: "43%"
};
const NextArrow = ({ className, onClick }: CustomArrowProps) => {
    return (
        <NavigateNextIcon
            onClick={onClick}
            className={className}
            sx={NavigateButtonStyle}
        />
    );
};

const PrevArrow = ({ className, onClick }: CustomArrowProps) => {
    return (
        <NavigateBeforeIcon
            onClick={onClick}
            className={className}
            sx={NavigateButtonStyle}
        />
    );
};

const Vid = ({ index, src, handleVideoPlay, setStartVideo, startVideo }:{ index:number, src:string, handleVideoPlay:Function, setStartVideo:Function, startVideo:boolean }) => {
    const vidRef = useRef<HTMLVideoElement>(null);
    useEffect(() => {
        if (startVideo) {
            const active = vidRef.current?.closest(".slick-active");
            if (!active) {
                vidRef.current?.pause();
            }
        }
        else {
            vidRef.current?.pause();
        }
    }, [startVideo]);
    return (
        <>
            <video  className={`video-${index}`} ref={vidRef} width="100%" height="100%" controls
                    onEndedCapture={()=>setStartVideo(false)}
                    onPause={()=>setStartVideo(false)}
                    onPlay={()=>setStartVideo(true)}
            >
                <source src={src} type="video/mp4" />
            </video>
            <VideoPlayButton onClick={()=>handleVideoPlay(index,vidRef)} sx={{
                position: "relative !important",
                bottom:"50% !important",
                top: "unset !important"
            }}>
                {startVideo ? <PauseIcon /> : <PlayIcon />}
            </VideoPlayButton>
        </>

    );
};
const LearnSwipeLoungeSection = () => {
    const [startVideo, setStartVideo] = useState(false);
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        style: {
            padding: "0 1.947rem" ,
            width:"100% important"
        },
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        dotsClass: "dashboard-carousel-button-bar",
        beforeChange:() => {
          setStartVideo(false)
        }
    };

    const theme = useTheme();

    const content = [
        {
            videoUrl: "https://gcom-content-service-stage.s3.ap-south-1.amazonaws.com/swipeLounge/media/243431753586.mp4",
            tldrText:
                "Swipe Ad is the best way save ads forever and create a living ad swipe file. Your library contains all the ads saved to your account using our Chrome Extension or from Discovery - they are organized into Folders and Boards.",
        },
        {
        videoUrl: "https://gcom-content-service-stage.s3.ap-south-1.amazonaws.com/swipeLounge/media/1122720817027.mp4",
            tldrText:
        "Explore lounge is a real-time feed of over 200k ads being saved by other Swipe Lounge users. We highly recommend using the AI search function to search by product name for example “collagen” or targeted ad copy like “Black Friday”.",
        },

        {
        videoUrl: "https://gcom-content-service-stage.s3.ap-south-1.amazonaws.com/swipeLounge/media/1579420191641.mp4",
            tldrText:
        "Explore lounge is a real-time feed of over 200k ads being saved by other Swipe Lounge users. We highly recommend using the AI search function to search by product name for example “collagen” or targeted ad copy like “Black Friday”.",
        },
    ]
    const handleVideoPlay = (index:number,vidRef:React.RefObject<HTMLVideoElement>) => {
        if (vidRef.current) {
            const video = vidRef.current;
            const active = video?.closest(".slick-active");
            let isPlaying = false;
            if(video){
                 isPlaying =
                    video?.currentTime > 0 &&
                    !video?.paused &&
                    !video?.ended &&
                    video?.readyState > video?.HAVE_CURRENT_DATA;
            }
            if(active){
                if (!isPlaying) {
                    setStartVideo(true);
                    video?.play();
                } else{
                    setStartVideo(false);
                    video?.pause();
                }
            }
        }
    };
    return (
        <CardContainer  sx={{
                height:"32.875rem"
        }}>
            <CardTitle>Learn to Use Swipe Lounge</CardTitle>
            <CardSubTitle>Tips & Tutorials for your creative workflow</CardSubTitle>
            <StyledDivider sx={{ margin: "1.25rem -1.25rem" }} />
            <MainVideoWrapper>
                <Typography
                    fontSize="1.071rem"
                    fontWeight="400"
                    color={theme.colors.gray1}
                    sx={{marginLeft: "2.125rem", paddingBottom: "1.25rem"}}
                >
                    Save Ads from Facebook Ads Library
                </Typography>
                    <Slider {...settings}>
                        {
                            content.map((item,index)=>(
                                <Card
                                    key={`tutorial_player_${index}`}
                                    sx={{
                                        width:"100%",
                                        height: "19.465rem",
                                        border: "none",
                                        borderRadius: "0.714rem",
                                    }}
                                >
                                    <Vid index={index}
                                         src={item.videoUrl}
                                         handleVideoPlay={handleVideoPlay}
                                         setStartVideo={setStartVideo}
                                         startVideo={startVideo}
                                    />
                                </Card>
                            ))
                        }
                    </Slider>
            </MainVideoWrapper>

        </CardContainer>
    );
};

export default LearnSwipeLoungeSection;
