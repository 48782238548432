import {
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      border-radius: 0.938rem;
    }
  }
`;

export const ModalIconWrapper = styled.div`
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.5rem;
  background: linear-gradient(
    94.04deg,
    rgb(28, 117, 188) 2.44%,
    rgb(0, 174, 239) 112.73%
  );
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExtensionBoxWrapper = styled.div`
  border: ${({ theme }) => `1px solid ${theme.colors.border1}`};
  border-radius: 2px;
  margin: 1.25rem 1.875rem;
  padding: 1.875rem 3.75rem 1.875rem 1.25rem;
`;

export const ExtensionLogoWrapper = styled.div`
  width: 4.875rem;
  height: 4.875rem;
  border-radius: 9.95px;
  background: #151616;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledContainedButton = styled(Button)<{ background?: string }>`
  && {
    background: ${({ background, theme }) =>
      background ? background : theme.colors.backgroundPrimary} !important;
    color: white;
    border-radius: 2rem;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
  }
  width: fit-content;
`;

export const PlatformsWrapper = styled.div`
  padding: 1.875rem 1.875rem 3rem;
`;

export const StyledOutlinedButton = styled(Button)<{ padding?: string }>`
  && {
    border-radius: 2rem !important;
    color: ${({ theme }) => theme.colors.black2};
    text-transform: capitalize;
    padding: ${({ padding }) => padding};
    font-size: 0.938rem;
    border: 1px solid ${({ theme }) => theme.colors.border1};
  }
`;

export const PlatformIconWrapper = styled.img`
  margin-right: 0.625rem;
`;

export const BulkUploadWrapper = styled.div`
  padding: 1.25rem 1.875rem;
`;

export const DragDropContainer = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.blue};
  border-radius: 2px;
  padding: 3.063rem 13.125rem;
  background: ${({ theme }) => theme.colors.background2};
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1.25rem 0 1.875rem;
`;

export const ModalTitle = styled(Typography)`
  && {
    font-size: 1.375rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.black2};
  }
`;

export const FontGrayNormal = styled(Typography)`
  && {
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const CloseIconWrapper = styled(IconButton)`
  && {
    position: absolute;
    right: 1.5rem;
    top: 5%;
    color: ${({ theme }) => theme.colors.black2};
  }
`;
export const CreateBoardWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 3.125rem 1.875rem 1.875rem 1.875rem;
  gap: 3rem;
`;

export const StyledTextField = styled(TextField)`
  && {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.border1} !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
      -webkit-background-clip: text;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    color: ${({ theme }) => theme.colors.black2} !important;
    font-size: 1.125rem;
    font-weight: 400;
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
  }
  & > label {
    top: -3.125rem;
    left: -0.938rem;
  }
`;

export const StyledSelectField = styled(Select)`
  && {
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.colors.border1} !important;
    }

    .MuiSelect-icon {
      margin: 0.25rem 0.625rem 0 0;
    }
  }
`;

export const FolderTitle = styled.span`
  && {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.829rem;
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const StyledBoardTextField = styled(TextField)`
  cursor: pointer;
  line-height: 1.143rem;
  font-weight: 500;
  font-size: 0.938rem;
  && .MuiInputBase-input.Mui-disabled {
    cursor: pointer;
    color: ${({ theme }) =>
      theme.colors.black2};
    opacity: 1;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.black2};
  }

  && .MuiInput-underline:before {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black2} !important;
  }

  && .MuiInput-underline:after {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black2} !important;
  }

  && .MuiInput-underline.Mui-disabled:before {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black2} !important;
  }

  && .MuiInput-underline.Mui-disabled:after {
    border-bottom: 1px solid ${({ theme }) => theme.colors.black2} !important;
  }
`;
