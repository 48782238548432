import * as React from "react";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { MenuPopoverProps } from "../../types/Popover";
import { useState } from "react";
import { PopoverMenu, StyledFormGroup } from "./style";
import { useTheme } from "styled-components";
import { staticFormats, staticPlatforms } from "../../utils/constants";
import CheckBoxGroup from "../commonComponents/checkboxGroup";
import FilterSearchbox from "../commonComponents/filterSearchbox";
import { useDispatch, useSelector } from "react-redux";
import {
  TagsListsSelector,
  boardFilterSearchTextSelector,
  boardItemsSelector,
  brandFilterSearchTextSelector,
  brandListsSelector,
  tagFilterSearchTextSelector,
} from "../../store/metadata/selector";

import { BoardIcon, TagsIcon } from "../../assets/Icons";
import { listType } from "../../store/metadata/types";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { MetaDataSagaActions } from "../../store/metadata/sagas";

const NoDataFound = () => {
  const theme = useTheme();
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      margin={"0.5rem"}
      color={theme.colors.black2}
    >
      No data found.
    </Box>
  );
};

const AccordionWrapper = styled((props: AccordionProps) => (
  <Accordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ background: "white !important", padding: "1rem", minHeight: "3rem" }}
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.8rem", color: "#414042" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: `0 0 0 ${theme.spacing(1)}`,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0.5rem 1rem 1rem",
  backgroundColor: "#F9F9FA",
}));

const SelectAllHeaderWrapper = ({
  selectAllHandler,
  selectedAll,
}: {
  selectAllHandler: () => void;
  selectedAll: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        paddingBlock: "0.75rem",
      }}
    >
      <Typography
        fontSize={"0.938rem"}
        fontWeight={500}
        color={theme.colors.neutral50}
      >
        Show from
      </Typography>
      <Button
        sx={{
          color: theme.colors.link,
          fontWeight: 600,
          fontSize: "0.938rem",
          textTransform: "capitalize",
          padding: 0,
        }}
        onClick={selectAllHandler}
      >
        {selectedAll ? "Deselect All" : "Select All"}
      </Button>
    </Stack>
  );
};

// TODO need to create component for brand, boards, format, and platform listing
const MenuPopover = ({
  anchorEl,
  handlePopoverClose,
  handleFilter,
  selectedBrands,
  selectedPlatforms,
  renderBoardFilter,
  selectedBoards,
  userId,
  renderTagFilter,
  selectedTags,
}: MenuPopoverProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | false>("");
  const [selectedFormats, setSelectedFormats] = useState<number[]>([]);

  const boardLists = useSelector(boardItemsSelector);
  const brandLists = useSelector(brandListsSelector);
  const tagLists = useSelector(TagsListsSelector);
  const brandFilterSearchText = useSelector(brandFilterSearchTextSelector);
  const boardFilterSearchText = useSelector(boardFilterSearchTextSelector);
  const tagFilterSearchText = useSelector(tagFilterSearchTextSelector);

  const handleBrandSearch = () => {
    dispatch(
      MetaDataSagaActions.fetchBrandLists({
        search: brandFilterSearchText,
        ...(userId && { userId }),
      })
    );
  };

  const handleBrandSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      MetaDataReducerAction.setBrandFilterSearchText(event.target.value)
    );
    if (event.target.value === "") {
      handleBrandSearch();
    }
  };

  const handleBrandSearchKeyDown: React.KeyboardEventHandler<
    HTMLInputElement
  > = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") handleBrandSearch();
  };

  const handleBoardSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      MetaDataReducerAction.setBoardFilterSearchText(event.target.value)
    );
    if (event.target.value === "") {
      dispatch(
        MetaDataSagaActions.fetchBoardList({ search: boardFilterSearchText })
      );
    }
  };

  const handleBoardSearch = () => {
    dispatch(
      MetaDataSagaActions.fetchBoardList({ search: boardFilterSearchText })
    );
  };

  const handleBoardSearchKeyDown: React.KeyboardEventHandler<
    HTMLInputElement
  > = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") handleBoardSearch();
  };

  const handleSelectCheckbox = ({
    checked,
    list,
    setSelected,
    selected,
    selectAll,
    id,
  }: {
    checked: boolean;
    list: any;
    setSelected?: any;
    selected: number[];
    selectAll?: boolean;
    id?: string;
  }) => {
    if (checked) {
      selectAll
        ? setSelected(list.map((n: any) => n._id))
        : setSelected([...selected, id!]);
    } else {
      selectAll
        ? setSelected([])
        : setSelected(selected.filter((item) => item.toString() !== id));
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleChangeLists = (
    selectAll: boolean,
    checked: boolean,
    stateName: string,
    list: listType[],
    id?: string
  ) => {
    if (handleFilter) {
      handleFilter(selectAll, checked, stateName, list, id);
    }
  };

  const handleTagSearch = () => {
    dispatch(
      MetaDataSagaActions.fetchTagLists({ searchValue: tagFilterSearchText })
    );
  };

  const handleTagSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(MetaDataReducerAction.setTagFilterSearchText(event.target.value));
    if (event.target.value === "") {
      dispatch(
        MetaDataSagaActions.fetchTagLists({
          searchValue: boardFilterSearchText,
        })
      );
    }
  };

  const handleTagSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.stopPropagation();
    if (event.key === "Enter") handleTagSearch();
  };

  return (
    <PopoverMenu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          overflow: "hidden",
          width: "24.375rem",
        },
      }}
    >
      <AccordionWrapper
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel1" ? 700 : 500}
          >
            Brand
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FilterSearchbox
            placeholder="Search for brands..."
            value={brandFilterSearchText}
            handleChange={handleBrandSearchOnChange}
            handleKeyDown={handleBrandSearchKeyDown}
            handleSearchIconClick={handleBrandSearch}
          />
          {brandLists.length === 0 ? (
            <NoDataFound />
          ) : (
            <>
              <SelectAllHeaderWrapper
                selectedAll={brandLists?.length === selectedBrands?.length}
                selectAllHandler={() =>
                  handleChangeLists(
                    true,
                    !(brandLists?.length === selectedBrands?.length),
                    "selectedPageIds",
                    brandLists
                  )
                }
              />
              <Divider
                sx={{ color: theme.colors.border3, marginInline: "-1rem" }}
              />
              <StyledFormGroup>
                <CheckBoxGroup
                  list={brandLists}
                  selected={selectedBrands}
                  onChangeHandler={(
                    event: React.ChangeEvent<HTMLInputElement>,
                    id: string
                  ) =>
                    handleChangeLists(
                      false,
                      event.target.checked,
                      "selectedPageIds",
                      brandLists,
                      id
                    )
                  }
                />
              </StyledFormGroup>
            </>
          )}
        </AccordionDetails>
      </AccordionWrapper>

      {renderBoardFilter && (
        <AccordionWrapper
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography
              fontSize={"1.125rem"}
              fontWeight={expanded === "panel2" ? 700 : 500}
            >
              Board
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FilterSearchbox
              placeholder="Search for boards..."
              value={boardFilterSearchText}
              handleChange={handleBoardSearchOnChange}
              handleKeyDown={handleBoardSearchKeyDown}
              handleSearchIconClick={handleBoardSearch}
            />
            {boardLists.length === 0 ? (
              <NoDataFound />
            ) : (
              <>
                <SelectAllHeaderWrapper
                  selectedAll={boardLists?.length === selectedBoards?.length}
                  selectAllHandler={() =>
                    handleChangeLists(
                      true,
                      !(boardLists?.length === selectedBoards?.length),
                      "selectedBoardIds",
                      boardLists
                    )
                  }
                />
                <Divider
                  sx={{ color: theme.colors.border3, marginInline: "-1rem" }}
                />
                <StyledFormGroup>
                  <CheckBoxGroup
                    list={boardLists}
                    selected={selectedBoards}
                    staticIcon={<BoardIcon fill={theme.colors.gray1} />}
                    onChangeHandler={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      id: string
                    ) =>
                      handleChangeLists(
                        false,
                        event.target.checked,
                        "selectedBoardIds",
                        boardLists,
                        id
                      )
                    }
                  />
                </StyledFormGroup>
              </>
            )}
          </AccordionDetails>
        </AccordionWrapper>
      )}

      {renderTagFilter && (
        <AccordionWrapper
          expanded={expanded === "tags"}
          onChange={handleChange("tags")}
        >
          <AccordionSummary aria-controls="tags-content" id="tags-header">
            <Typography
              fontSize={"1.125rem"}
              fontWeight={expanded === "tags" ? 700 : 500}
            >
              Tags
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FilterSearchbox
              placeholder="Search for tags..."
              value={tagFilterSearchText}
              handleChange={handleTagSearchOnChange}
              handleKeyDown={handleTagSearchKeyDown}
              handleSearchIconClick={handleTagSearch}
            />
            {tagLists.length === 0 ? (
              <NoDataFound />
            ) : (
              <>
                <SelectAllHeaderWrapper
                  selectedAll={tagLists?.length === selectedTags?.length}
                  selectAllHandler={() =>
                    handleChangeLists(
                      true,
                      !(tagLists?.length === selectedTags?.length),
                      "selectedTagIds",
                      tagLists
                    )
                  }
                />
                <Divider
                  sx={{ color: theme.colors.border3, marginInline: "-1rem" }}
                />
                <StyledFormGroup>
                  <CheckBoxGroup
                    list={tagLists}
                    selected={selectedTags}
                    staticIcon={<TagsIcon />}
                    onChangeHandler={(
                      event: React.ChangeEvent<HTMLInputElement>,
                      id: string
                    ) =>
                      handleChangeLists(
                        false,
                        event.target.checked,
                        "selectedTagIds",
                        tagLists,
                        id
                      )
                    }
                  />
                </StyledFormGroup>
              </>
            )}
          </AccordionDetails>
        </AccordionWrapper>
      )}

      {/* <AccordionWrapper
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel3" ? 700 : 500}
          >
            Format
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledFormGroup padding="0 1.25rem">
            <CheckBoxGroup
              list={staticFormats}
              selected={selectedFormats}
              onChangeHandler={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) =>
                handleSelectCheckbox({
                  checked: event.target.checked,
                  list: staticFormats,
                  setSelected: setSelectedFormats,
                  selected: selectedFormats,
                  selectAll: false,
                  id,
                })
              }
            />
          </StyledFormGroup>
        </AccordionDetails>
      </AccordionWrapper> */}

      <AccordionWrapper
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography
            fontSize={"1.125rem"}
            fontWeight={expanded === "panel4" ? 700 : 500}
          >
            Platform
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <StyledFormGroup padding="0 1.25rem">
            <CheckBoxGroup
              list={staticPlatforms}
              selected={selectedPlatforms}
              onChangeHandler={(
                event: React.ChangeEvent<HTMLInputElement>,
                id: string
              ) =>
                handleChangeLists(
                  false,
                  event.target.checked,
                  "selectedPlatformIds",
                  staticPlatforms,
                  id
                )
              }
            />
          </StyledFormGroup>
        </AccordionDetails>
      </AccordionWrapper>
    </PopoverMenu>
  );
};

export default MenuPopover;
