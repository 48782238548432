import { Outlet } from "react-router";
import Sidebar from "../sidebar";
import { Box } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AccountSagaActions } from "../../store/account/sagas";
import useConfig from "../../hooks/useConfig";
import { useTheme } from "styled-components";
import { MainLayoutWrapper } from "../../pageComponents/auth/style";
import { MetaDataSagaActions } from "../../store/metadata/sagas";

const MainLayout = () => {
  const { isAuthenticated } = useAuth();

  const dispatch = useDispatch();
  const theme = useTheme();
  const { drawerOpen } = useConfig();

  useEffect(() => {
    if (isAuthenticated) {
      const userDetails = JSON.parse(localStorage.getItem("User") as string);
      dispatch(AccountSagaActions.fetchUserDetails(userDetails?.user?._id));
    }
  }, [isAuthenticated]);


  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(MetaDataSagaActions.checkNotification());
    }, 60000);

    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {isAuthenticated && <Sidebar />}
      <MainLayoutWrapper drawerOpen={drawerOpen}>
        <Outlet />
      </MainLayoutWrapper>
    </Box>
  );
};

export default MainLayout;
