import {
  CardContainer,
  ManageTeamInfo,
  CardSubTitle,
  CardTitle,
  BuyNowButtonWrapper,
} from "./style";
import { Box, Typography, Stack, Chip, Grid } from "@mui/material";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../globalStyle";
import cardBg from "../../assets/images/subCardBg.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { DashoardAnalyticsSagaActions } from "../../store/dashboardAnalytics/sagas";
import { recommendedPlansSelector } from "../../store/dashboardAnalytics/selector";

const SubscriptionPlansSection = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(DashoardAnalyticsSagaActions.fetchDashboardRecommendedPlans());
  }, []);

  const recommendedPlansData = useSelector(recommendedPlansSelector);

  return (
    <CardContainer
      sx={{
        height: "32.875rem",
        padding: "1.25rem 0",
      }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        sx={{ padding: "0 1.25rem" }}
      >
        <ManageTeamInfo>
          <Stack>
            <CardTitle>Subscription Plans</CardTitle>
            <CardSubTitle>Lorem ipsum dolor sit amet consectetur.</CardSubTitle>
          </Stack>
        </ManageTeamInfo>
      </Stack>

      <StyledDivider sx={{ margin: "1.25rem 0" }} />
      <Grid container display="flex" gap="10px" padding="0 1.25rem">
        <Grid
          item
          xs={12}
          md={5.8}
          display="flex"
          flexDirection="column"
          gap="15px"
          padding="15px 25px"
          border="1px solid #EAECEE"
          borderRadius="10px"
        >
          <Box>
            <Chip
              label="Active Plan"
              sx={{
                padding: "3px 7px",
                borderRadius: "20px",
                background: "#EDFCF5",
                border: "1px solid #D4F7E6",
                color: "#1EAF83",
                fontSize: "14px",
                fontWeight: 500,
              }}
            />
          </Box>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Free Plan
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              lineHeight: "26px",
              color: theme.colors.gray1,
            }}
          >
            A Plan to get You started with the Application. We hope you enjoy
            the Free Benefits and there is always an option to update for more.{" "}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={5.8}
          display="flex"
          flexDirection="column"
          gap="15px"
          padding="15px 25px"
          border="1px solid #EAECEE"
          borderRadius="10px"
          sx={{
            background: `url(${cardBg})`,
            backgroundRepeat: "no-repeat",
            // backgroundSize: "contain",
            color: theme.colors.white,
          }}
        >
          <Box>
            <Chip
              label="Recommended Plan"
              sx={{
                padding: "3px 7px",
                borderRadius: "20px",
                background: "#FADD47",
                color: "black",
                fontSize: "14px",
                fontWeight: 500,
              }}
            />
          </Box>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            {recommendedPlansData[0]?.name}
          </Typography>
          <Box display="flex" flexDirection="column" gap="10px">
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              maxHeight="13rem"
              overflow="hidden"
            >
              {recommendedPlansData[0]?.featureList?.map((item: string) => (
                <Typography
                  sx={{
                    fontSize: "14px",
                    lineHeight: "26px",
                    color: theme.colors.white,
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Box marginY="10px">
              <BuyNowButtonWrapper to="/subscription">
                Buy Now
              </BuyNowButtonWrapper>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CardContainer>
  );
};

export default SubscriptionPlansSection;
