import { CircularProgress, DialogTitle, Divider, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  CreateBoardWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledFormControl,
  StyledInputLabel,
  StyledTextField,
} from "./style";
import { CloseIcon } from "../../assets/Icons";
import { SaveTranscriptValueProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { saveTranscriptValidationSchema } from "../../utils/formValidationSchema";
import { ErrorMessage } from "../../pageComponents/auth/style";
import {
  openSaveTranscriptSelector,
  saveTranscriptLoaderSelector,
} from "../../store/metadata/selector";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { webkitInputCSSFix } from "../../utils/helpers";

const SaveTranscriptModal = ({ adId }: { adId: string }) => {
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<SaveTranscriptValueProps>({
    resolver: yupResolver(saveTranscriptValidationSchema),
  });
  const dispatch = useDispatch();
  const loading = useSelector(saveTranscriptLoaderSelector);
  const openModal = useSelector(openSaveTranscriptSelector);

  const handleResetState = () => {
    reset();
  }

  const onSubmit = () => {
    const values = getValues();
    dispatch(
      MetaDataSagaActions.saveAdTranscript({ name: values.name, adId: adId, handleResetState: handleResetState })
    );
  };

  const handleCloseModal = () => {
    reset();
    dispatch(MetaDataReducerAction.setOpenSaveTranscriptModal(false));
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <Stack>
            <ModalTitle>Save Transcript</ModalTitle>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <CreateBoardWrapper>
        <StyledFormControl fullWidth>
          <StyledInputLabel htmlFor="name">Transcript Name</StyledInputLabel>
          <StyledTextField
            id="name"
            variant="outlined"
            sx={webkitInputCSSFix}
            {...register("name", { required: true })}
          />
          {errors.name?.message && (
            <ErrorMessage>{errors.name?.message}</ErrorMessage>
          )}
        </StyledFormControl>
      </CreateBoardWrapper>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Save
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default SaveTranscriptModal;
