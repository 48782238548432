import { useContext, useEffect, useState } from "react";
import {
  AccountLoginText,
  AccountText,
  BottomButtonWrapper,
  FormFieldWrapper,
  FormWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  CredentialSection,
  ErrorMessage,
  ImageSection,
  LoginTextBox,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForgotPasswordFormValuesProp } from "../../../utils/propTypes";
import { forgotPasswordValidationSchema } from "../../../utils/formValidationSchema";
import { ForgotPasswordService } from "../../../services/AuthServices";
import Carousel from "../../../components/commonComponents/carousel";
import { CarouselContext } from "../../../context/CarouselContext";
import { useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { ForgotOrResetPasswordResponse } from "../../../types/AuthTypes";
import { webkitInputCSSFix } from "../../../utils/helpers";
import { LoginResponseIcon } from "../../../assets/Icons";
import { useTheme } from "styled-components";
import { CarouselType } from "../../../types/Carousel";

const ForgotPassword = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordFormValuesProp>({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });
  const { carouselImages } = useContext(CarouselContext);

  const onSubmit = async () => {
    const values = getValues();
    values.email = values.email.toLowerCase();
    setLoading(true);
    const response = (await ForgotPasswordService(
      values
    )) as AxiosResponse<ForgotOrResetPasswordResponse>;
    if (response.status === 200) {
      setSuccessMessage("Forgot Password mail sent successful!");
      reset();
      setLoading(false);
    } else {
      setLoading(false);
    }
    if (axios.isAxiosError(response)) {
      setErrorMessage(response.response?.data?.message);
      setLoading(false);
    }
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    submitHandler: () => void
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };

  const renderCarousel = (images: CarouselType[], direction: "ltr" | "rtl") => (
    <div className={`Marquee-content Marquee-content-${direction}`}>
      {[...Array(6)].flatMap((_, repetitionIndex) =>
        images.map((image, index) => (
          <div className="Marquee-tag" key={`${index}-${repetitionIndex}`}>
            <img src={image.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))
      )}
    </div>
  );

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    if (errorMessage || successMessage) {
      timer = setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [successMessage, errorMessage]);

  return (
    <LoginLayoutContainer container>
      <CredentialSection xs={12} md={6}>
        <LogoWrapper src={Logo} alt="app-logo" />
        <PageTitle
          style={{
            marginTop: "15%",
          }}
        >
          Forgot Password
        </PageTitle>
        <AccountText>
          {"Already have an account ? "}
          <AccountLoginText to="/login">Log In</AccountLoginText>
        </AccountText>

        {errorMessage && (
          <LoginTextBox style={{ background: theme.colors.error }}>
            <LoginResponseIcon />
            {errorMessage}
          </LoginTextBox>
        )}
        {successMessage && (
          <LoginTextBox style={{ background: theme.colors.green1 }}>
            <LoginResponseIcon />
            {successMessage}
          </LoginTextBox>
        )}

        <FormWrapper>
          <FormFieldWrapper>
            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem  !important",
                  color: "white",
                  border: "3px solid white",
                  borderRadius: "2px",
                  zIndex: 2,
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{ color: "white" }}
                htmlFor="outlined-adornment-email-login"
              >
                Email
              </InputLabel>
              <OutlinedInput
                {...register("email", { required: true })}
                id="outlined-adornment-email-login"
                type="email"
                sx={webkitInputCSSFix}
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
              />
            </FormControl>
            {errors.email?.message && (
              <ErrorMessage>{errors.email?.message}</ErrorMessage>
            )}
          </FormFieldWrapper>
          <BottomButtonWrapper>
            <StyledLoginButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Send Email
            </StyledLoginButton>
          </BottomButtonWrapper>
        </FormWrapper>
      </CredentialSection>
      <ImageSection xs={12} md={6}>
        {carouselImages.carousel_one?.length > 0 && (
          <>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_one, "ltr")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_two, "rtl")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_three, "ltr")}
            </div>
          </>
        )}
      </ImageSection>
    </LoginLayoutContainer>
  );
};

export default ForgotPassword;
