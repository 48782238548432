import { MenuPopoverProps } from "../../types/Popover";
import { Button, Menu, Stack } from "@mui/material";
import { useTheme } from "styled-components";
import { sortingOptions } from "../../utils/constants";
import { CheckMarkIcon } from "../../assets/Icons";
import { CustomButton } from "../../globalStyle";

const SortPopover = ({
  anchorEl,
  handlePopoverClose,
  handleSortFilter,
  selectedFilterOption,
}: MenuPopoverProps) => {
  const theme = useTheme();

  const handleSorting = (value: string) => {
    const sortField =
      value === "longestRunning" ? "startedRunningDate" : "createdAt";

    const sortOrder = value === "Newest" ? -1 : 1;

    const sortObject = { [sortField]: sortOrder };

    if (handleSortFilter) {
      handleSortFilter(value, sortObject);
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "0.938rem",
          width: "17.375rem",
        },
        ".MuiList-root": {
          padding: "0",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        },
      }}
    >
      {sortingOptions?.map((item, index) => (
        <Stack
          key={index}
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          onClick={() => handleSorting(item.value)}
        >
          <CustomButton
            disableRipple
            key={item.label}
            sx={{
              fontSize: "0.938rem",
              fontWeight: "500",
              color: theme.colors.black2,
              padding: "0.375rem 0.5rem",
            }}
          >
            {item.label}
          </CustomButton>
          {selectedFilterOption === item.value && <CheckMarkIcon />}
        </Stack>
      ))}
    </Menu>
  );
};

export default SortPopover;
