import { MenuPopoverProps } from "../../types/Popover";
import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Divider,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "styled-components";
import {
  ArrowRightIcon,
  CloseIcon,
  NoNotificationIcon,
} from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { useDispatch, useSelector } from "react-redux";
import { CustomButton } from "../../globalStyle";
import { useEffect } from "react";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import {
  acceptJoinTeamLoaderSelector,
  notificationListSelector,
  notificationLoaderSelector,
  rejectJoinTeamLoaderSelector,
} from "../../store/metadata/selector";
import { notificationType } from "../../store/metadata/types";
import { formatCreatedDate } from "../../utils/helpers";
import { renderNotificationButtonText } from "../../utils/constants";

export const decodeHtmlEntities = (str: string) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = str;
  return txt.value;
};

export const stripHtmlTags = (str: string) => {
  return str.replace(/<[^>]+>/g, "");
};

export const convertHtmlToText = (htmlString: string) => {
  return decodeHtmlEntities(stripHtmlTags(htmlString));
};

const ItemRow = ({
  children,
  handleClick,
}: {
  children: React.ReactNode;
  handleClick?: () => void;
}) => {
  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        padding: "0.563rem 0.25rem 0.563rem 0.063rem",
        justifyContent: "space-between",
      }}
      onClick={handleClick}
    >
      {children}
    </Stack>
  );
};

const NotificationPopover = ({
  anchorEl,
  handlePopoverClose,
}: MenuPopoverProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const notificationList = useSelector(notificationListSelector);
  const limitedNotificationList = notificationList?.slice(0, 4);
  const acceptInvitationLoader = useSelector(acceptJoinTeamLoaderSelector);
  const rejectInvitationLoader = useSelector(rejectJoinTeamLoaderSelector);

  const loading = useSelector(notificationLoaderSelector);

  const getNotificationList = () => {
    if (anchorEl !== null) {
      dispatch(MetaDataSagaActions.notificationList());
    }
  };

  useEffect(() => {
    getNotificationList();
  }, [anchorEl]);

  const handleJoin = (
    notificationId: string,
    teamId: string,
    status: boolean
  ) => {
    dispatch(
      MetaDataSagaActions.joinTeam({
        notificationId: notificationId,
        teamId: teamId,
        status: status,
      })
    );
  };

  

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "0.938rem",
          width: "23rem",
        },
        ".MuiList-root": {
          display: "flex",
          flexDirection: "column",
          gap: "0.938rem",
          padding: "0.5rem 0",
        },
      }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 0.75rem 0 0.25rem",
        }}
      >
        <Typography
          fontSize="0.938rem"
          fontWeight="500"
          color={theme.colors.black2}
        >
          Notifications
        </Typography>
        <div
          onClick={handlePopoverClose}
          style={{
            cursor: "pointer",
          }}
        >
          <CloseIcon />
        </div>
      </Stack>
      <Divider sx={{ marginInline: "-0.938rem" }} />
      {!loading ? (
        <>
          {Array.isArray(limitedNotificationList) &&
          limitedNotificationList.length > 0 ? (
            <>
              {limitedNotificationList?.map(
                (notification: notificationType) => {
                  const titleText = convertHtmlToText(notification.title);
                  return (
                    <>
                      <ItemRow key={notification._id}>
                        <Avatar
                          src={
                            notification?.imageUrl
                              ? notification?.imageUrl
                              : "assests/images/user-logo.png"
                          }
                        />
                        <Stack
                          sx={{
                            marginLeft: notification.read
                              ? "0.2rem"
                              : "0.75rem",
                            width: "70%",
                          }}
                        >
                          <Typography
                            fontSize="0.875rem"
                            fontWeight="500"
                            color={theme.colors.black2}
                          >
                            {titleText}
                          </Typography>
                          <Typography
                            fontSize="0.625rem"
                            fontWeight="400"
                            color={theme.colors.gray1}
                          >
                            {formatCreatedDate(notification?.createdAt ?? "")}
                          </Typography>
                        </Stack>

                        {!notification.read && (
                          <Badge
                            variant="dot"
                            sx={{
                              ".MuiBadge-badge": {
                                position: "static",
                                backgroundColor: `${theme.colors.radicalRed}`,
                                height: "0.5rem",
                                minWidth: "0.5rem",
                                margin: "0.400rem 0.875rem 0 0",
                              },
                            }}
                          />
                        )}
                        <div
                          onClick={() => navigate(routeConstant.notification)}
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <ArrowRightIcon />
                        </div>
                      </ItemRow>
                      {notification.type === renderNotificationButtonText && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.625rem",
                          }}
                        >
                          <CustomButton
                            sx={{
                              padding: "0.375rem 0.938rem",
                              border: `1px solid ${theme.colors.border1}`,
                              background: `${theme.colors.white}`,
                              color: `${theme.colors.gray1}`,
                              borderRadius: "3.125rem",
                            }}
                            disabled={rejectInvitationLoader}
                            startIcon={
                              rejectInvitationLoader && (
                                <CircularProgress size={22} color="inherit" />
                              )
                            }
                            onClick={() =>
                              handleJoin(
                                notification._id,
                                notification.teamId,
                                false
                              )
                            }
                          >
                            Decline
                          </CustomButton>
                          <CustomButton
                            style={{
                              padding: "0.375rem 0.938rem",
                              border: `1px solid ${theme.colors.border1}`,
                              background: `${theme.colors.backgroundPrimary}`,
                              color: `${theme.colors.white}`,
                              borderRadius: "3.125rem",
                            }}
                            disabled={acceptInvitationLoader}
                            startIcon={
                              acceptInvitationLoader && (
                                <CircularProgress size={22} color="inherit" />
                              )
                            }
                            onClick={() =>
                              handleJoin(
                                notification._id,
                                notification.teamId,
                                true
                              )
                            }
                          >
                            Accept
                          </CustomButton>
                        </div>
                      )}
                    </>
                  );
                }
              )}
              <Divider sx={{ marginInline: "-0.938rem" }} />
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  padding: "0.563rem 0.25rem 0.563rem 0.063rem",
                  justifyContent: "center",
                  gap: "0.625rem",
                  cursor: "pointer",
                }}
                onClick={() => navigate(routeConstant.notification)}
              >
                <Typography>View All</Typography>
                <ArrowRightIcon />
              </Stack>
            </>
          ) : (
            <Stack justifyContent={"center"} alignItems={"center"}>
              <div
                style={{
                  margin: "0rem 1.634rem 1.368rem 1.125rem",
                }}
              >
                <NoNotificationIcon />
              </div>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "1.25rem",
                  lineHeight: "1.524rem",
                  textAlign: "center",
                }}
              >
                No Notification
              </Typography>
            </Stack>
          )}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </Menu>
  );
};

export default NotificationPopover;
