import { call, put, select } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import { StarredReducerAction } from "./slice";
import { starredListParamsSelector } from "./selector";
import { starredListsParamsType } from "./types";
import { AxiosResponse } from "axios";
import { getStarredAdList } from "../../services/AdServices";
import * as Sentry from "@sentry/react";

const saga = createSliceSaga({
  name: "starred-state",
  caseSagas: {
    *fetchStarredAds() {
      try {
        yield put(StarredReducerAction.setLoadingStarredList(true));
        const data: starredListsParamsType = yield select(
          starredListParamsSelector
        );

        const response: AxiosResponse = yield call(getStarredAdList, data);
        if (response?.status === 200) {
          yield put(StarredReducerAction.setStarredList(response?.data));
          yield put(
            StarredReducerAction.setStarredListParams(data.skip + data.limit)
          );
          yield put(StarredReducerAction.setLoadingStarredList(false));
        }
      } catch (error) {
        yield put(StarredReducerAction.setLoadingStarredList(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: StarredSagaActions,
  saga: StarredSaga,
  name: StarredSagaName,
} = saga;
