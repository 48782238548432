import Header from '../../components/header'
import { Container, HeaderSection, ListContainer } from '../style'
import CreateBoardModal from '../../components/modals/createBoardModal'
import PaymentHistory from '../../components/paymentHistory'

const PaymentHistoryPage = () => {

  const content = {
    tabTitle: "Payments",
    tabSubTitle: `Payment History`,
  };
  return (
    <Container>
      <HeaderSection>
        <Header content={content} hideCreateButton={true} />
        <ListContainer>
          <PaymentHistory />
        </ListContainer>
      </HeaderSection>
      <CreateBoardModal />
    </Container>
  )
}

export default PaymentHistoryPage