import {
  BrandFolderCardContainer,
  FavouriteButton,
  FolderCardText,
  FolderImageWrapper,
} from "./style";
import { FavouriteIcon, HeartIcon } from "../../../assets/Icons";
import FolderImage from "../../../assets/images/folder-image.svg";
import { useEffect, useState } from "react";
import { folderItem } from "../../../store/metadata/types";
import { useDispatch } from "react-redux";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { MetaDataReducerAction } from "../../../store/metadata/slice";

const BrandFolderCard = ({
  item,
  cardWidth,
  ableToFavourite,
}: {
  item?: folderItem;
  cardWidth: string;
  ableToFavourite: boolean;
}) => {
  const [favourite, setFavourite] = useState(false);

  const dispatch = useDispatch();
  const handleFavourite = () => {
    dispatch(MetaDataSagaActions.updateFavouriteFolder(item?._id));
    setFavourite(!favourite);
  };

  useEffect(() => {
    if (item) {
      setFavourite(item?.favourite);
    }
  }, [item?.favourite]);

  const handleOpenViewFolderModal = () => {
    dispatch(MetaDataReducerAction.setFolderItem(item));
    dispatch(MetaDataReducerAction.setOpenViewFolderModal(true));
  };

  return (
    <BrandFolderCardContainer
      height={"fit-content"}
    >
      <FavouriteButton
        endIcon={favourite ? <FavouriteIcon /> : <HeartIcon />}
        sx={{ placeSelf: "end", marginBottom: "-6px" }}
        disabled={ableToFavourite}
        onClick={handleFavourite}
      />
      <FolderImageWrapper
        src={FolderImage}
        alt=""
        onClick={handleOpenViewFolderModal}
      />
      <FolderCardText onClick={handleOpenViewFolderModal}>
        {item?.name}
      </FolderCardText>
    </BrandFolderCardContainer>
  );
};

export default BrandFolderCard;
