import {Box, Grid, Skeleton, Stack} from "@mui/material";

const AdSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Grid container sx={{
          alignItems: "center",
          margin: "1.143rem", width:"19.5rem"}}>
          <Grid item xs={2}>
            <Skeleton variant="circular" sx={{ width: "2.571rem", height: "2.571rem", borderRadius: "50%" }} animation="wave" />
          </Grid>
          <Grid item xs={10}>
            <Stack sx={{ width: "82%"}}>
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "100%" }}
            animation="wave"
          />
          <Skeleton
            variant="text"
            sx={{ fontSize: "1rem", width: "50%" }}
            animation="wave"
          />
        </Stack>
          </Grid>
      </Grid>
      <Skeleton
        variant="rectangular"
        sx={{ width: "100%" }}
        height={350}
        animation="wave"
      />
    </Box>
  );
};

export default AdSkeleton;
