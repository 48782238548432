import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  FilterOptions,
  SetFilterOptionsPayload,
  SwipeFileListItems,
  SwipeFileStateTypes,
  ViewTypeEnum,
} from "./types";
import { listType } from "../metadata/types";

export const initialState: SwipeFileStateTypes = {
  selectedTableItems: [],
  viewType: ViewTypeEnum.Card,
  swipeFileListItems: {
    data: [],
    total: 0,
  },
  isLoading: false,
  swipeFileTabIndex: 0,
  swipeFileTopBrandLists: {
    top10: [],
    data: [],
    total: 0,
  },
  swipeFileBrandsParams: {
    skip: 0,
    limit: 12,
    favourite: false,
  },
  filterOptions: {
    selectedPlatformIds: [],
    selectedPageIds: [],
    selectedBoardIds: [],
    userId: "",
    limit: 30,
    skip: 0,
    sortValue: "Newest",
    sort: {
      createdAt: -1,
    },
    selectedTagIds: [],
  },
  search: "",
  swipeFilesFavouriteBrandFilterEnabled: false,
  swipeFileBrandsLoading: false,
  selectedSidebarBoardId: "",
  openDeleteAdsModal: false,
  selectedDeleteAdId: "",
  deleteAdLoading: false,
  openAddBoardModal: false,
  openAddBoardModalLoading: false,
  openAddTagModal: false,
  openAddTagModalLoading: false,
};

const swipeFile = createSlice({
  name: "swipeFileState",
  initialState,
  reducers: {
    setSelectedTableItems(state, action) {
      if (action.payload.checked) {
        action.payload.selectAll
          ? (state.selectedTableItems = state.swipeFileListItems?.data?.map(
              (n) => n._id
            ))
          : (state.selectedTableItems = [
              ...state.selectedTableItems,
              action.payload.id,
            ]);
      } else {
        action.payload.selectAll
          ? (state.selectedTableItems = [])
          : (state.selectedTableItems = state.selectedTableItems.filter(
              (item) => item !== action.payload.id
            ));
      }
    },
    setViewType(state, action) {
      state.viewType = action.payload;
    },
    setSelectedSortOption(state, action) {
      state.filterOptions.sortValue = action.payload.sortValue;
      state.filterOptions.sort = action.payload.sortObject;
    },
    setSwipeFileItems(state, action) {
      state.swipeFileListItems.total = action.payload.total
        ? action.payload.total
        : 0;
      state.swipeFileListItems.data = [
        ...state.swipeFileListItems.data,
        ...action.payload.data,
      ];
    },
    setSwipeFileItemsData(state, action) {
      state.swipeFileListItems.data = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSwipeFileTabIndex(state, action) {
      state.swipeFileTabIndex = action.payload;
    },
    setSwipeFileTopBrands(state, action) {
      const { brandData, skipValue } = action.payload;
      state.swipeFileTopBrandLists.top10 = brandData?.top10 ?? [];
      state.swipeFileTopBrandLists.total = brandData?.total;
      state.swipeFileTopBrandLists.data =
        skipValue === 0
          ? brandData.data
          : [...state.swipeFileTopBrandLists.data, ...brandData.data];
    },
    setSwipeFileBrandsParams(state, action) {
      state.swipeFileBrandsParams = {
        ...state.swipeFileBrandsParams,
        skip: action.payload,
      };
    },
    setSelectedFilterOptions<T extends keyof FilterOptions>(
      state: SwipeFileStateTypes,
      action: PayloadAction<SetFilterOptionsPayload<T>>
    ) {
      const { selectAll, checked, id, list, stateName } = action.payload;
      const { filterOptions } = state;

      const selectedFilters = filterOptions[stateName] as string[];

      if (selectAll) {
        if (checked) {
          const mergedArray = [
            ...selectedFilters,
            ...list.map((item: listType) => item._id),
          ];
          const uniqueArray = mergedArray.filter(
            (value, index) => mergedArray.indexOf(value) === index
          );
          (filterOptions[stateName] as string[]) = uniqueArray;
        } else {
          (filterOptions[stateName] as string[]) = [];
        }
      } else {
        const index = (filterOptions[stateName] as string[]).indexOf(id);
        if (checked && index === -1) {
          (filterOptions[stateName] as string[]).push(id);
        } else if (!checked && index !== -1) {
          (filterOptions[stateName] as string[]).splice(index, 1);
        }
      }
    },
    setLoggingUserIdInFilterOption(state, action) {
      state.filterOptions.userId = action.payload;
    },
    setSelectedBoardId(state, action) {
      state.filterOptions.selectedBoardIds = [action.payload];
    },
    setFilterOptionSkipValue(state, action) {
      state.filterOptions = {
        ...state.filterOptions,
        skip: action.payload,
      };
    },
    setSwipeFileItemToInitialSet(state, action) {
      state.swipeFileListItems.data = [];
      state.swipeFileListItems.total = 0;
    },
    setSwipeFilePageSearchInputValue(state, action) {
      state.search = action.payload;
    },
    setSwipeFileTopBrandsToInitialState(state, action) {
      state.swipeFileTopBrandLists.top10 = [];
      state.swipeFileTopBrandLists.total = 0;
      state.swipeFileTopBrandLists.data = [];
    },
    setSwipeFileFavouriteBrandFilter(state, action) {
      state.swipeFilesFavouriteBrandFilterEnabled = action.payload;
    },
    setFilterOptionBrandId(state, action) {
      state.filterOptions?.selectedPageIds?.push(action.payload);
    },
    resetSwipefileState(state) {
      //Swipe file ads listing reset state
      state.swipeFileListItems.data = [];
      state.swipeFileListItems.total = 0;
      state.filterOptions.selectedPlatformIds = [];
      state.filterOptions.selectedPageIds = [];
      state.filterOptions.selectedBoardIds = [];
      state.filterOptions.limit = 30;
      state.filterOptions.skip = 0;
      state.filterOptions.sortValue = "Newest";
      state.filterOptions.sort = {
        createdAt: -1,
      };
      state.filterOptions.selectedTagIds = [];
      state.selectedSidebarBoardId = "";
      state.swipeFilesFavouriteBrandFilterEnabled = false;
      state.swipeFileTabIndex = 0;
    },
    setSwipeFileBrandsLoading(state, action) {
      state.swipeFileBrandsLoading = action.payload;
    },
    resetFilters(state) {
      state.filterOptions = initialState.filterOptions;
      state.selectedSidebarBoardId = "";
    },
    setSelectedSidebarBoardId(state, action) {
      state.selectedSidebarBoardId = action.payload;
    },
    setUpdateSwipeFileItem(state, action) {
      const index = state.swipeFileListItems.data.findIndex(
        (item) => item._id === action.payload
      );
      if (index !== -1) {
        const updatedItems = state.swipeFileListItems.data.filter(
          (item) => item._id !== action.payload
        );

        state.swipeFileListItems.data = updatedItems;
        state.swipeFileListItems.total = state.swipeFileListItems.total - 1;
      }
    },
    setNewAssignTagToAdItem(state, action) {
      const { addTagInAdItem, adId } = action.payload;
      state.swipeFileListItems.data.forEach((item) => {
        if (item._id === adId) {
          return {
            ...item,
            Tags: item.Tags.push(addTagInAdItem),
          };
        }
      });
    },
    setFolderItemUpdatedRating(state, action) {
      const { adId, ratings } = action.payload;
      state.swipeFileListItems.data = state.swipeFileListItems.data.map(
        (item: SwipeFileListItems) => {
          if (item._id === adId) {
            return {
              ...item,
              ratings: ratings,
            };
          } else {
            return item;
          }
        }
      );
    },
    setUpdatedBoardIds(state, action) {
      const updatedSwipeFileItemData = state.swipeFileListItems.data.map(
        (item: SwipeFileListItems) => {
          if (item._id === action.payload.adId) {
            return {
              ...item,
              board: action.payload.updatedBoardList,
            };
          } else {
            return item;
          }
        }
      );
      state.swipeFileListItems.data = updatedSwipeFileItemData;
    },
    setDeleteAdsModal(state, action) {
      state.openDeleteAdsModal = action.payload;
    },
    setSelectedDeleteAdId(state, action) {
      state.selectedDeleteAdId = action.payload;
    },
    setDeleteAdLoading(state, action) {
      state.deleteAdLoading = action.payload;
    },
    setSelectedTagIds(state, action) {
      state.filterOptions.selectedTagIds = action.payload;
    },
    setOpenAddBoardModal(state, action) {
      state.openAddBoardModal = action.payload;
    },
    setOpenAddBoardModalLoading(state, action) {
      state.openAddBoardModalLoading = action.payload;
    },
    setUpdatedBoardsToMultipleAds(state, action) {
      const updatedSwipeFileItems = state.swipeFileListItems.data.map(
        (item: SwipeFileListItems) => {
          if (action.payload.adIds.includes(item._id)) {
            const updatedBoard = [
              ...item.board,
              ...action.payload.boardIds.filter(
                (boardId: string) =>
                  !item.board.some(
                    (existingBoard) => existingBoard._id === boardId
                  )
              ),
            ];
            return {
              ...item,
              board: updatedBoard,
            };
          } else {
            return item;
          }
        }
      );
      state.swipeFileListItems.data = updatedSwipeFileItems;
    },
    setSelectedTableItemsInitialState(state, action) {
      state.selectedTableItems = action.payload;
    },
    setManualAd(state, action) {
      state.swipeFileListItems.data.push(action.payload);
      state.swipeFileListItems.total = state.swipeFileListItems.total + 1;
    },
    setOpenAddTagModal(state, action) {
      state.openAddTagModal = action.payload;
    },
    setOpenAddTagModalLoading(state, action) {
      state.openAddTagModalLoading = action.payload;
    },
    setUpdatedTagsToMultipleAds(state, action) {
      const updatedSwipeFileItems = state.swipeFileListItems.data.map(
        (item: SwipeFileListItems) => {
          if (action.payload.adIds.includes(item._id)) {
            const updatedTag = [
              ...item.Tags,
              ...action.payload.tagIds.filter(
                (tagId: string) =>
                  !item.Tags.some((existingTag) => existingTag._id === tagId)
              ),
            ];
            return {
              ...item,
              Tags: updatedTag,
            };
          } else {
            return item;
          }
        }
      );
      state.swipeFileListItems.data = updatedSwipeFileItems;
    },
    setUpdatedTagIds(state, action) {
      const updateSwipeFileData = state.swipeFileListItems.data.map(
        (item: SwipeFileListItems) => {
          if (item._id === action.payload.adId) {
            return {
              ...item,
              Tags: action.payload.updatedTagList,
            };
          } else {
            return item;
          }
        }
      );
      state.swipeFileListItems.data = updateSwipeFileData;
    },
  },
});

export const {
  actions: SwipeFileReducerAction,
  name: SwipeFileReducerName,
  reducer: SwipeFileReducer,
} = swipeFile;
