import { Box, Button, Typography } from "@mui/material";
import styled from "styled-components";

export const CardContainer = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 10px;
  padding: 1.25rem;
  background: white;
`;

export const TopBrandCardContainer = styled.div`
  min-width: 11.25rem;
  margin-inline: 1rem;
`;

export const StyledNumberText = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 4.25rem;
    line-height: 3.048rem;
    margin: 0.5rem 0 1.438rem 0;
    -webkit-text-stroke: 1.5px ${({ theme }) => theme.colors.lightBlue};
    color: transparent;
  }
`;

export const BrandName = styled(Typography)`
  && {
    width: 100%;
    height: 1.375rem;
    font-size: 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.black2};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.371rem;
    font-weight: 600;
    cursor: pointer;
  }
`;

export const BrandGenre = styled(Typography)`
  && {
    font-weight: 400;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray1};
  }
`;

export const SavedCount = styled(Typography)`
  && {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.black2};
    display: flex;
    gap: 0.188rem;
    align-items: center;
  }
`;

export const LibraryButton = styled(Button)`
  && {
    text-transform: capitalize;
    border: 1px solid ${({ theme }) => theme.colors.border1};
    border-radius: 50px;
    padding: 0.5rem 1.25rem;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 1.219rem;
    color: ${({ theme }) => theme.colors.black2};
    gap: 0.188rem;

    &:hover {
      background: ${({ theme }) => theme.colors.backgroundPrimary};
      color: white;
    }
  }
`;

export const BrandImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.937rem;
  width: 11.125rem;
  height: 12.25rem;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 0.625rem;
  background-color: white;
`;

export const FavouriteBrandImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.937rem;
  width: 7rem;
  border: 1px solid ${({ theme }) => theme.colors.border1};
  border-radius: 0.625rem;
  background-color: white;
`;

export const FavouriteButton = styled(Button)`
  && {
    background: none !important;
    padding: 0;
    min-width: fit-content;
    align-self: end;
  }
`;

export const BrandCardContainer = styled(CardContainer)`
  // padding-top: 0;
`;

export const BrandFolderCardContainer = styled(CardContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 110px;

  @media (max-width: 320px) {
    width: min-content;
  }
`;

export const FolderImageWrapper = styled.img`
  width: 4.625rem;
  height: 4.625rem;
  margin-bottom: 1.25rem;
  cursor: pointer;
`;

export const ViewAllCardContainer = styled(CardContainer)`
  width: 9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25rem;
  justify-content: center;
`;

export const FolderCardText = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.black2};
    text-align: center;
    cursor: pointer;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const BrandDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const BrandLogoWrapper = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  margin: 0 0 0.937rem 0;
  overflow: hidden;
  cursor: pointer;
  border-radius: 0.5rem;
`;

export const BrandCountWrapper = styled.div`
  height: 1.375rem;
  display: flex;
  align-items: center;
`;

export const BrandLinkWrapper = styled.div`
  width: 10.5rem;
  margin-top: 1.25rem;
  display: block;
`;
