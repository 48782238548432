import SelectDropdown from "../../selectDropdown";
import { TagsIcon } from "../../../assets/Icons";
import {
  Box,
  Chip,
  CircularProgress,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { StyledChip, StyledMenuItem } from "../../../globalStyle";
import FilterSearchbox from "../filterSearchbox";
import { TagsDropdownProps } from "../../../utils/propTypes";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TagsListsSelector,
  isLoadingSelector,
} from "../../../store/metadata/selector";
import { tagItem } from "../../../store/metadata/types";
import { ButtonText, HeaderButton2 } from "../../../pageComponents/style";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { TagsReducerAction } from "../../../store/tags/slice";
import { openTagsDropdownSelector } from "../../../store/tags/selector";
import { useTheme } from "styled-components";

const TagsDropdown = ({
  showSelectedItemCount,
  selectedTags,
  selectedTagIds = [],
  handleUpdateTag,
}: TagsDropdownProps) => {
  const theme = useTheme();
  const [tags, setTags] = useState<string[]>([]);
  const [tagSearchText, setTagSearchText] = useState("");
  const dispatch = useDispatch();

  const tagsList = useSelector(TagsListsSelector);
  const openTagsDropdown = useSelector(openTagsDropdownSelector);
  const loading = useSelector(isLoadingSelector);

  const handleTagSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTagSearchText(event.target.value);
    if (event.target.value === "") {
      dispatch(
        MetaDataSagaActions.fetchTagLists({ searchValue: event.target.value })
      );
    }
  };

  const handleTagSearch = () => {
    dispatch(MetaDataSagaActions.fetchTagLists({ searchValue: tagSearchText }));
  };

  const handleTagSearchKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (
    event
  ) => {
    event.stopPropagation();
    if (event.key === "Enter") handleTagSearch();
  };

  const handleDropDownClose = () => {
    if (tagSearchText) {
      dispatch(MetaDataSagaActions.fetchTagLists({ searchValue: "" }));
      setTagSearchText("");
    }
    dispatch(TagsReducerAction.setOpenTagsDropdown(""));
  };

  useEffect(() => {
    selectedTags?.length > 0 && setTags(selectedTags?.map((tag) => tag._id));
  }, [selectedTags]);

  return (
    <SelectDropdown
      openDropdown={openTagsDropdown}
      startAdornmentIcon={<TagsIcon />}
      multiple={true}
      renderValue={(selected: string[]) => {
        return (
          <Stack
            direction="row"
            justifyContent={
              showSelectedItemCount === 1 ? "space-between" : "start"
            }
          >
            {tags.slice(0, showSelectedItemCount).map((item: string) => {
              let findTag;
              findTag = selectedTags?.find((ele: tagItem) => ele._id === item);
              if (!findTag) {
                findTag = tagsList?.find((ele: tagItem) => ele._id === item);
              }
              return findTag && <Chip key={findTag._id} label={findTag.name} />;
            })}
            {tags.length > showSelectedItemCount && (
              <StyledChip label={`+${tags.length - showSelectedItemCount}`} />
            )}
          </Stack>
        );
      }}
      handleChange={(event: SelectChangeEvent) => {
        const selectedValue = event.target.value;
        if (typeof selectedValue === "string") {
          setTags(selectedValue.split(","));
        } else {
          const parsedValues = (selectedValue as string[])?.filter(
            (item) => item !== undefined
          );
          setTags(parsedValues);
        }
      }}
      value={tags}
      handleClose={handleDropDownClose}
      height="20rem"
    >
      <Box
        sx={{
          margin: "0.938rem",
          position: "sticky",
          top: 0,
          left: 0,
          background: "white",
          borderBottom: `1px solid ${theme.colors.border1}`,
          zIndex: 1,
        }}
      >
        <FilterSearchbox
          placeholder="Search"
          handleChange={handleTagSearchOnChange}
          handleKeyDown={handleTagSearchKeyDown}
          handleSearchIconClick={handleTagSearch}
        />
      </Box>
      {tagsList?.map((tag: tagItem, index: number) => (
        <StyledMenuItem key={index} value={tag._id}>
          {tag.name}
        </StyledMenuItem>
      ))}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          background: "white",
          zIndex: 1,
          padding: "0.625rem",
          borderTop: `1px solid ${theme.colors.border1}`,
          textAlign: "right",
        }}
      >
        <HeaderButton2
          variant="contained"
          size="small"
          disabled={tags?.length > 0 ? false : true}
          onClick={() =>
            handleUpdateTag(
              tags,
              selectedTagIds.filter((id) => !tags.includes(id))
            )
          }
          startIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          <ButtonText>Update</ButtonText>
        </HeaderButton2>
      </Box>
    </SelectDropdown>
  );
};

export default TagsDropdown;
