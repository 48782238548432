import { createSlice } from "@reduxjs/toolkit";
import { TagsStateTypes } from "./types";

export const initialState: TagsStateTypes = {
  tagItems: [],
  newTagValue: "",
  selectedTagItems: [],
  loading: false,
  openTagsDropdown: "",
};

const tags = createSlice({
  name: "tagsState",
  initialState,
  reducers: {
    setTagItems(state) {
      state.tagItems = [...state.tagItems, { name: state.newTagValue }];
      state.selectedTagItems = [...state.selectedTagItems, state.newTagValue];
      state.newTagValue = "";
    },
    setNewTagValue(state, action) {
      state.newTagValue = action.payload;
    },
    setSelectedTagItems(state, action) {
      state.selectedTagItems = state.selectedTagItems.includes(action.payload)
        ? state.selectedTagItems.filter((tag) => tag !== action.payload)
        : [...state.selectedTagItems, action.payload];
    },
    setTagsLoader(state, action) {
      state.loading = action.payload;
    },
    setOpenTagsDropdown(state, action) {
      state.openTagsDropdown = action.payload;
    },
  },
});

export const {
  actions: TagsReducerAction,
  name: TagsReducerName,
  reducer: TagsReducer,
} = tags;
