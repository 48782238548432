import {
  TableToolbarSection,
  StyledToolbar,
  ToolbarButton,
  StyledText,
} from "./style";
import { BoardIcon, CloseIcon, DeleteIcon, TagsIcon } from "../../assets/Icons";
import { Divider } from "@mui/material";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectedTableItemsSelector } from "../../store/swipeFile/selector";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";

const VerticalDivider = () => (
  <Divider orientation="vertical" variant="middle" flexItem sx={{ m: 0 }} />
);

const TableToolbar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedTableItems = useSelector(selectedTableItemsSelector);

  const handleClose = () => {
    dispatch(
      SwipeFileReducerAction.setSelectedTableItems({
        checked: false,
        selectAll: true,
      })
    );
  };

  const openDeleteModal = () => {
    dispatch(SwipeFileReducerAction.setDeleteAdsModal(true));
  };

  const openBoardAddModal = () => {
    dispatch(SwipeFileReducerAction.setOpenAddBoardModal(true));
  };

  const openTagAddModal = () => {
    dispatch(SwipeFileReducerAction.setOpenAddTagModal(true));
  };

  return (
    <>
      <TableToolbarSection>
        <StyledToolbar>
          <ToolbarButton startIcon={<CloseIcon />} onClick={handleClose}>
            <StyledText textColor={theme.colors.black2}>
              {selectedTableItems.length} selected
            </StyledText>
          </ToolbarButton>
          <VerticalDivider />
          <ToolbarButton
            startIcon={<BoardIcon fill={theme.colors.gray1} />}
            onClick={openBoardAddModal}
          >
            <StyledText textColor={theme.colors.black2}>Add Boards</StyledText>
          </ToolbarButton>
          <VerticalDivider />
          <ToolbarButton startIcon={<TagsIcon />} onClick={openTagAddModal}>
            <StyledText textColor={theme.colors.black2}>Add Tags</StyledText>
          </ToolbarButton>
          <VerticalDivider />
          <ToolbarButton startIcon={<DeleteIcon />} onClick={openDeleteModal}>
            <StyledText textColor={theme.colors.brandRed}>Delete</StyledText>
          </ToolbarButton>
        </StyledToolbar>
      </TableToolbarSection>
    </>
  );
};

export default TableToolbar;
