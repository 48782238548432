import Slider, { CustomArrowProps } from "react-slick";
import { brandItem } from "../../../store/metadata/types";
import TopBrandCard from "./topBrandCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  BrandCountText,
  TopBrandsListWrapper,
} from "../../../pageComponents/style";
import { Grid } from "@mui/material";

const NavigateButtonStyle = {
  fill: "#58595B",
  background: "white",
  border: "1px solid #EAECEE",
  borderRadius: "50%",
  padding: "0.25rem",
  margin: "0.25rem",
};

const NextArrow = ({ className, onClick }: CustomArrowProps) => {
  return (
    <NavigateNextIcon
      onClick={onClick}
      className={className}
      sx={NavigateButtonStyle}
    />
  );
};

const PrevArrow = ({ className, onClick }: CustomArrowProps) => {
  return (
    <NavigateBeforeIcon
      onClick={onClick}
      className={className}
      sx={NavigateButtonStyle}
    />
  );
};

const TopBrandCarousel = ({
  brandsTitle,
  topBrandLists,
  handleViewAd,
}: {
  brandsTitle: string;
  topBrandLists: brandItem[];
  handleViewAd: Function;
}) => {
  const slidesToShow = Math.min(topBrandLists?.length, 6);
  const settings = {
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: Math.min(topBrandLists?.length, 5),
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: Math.min(topBrandLists?.length, 4),
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: Math.min(topBrandLists?.length, 3),
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(topBrandLists?.length, 2),
        },
      },
    ],
  };
  if (topBrandLists.length === 0) {
    return <></>;
  }
  return (
    <>
      <BrandCountText>{brandsTitle}</BrandCountText>
      <TopBrandsListWrapper>
        {topBrandLists.length > 5 ? (
          <Slider {...settings}>
            {topBrandLists?.map((item: brandItem, index: number) => (
              <TopBrandCard
                buttonsToShow={["facebookButton"]}
                key={index}
                index={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`}
                item={item}
                handleViewAd={handleViewAd}
                showNumber={true}
              />
            ))}
          </Slider>
        ) : (
          <Grid container sx={{ paddingRight: "1rem" }}>
            {topBrandLists
              .slice(0, 6)
              .map((brand: brandItem, index: number) => (
                <Grid item xs={12 / 4} lg={12 / 5} xl={12 / 6} key={index}>
                  <TopBrandCard
                    buttonsToShow={["facebookButton"]}
                    index={index < 9 ? `0${index + 1}` : `${index + 1}`}
                    item={brand}
                    handleViewAd={handleViewAd}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </TopBrandsListWrapper>
    </>
  );
};

export default TopBrandCarousel;
