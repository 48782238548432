import { brandItem, listType, tagItem } from "../metadata/types";

export type SwipeFileStateTypes = {
  viewType: ViewTypeEnum;
  selectedTableItems: string[];
  swipeFileListItems: swipeFileItemListsType;
  isLoading: boolean;
  swipeFileTabIndex: number;
  swipeFileTopBrandLists: swipeFileTopBrandListsType;
  filterOptions: FilterOptions;
  swipeFileBrandsParams: swipeFileBrandsParamsType;
  search: string;
  swipeFilesFavouriteBrandFilterEnabled: boolean;
  swipeFileBrandsLoading: boolean;
  selectedSidebarBoardId: string;
  openDeleteAdsModal: boolean;
  selectedDeleteAdId: string;
  deleteAdLoading: boolean
  openAddBoardModal: boolean
  openAddBoardModalLoading: boolean,
  openAddTagModal: boolean,
  openAddTagModalLoading: boolean,
};

export type SwipeFileListItems = {
  _id: string;
  description?: string;
  libraryId: string;
  startedRunningDate: string;
  platforms: [];
  imageUrls: string[];
  videoUrls: string[];
  adTargetSourceURL: string;
  pageId?: string;
  createdAt: string;
  updatedAt: string;
  page: PageType;
  isActive: boolean;
  board: boardType[];
  Tags: tagItem[];
  ratings: { _id: string; rating: number }[];
  transcript: transcriptObject;
};

export type PageType = {
  _id: string;
  name: string;
  logo: string;
  url: string;
  createdAt: string;
  updatedAt: string;
};

export enum ViewTypeEnum {
  List,
  Card,
}

export type handleSelectItemType = {
  event: React.ChangeEvent<HTMLInputElement>;
  selectAll?: boolean;
  id?: number;
};

export type boardType = {
  _id: string;
};

export type swipeFileTopBrandListsType = {
  top10: brandItem[];
  data: brandItem[];
  total: number;
};

export type swipeFileBrandsParamsType = {
  skip: number;
  limit: number;
  favourite: boolean;
};

export type FilterOptions = {
  selectedPlatformIds: string[];
  selectedPageIds: string[];
  selectedBoardIds: string[];
  userId: string;
  limit: number;
  skip: number;
  sortValue: string;
  sort: Record<string, any>;
  selectedTagIds: string[]
};

export type swipeFileItemListsType = {
  data: SwipeFileListItems[];
  total: number;
};

export interface SetFilterOptionsPayload<T extends keyof FilterOptions> {
  selectAll: boolean;
  checked: boolean;
  id: string;
  list: listType[];
  stateName: T;
}

export type transcriptObject = {
  results: {
    channels: [
      {
        alternatives: alternativesObject[];
      }
    ];
    utterances: utterancesObject[];
  };
};

export type utterancesObject = {
  start: number;
  end: number;
  confidence: number;
  transcript: string;
  id: string;
};

export type alternativesObject = {
  transcript: string;
};

