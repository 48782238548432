import {
  CardContainer,
  DashboardCardButton,
  GrayBgHeader,
  GrayBgHeaderText,
} from "./style";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../globalStyle";
import {
  ArrowRightIcon,
  FavouriteAdseBlackIcon,
  HelpBlackIcon,
  NoFavoriteBrandsFoundIcon,
  NoSwipeAdsTeamsCollectionFoundIcon,
  SideArrow,
} from "../../assets/Icons";
import BrandFolderCard from "../commonComponents/brandCards/brandFolderCard";
import { useSelector } from "react-redux";
import {
  favouriteBrandsSelector,
  folderListsSelector,
} from "../../store/metadata/selector";
import { brandItem, folderItem } from "../../store/metadata/types";
import { useState } from "react";
import {
  BrandCountWrapper,
  BrandDetailWrapper,
  BrandLogoWrapper,
  BrandName,
  FavouriteBrandImageWrapper,
  TopBrandCardContainer,
} from "../commonComponents/brandCards/style";
import { formatAdCount } from "../../utils/helpers";
import FacebookLogo from "../../assets/images/facebookLogo.png";
import LinkedInLogo from "../../assets/images/linkedInLogo.png";
import { Link } from "react-router-dom";

const SwipeAdSection = () => {
  const theme = useTheme();
  const [viewAllCollectionClick, setViewAllCollectionClick] = useState(false);

  const folderList = useSelector(folderListsSelector);
  const limitedItems = viewAllCollectionClick
    ? folderList?.teamFolders
    : folderList?.teamFolders?.slice(0, 12);

  const favouriteBrandsList = useSelector(favouriteBrandsSelector);

  return (
    <CardContainer sx={{ padding: "0" }}>
      <Stack direction="row" margin="0" flexWrap={"wrap"}>
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          borderRight={`1px solid ${theme.colors.border1}`}
        >
          <Box flex={1} >
            <GrayBgHeader>
              <GrayBgHeaderText>Favourite Brands</GrayBgHeaderText>
            </GrayBgHeader>

            <Grid
              container
              spacing={"1rem"}
              padding="0 0px 0rem 3rem"
              sx={{
                marginTop: "2rem",
                marginBottom: "2rem",
                overflowY: "scroll",
                flexGrow: 1, // Makes the grid section grow to fill available space
              }}
              display="flex"
              gap="20px"
              width="100%"
            >
              {favouriteBrandsList?.length > 0 ? (
                favouriteBrandsList?.map((item: brandItem) => (
                  <TopBrandCardContainer
                    style={{ marginInline: "0px", minWidth: "auto" }}
                  >
                    <FavouriteBrandImageWrapper>
                      <BrandDetailWrapper style={{ margin: "10px 0" }}>
                        <BrandLogoWrapper>
                          <img
                            src={item?.logo}
                            alt="brand"
                            style={{
                              maxWidth: "100%",
                              minHeight: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </BrandLogoWrapper>
                        <BrandName fontWeight={600} fontSize={"16px"}>
                          {item?.name}
                        </BrandName>
                        <BrandCountWrapper style={{ marginTop: "5px" }}>
                          <span
                            style={{
                              height: "15px",
                              width: "15px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "4px",
                              background: "black",
                              marginRight: "8px",
                            }}
                          >
                            <FavouriteAdseBlackIcon />
                          </span>
                          <p
                            style={{
                              fontWeight: "500",
                              fontSize: "14px",
                              lineHeight: "1.371rem",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            {formatAdCount(item?.adsSaved ?? 0)} Saved
                          </p>
                        </BrandCountWrapper>
                      </BrandDetailWrapper>
                    </FavouriteBrandImageWrapper>
                  </TopBrandCardContainer>
                ))
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    alignItems="center"
                  >
                    <NoFavoriteBrandsFoundIcon />
                    <Box fontSize="16px" fontWeight={500}>
                      Add Brands to Favourite and spy on them
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Box>
          <Box>
            <GrayBgHeader>
              <GrayBgHeaderText
                sx={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                Saving Extension Status <HelpBlackIcon />
              </GrayBgHeaderText>
            </GrayBgHeader>

            <Box
              display="flex"
              gap="20px"
              padding="20px"
              marginTop="2rem"
              marginBottom="2rem"
            >
              <Box
                borderRadius="10px"
                border={`1px solid ${theme.colors.border1}`}
                width="50%"
              >
                <Box display="flex" gap="15px" padding="15px" paddingBottom={0}>
                  <Box>
                    <img
                      src={FacebookLogo}
                      alt="pp"
                      width="48px"
                      height="48px"
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                      Facebook Ad Library
                    </Typography>
                    <Box>
                      <Chip
                        label="Working"
                        sx={{
                          height: "23px",
                          borderRadius: "20px",
                          background: "#EDFCF5",
                          border: "1px solid #D4F7E6",
                          color: "#1EAF83",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="end">
                  <Link
                    target="_blank"
                    to="https://www.facebook.com/ads/library"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "34px",
                      height: "34px",
                      borderRadius: "10px 0 9px 0",
                      background: theme.colors.border3,
                    }}
                  >
                    <SideArrow />
                  </Link>
                </Box>
              </Box>
              <Box
                borderRadius="10px"
                border={`1px solid ${theme.colors.border1}`}
                width="50%"
              >
                <Box display="flex" gap="15px" padding="15px" paddingBottom={0}>
                  <Box>
                    <img
                      src={LinkedInLogo}
                      alt="pp"
                      width="48px"
                      height="48px"
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" gap="10px">
                    <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                      LinkedIn Ads
                    </Typography>
                    <Box>
                      <Chip
                        label="Working"
                        sx={{
                          height: "23px",
                          borderRadius: "20px",
                          background: "#EDFCF5",
                          border: "1px solid #D4F7E6",
                          color: "#1EAF83",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box width="100%" display="flex" justifyContent="end">
                  <Link
                    target="_blank"
                    to="https://www.linkedin.com/ad-library/"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "34px",
                      height: "34px",
                      borderRadius: "10px 0 9px 0",
                      background: theme.colors.border3,
                    }}
                  >
                    <SideArrow />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <GrayBgHeader>
            <GrayBgHeaderText>Teams Collection</GrayBgHeaderText>
          </GrayBgHeader>
          <Box
            padding="1.5rem"
            sx={{
              overflowY: viewAllCollectionClick ? "auto" : undefined,
              height:'100%'
            }}
          >
            {limitedItems?.length > 0 ? (
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: '20px' }} >
                {limitedItems?.map((item: folderItem, index: number) => (
                  <BrandFolderCard
                    key={index}
                    item={item}
                    cardWidth="100%"
                    ableToFavourite={false}
                  />
                ))}
              </Box>

            ) : (
              <NoSwipeAdsTeamsCollectionFoundIcon />
            )}
          </Box>
          <StyledDivider />
          {Array.isArray(limitedItems) && limitedItems.length >= 12 && (
            <DashboardCardButton
              endIcon={<ArrowRightIcon />}
              sx={{
                margin: "1.25rem auto",
              }}
              disabled={folderList?.teamFolders?.length > 12 ? false : true}
              onClick={() => setViewAllCollectionClick(!viewAllCollectionClick)}
            >
              View All
            </DashboardCardButton>
          )}
        </Box>
      </Stack>
    </CardContainer>
  );
};

export default SwipeAdSection;
