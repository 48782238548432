import { CardContainerUserInfo, CardSubTitle, SavedAdsDashboardStack, DashboardCardButtonUserInfo, DashboardUserInfo } from "./style";
import { Avatar, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { StyledDivider } from "../../globalStyle";
import { SettingsIcon } from "../../assets/Icons";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "../../store/account/selector";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { dashboardAnalyticsSelector } from "../../store/dashboardAnalytics/selector";
import { formatAdCount } from "../../utils/helpers";

const Greeting = ({ userName }: { userName: string }) => {
  const theme = useTheme();
  var myDate = new Date();
  var hours = myDate.getHours();
  var greet;

  if (hours < 12) greet = "Good Morning";
  else if (hours >= 12 && hours <= 17) greet = "Good Afternoon";
  else if (hours >= 17 && hours <= 24) greet = "Good Evening";

  return (
    <Typography
      fontSize="1.375rem"
      fontWeight="600"
      color={theme.colors.black2}
    >
      {greet} {userName?.split(" ")[0]}!
    </Typography>
  );
};

const UserInfoCard = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userDetails = useSelector(userDetailsSelector);
  const dashboardAnalytics = useSelector(dashboardAnalyticsSelector);

  const handleRedirection = () => {
    navigate(`/${routeConstant.settings}`);
  };
  return (
    <CardContainerUserInfo
      sx={{
        width: "50%",
        alignItems: "center",
        gap: "0.625rem",
        display: "flex",
      }}
    >
      <DashboardUserInfo>
        <Avatar
          src={`${
            userDetails?.profileImage
              ? userDetails?.profileImage
              : "assests/images/user-logo.png"
          }`}
        />
        <Stack>
          <Greeting userName={userDetails?.fullName} />
          <CardSubTitle>{userDetails?.email}</CardSubTitle>
        </Stack>
      </DashboardUserInfo>
      <StyledDivider
        orientation="vertical"
        flexItem
        variant="middle"
        sx={{ height: "5rem", m: "0 0.5rem" }}
      />
      <SavedAdsDashboardStack alignItems="center">
        <Typography
          fontSize="0.938rem"
          fontWeight="400"
          color={theme.colors.gray1}
        >
          Saved Ads
        </Typography>
        <Typography
          fontSize="1.25rem"
          fontWeight="700"
          color={theme.colors.black2}
        >
          {formatAdCount(dashboardAnalytics.userSavedAds)}
        </Typography>
      </SavedAdsDashboardStack>
      <DashboardCardButtonUserInfo
        startIcon={<SettingsIcon />}
        sx={{
          marginLeft: "auto",
        }}
        onClick={handleRedirection}
      >
        Account Settings
      </DashboardCardButtonUserInfo>
    </CardContainerUserInfo>
  );
};

export default UserInfoCard;
