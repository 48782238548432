// TODO: commented code needed in future.
import { useState } from "react";
import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { adsTableColumns } from "../../../utils/constants";
import { ReactComponent as UncheckedIcon } from "../../../assets/images/unchecked-checkbox.svg";
import { ReactComponent as CheckedIcon } from "../../../assets/images/checked-checkbox.svg";
import { useTheme } from "styled-components";
import { StyledTableCell } from "./style";
import SelectBoardDropdown from "../selectBoardsDropdown";
import RatingsDropdown from "../ratingsDropdown";
import TagsDropdown from "../tagsDropdown";
import { EyeIcon, FacebookIcon } from "../../../assets/Icons";
import ActiveStatusPopover from "../../activeStatusPopover";
import { InActiveBadge } from "../../../pageComponents/style";
import { useDispatch, useSelector } from "react-redux";
import {
  selectedTableItemsSelector,
  swipeFileListSelector,
} from "../../../store/swipeFile/selector";
import {
  SwipeFileListItems,
  handleSelectItemType,
} from "../../../store/swipeFile/types";
import { SwipeFileReducerAction } from "../../../store/swipeFile/slice";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { DiscoveryItem } from "../../../store/discovery/types";
import { formatDistance } from "date-fns";
import { BoardItem, tagItem } from "../../../store/metadata/types";
import { TagsSagaActions } from "../../../store/tags/sagas";
import { NavLink, useLocation } from "react-router-dom";

const TableRowComponent = ({
  data,
  isItemSelected,
  handleSelectItem,
}: {
  data: any;
  isItemSelected: boolean;
  handleSelectItem: ({ event, selectAll, id }: handleSelectItemType) => void;
  handleActiveStatusOpen: (event: React.MouseEvent<HTMLElement>) => void;
  handleActiveStatusClose: () => void;
  index: number;
  dataList: DiscoveryItem[] | SwipeFileListItems[];
}) => {
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleUpdateBoard = (
    selectedBoardIds: string[],
    removedBoardId: string[]
  ) => {
    dispatch(
      MetaDataSagaActions.saveAdToBoards({
        adId: data?._id,
        boardIds: selectedBoardIds,
        removeBoardIds: removedBoardId,
      })
    );
  };

  const handleUpdateTag = (
    selectedTagsIds: string[],
    removeTagId: string[]
  ) => {
    dispatch(
      TagsSagaActions.saveAdTags({
        adId: data?._id,
        saveTags: selectedTagsIds,
        removeTags: removeTagId,
      })
    );
  };

  return (
    <>
      <TableRow
        key={data._id}
        selected={isItemSelected}
        sx={{
          cursor: "pointer",
          "&.Mui-selected": {
            backgroundColor: theme.colors.border1,
          },
          "&.Mui-selected:hover": {
            backgroundColor: theme.colors.border1,
          },
        }}
      >
        <StyledTableCell padding="checkbox">
          <Checkbox
            checked={isItemSelected}
            icon={<UncheckedIcon />}
            checkedIcon={<CheckedIcon />}
            onChange={(event) =>
              handleSelectItem({ event, selectAll: false, id: data._id })
            }
          />
        </StyledTableCell>
        <NavLink
          style={{ textDecoration: "none" }}
          to={`${location.pathname}/adDetails/${data._id}`}
        >
          <StyledTableCell
            align="left"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.938rem",
              color: theme.colors.black2,
              fontSize: "1rem",
              fontWeight: 600,
              minWidth: 240,
            }}
          >
            <img
              src={data.page?.logo}
              alt=""
              style={{
                borderRadius: "0.25rem",
                width: "2.25rem",
                height: "2.25rem",
              }}
            />
            {data.page?.name}
          </StyledTableCell>
        </NavLink>
        <StyledTableCell
          align="left"
          sx={{
            color: theme.colors.black2,
            fontSize: "1rem",
            fontWeight: 600,
            minWidth: 240,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FacebookIcon />
            <InActiveBadge variant="dot" margin="0.5rem 1.25rem 0 0.625rem" />
            <span>
              {formatDistance(new Date(data.createdAt), new Date(), {
                addSuffix: true,
              })}
            </span>
          </Box>
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            minWidth: 240,
          }}
        >
          <SelectBoardDropdown
            showSelectedItemCount={1}
            selectedBoards={data?.board}
            selectedBoardIds={data?.board?.map((item: BoardItem) => item._id)}
            handleUpdateBoard={handleUpdateBoard}
          />
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            minWidth: 240,
          }}
        >
          <RatingsDropdown
            adId={data?._id}
            ratingValue={data?.ratings && data?.ratings[0]?.rating}
          />
        </StyledTableCell>
        <StyledTableCell
          align="left"
          sx={{
            minWidth: 240,
          }}
        >
          <TagsDropdown
            showSelectedItemCount={1}
            selectedTags={data.Tags}
            selectedTagIds={data.Tags?.map((tag: tagItem) => tag._id)}
            handleUpdateTag={handleUpdateTag}
          />
        </StyledTableCell>
        <StyledTableCell align="left">
          <NavLink to={`${location.pathname}/adDetails/${data._id}`}>
            <EyeIcon />
          </NavLink>
        </StyledTableCell>
      </TableRow>
    </>
  );
};

const AdTable = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const selectedTableItems = useSelector(selectedTableItemsSelector);
  const swipeFileItems = useSelector(swipeFileListSelector);

  const [activeStatusPopupAnchorEl, setActiveStatusPopupAnchorEl] =
    useState<HTMLElement | null>(null);

  const handleSelectItem = ({ event, selectAll, id }: handleSelectItemType) => {
    dispatch(
      SwipeFileReducerAction.setSelectedTableItems({
        checked: event.target.checked,
        selectAll,
        id,
      })
    );
  };

  const handleActiveStatusOpen = (event: React.MouseEvent<HTMLElement>) => {
    setActiveStatusPopupAnchorEl(event.currentTarget);
  };

  const handleActiveStatusClose = () => {
    setActiveStatusPopupAnchorEl(null);
  };

  return (
    <>
      <TableContainer
        sx={{
          background: "white",
          overflow: "unset",
        }}
      >
        <Table size="medium">
          <TableHead>
            <TableRow>
              <StyledTableCell padding="checkbox">
                <Checkbox
                  checked={
                    swipeFileItems?.data?.length > 0 &&
                    selectedTableItems.length === swipeFileItems?.data?.length
                  }
                  onChange={(event) =>
                    handleSelectItem({ event, selectAll: true })
                  }
                  icon={<UncheckedIcon />}
                  checkedIcon={<CheckedIcon />}
                />
              </StyledTableCell>
              {adsTableColumns.map((headCell) => (
                <StyledTableCell
                  key={headCell.id}
                  sx={{ color: theme.colors.gray1, fontSize: "1rem" }}
                >
                  {headCell.label}
                </StyledTableCell>
              ))}
              <StyledTableCell />
              <StyledTableCell />
            </TableRow>
          </TableHead>

          <TableBody>
            {swipeFileItems?.data?.map(
              (row: DiscoveryItem | SwipeFileListItems, index: number) => {
                const isItemSelected =
                  selectedTableItems.indexOf(row._id) !== -1;

                return (
                  <TableRowComponent
                    key={row._id}
                    data={row}
                    isItemSelected={isItemSelected}
                    handleSelectItem={handleSelectItem}
                    handleActiveStatusOpen={handleActiveStatusOpen}
                    handleActiveStatusClose={handleActiveStatusClose}
                    index={index}
                    dataList={swipeFileItems?.data}
                  />
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ActiveStatusPopover
        anchorEl={activeStatusPopupAnchorEl}
        handlePopoverClose={handleActiveStatusClose}
      />
    </>
  );
};

export default AdTable;
