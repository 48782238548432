import { Avatar, Box, Stack, Typography } from "@mui/material";
import {
  CommentsCount,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
} from "./style";
import { ArrowDownIcon, CloseIcon } from "../../../assets/Icons";
import { memo, useCallback, useState } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LexicalTextEditorTheme } from "../../../utils/theme";
import { useTheme } from "styled-components";
import ToolbarPlugin from "../textEditorPlugins/toolbarPlugin";
import SendButton from "../textEditorPlugins/sendButton";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import "./textEditorStyle.css";
import { DiscoveryItem } from "../../../store/discovery/types";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import {
  adDetailsSelector,
  isLoadingSelector,
} from "../../../store/metadata/selector";
import { commentsType } from "../../../store/metadata/types";
import { formatCreatedDate } from "../../../utils/helpers";
import { CustomButton } from "../../../globalStyle";
import { userDetailsSelector } from "../../../store/account/selector";
import { $getRoot, EditorState } from "lexical";

const editorConfig = {
  namespace: "React MarkDown Editor Demo",
  onError(error: Error) {
    throw error;
  },
  theme: LexicalTextEditorTheme,
};

const CommentBox = ({ item }: { item: DiscoveryItem }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(true);
  const [editorText, setEditorText] = useState<string>("");

  const dispatch = useDispatch();

  const handleAddComment = (comment: string) => {
    dispatch(
      MetaDataSagaActions.addComment({
        _id: item._id,
        payload: { comment },
      })
    );
  };
  const handleCommentDelete = (commentId: string) => {
    dispatch(MetaDataSagaActions.deleteCommentById(commentId));
  };

  const adDetailsData = useSelector(adDetailsSelector);
  const userDetails = useSelector(userDetailsSelector);
  const loadingCommentAction = useSelector(isLoadingSelector);

  const onChange = useCallback((editorState: EditorState) => {
    const editorStateTextString = editorState.read(() =>
      $getRoot().getTextContent()
    );
    setEditorText(editorStateTextString);
  }, []);

  return (
    <Box>
      <StyledAccordion
        expanded={expanded}
        onChange={() => setExpanded(!expanded)}
      >
        <StyledAccordionSummary
          expandIcon={<ArrowDownIcon />}
          sx={{ padding: "1rem" }}
        >
          Comments
          <CommentsCount>{adDetailsData?.Comments?.length ?? 0}</CommentsCount>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Box sx={{ height: "200px", overflow: "auto" }}>
            {adDetailsData?.Comments?.map((item: commentsType) => (
              <Stack
                key={item._id}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                marginBottom="0.313rem"
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.75rem",
                    backgroundColor: theme.colors.background4,
                    width: "fit-content",
                    padding: "0.5rem 0.625rem",
                  }}
                >
                  <Avatar
                    src={item?.user?.profileImage ?? ""}
                    sx={{ width: "2.5rem", height: "2.5rem" }}
                  />
                  <Stack>
                    <Typography
                      fontSize="0.938rem"
                      color={theme.colors.black2}
                      fontWeight={500}
                      dangerouslySetInnerHTML={{ __html: item?.comment }}
                    />
                    <Stack direction="row" display={"flex"} gap={2.5}>
                      <Typography
                        fontSize="0.75rem"
                        color={theme.colors.gray1}
                        fontWeight={400}
                      >
                        {item?.user?.fullName} |{" "}
                        {item?.createdAt && formatCreatedDate(item?.createdAt)}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
                {userDetails?._id === item?.userId && (
                  <CustomButton
                    disabled={loadingCommentAction}
                    startIcon={<CloseIcon />}
                    onClick={() => handleCommentDelete(item?._id)}
                  />
                )}
              </Stack>
            ))}
          </Box>
          <LexicalComposer initialConfig={editorConfig}>
            <Box
              sx={{
                margin: "0 -1rem",
                position: "relative",
                lineHeight: "1.25rem",
              }}
            >
              <RichTextPlugin
                contentEditable={<ContentEditable className="editor-input" />}
                placeholder={
                  <Box
                    sx={{
                      color: theme.colors.black2,
                      fontWeight: "500",
                      position: "absolute",
                      textOverflow: "ellipsis",
                      top: "0.938rem",
                      left: "0.625rem",
                      fontSize: "0.938rem",
                      pointerEvents: "none",
                    }}
                  >
                    Add your comment...
                  </Box>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
              <ClearEditorPlugin />
              <OnChangePlugin onChange={onChange} />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "0.5rem",
              }}
            >
              <ToolbarPlugin />
              <SendButton
                handleAddComment={handleAddComment}
                editorText={editorText}
              />
            </Box>
          </LexicalComposer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default memo(CommentBox);
