import { SwipeFileBlackIcon } from "../../../assets/Icons";

export const SwipeFileAdIcon = () => (
  <span
    style={{
      height: "18px",
      width: "18px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "4px",
      background: "black",
      marginRight: "8px",
    }}
  >
    <SwipeFileBlackIcon />
  </span>
);
