import styled from "styled-components";
import { OutlinedInput, InputLabel, Checkbox } from "@mui/material";

export const StyledOutlinedInput = styled(OutlinedInput)<{
  showPassword?: boolean;
}>`
  width: 100%;
  height: 62px;
  && {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    ${({ showPassword, name }) =>
      name === "password" && !showPassword
        ? {
            letterSpacing: "5px",
            fontSize: "1.875rem",
          }
        : { fontSize: "1.125rem" }};

    .MuiInputBase-input {
      padding-inline: 30px;
    }
    .MuiOutlinedInput-notchedOutline,
    &:hover .MuiOutlinedInput-notchedOutline,
    &:focus-within .MuiOutlinedInput-notchedOutline {
      border-color: ${({ theme }) => theme.colors.white};
      border-radius: 2px;
    }
  }
  @media (max-width: 1440px) {
    && {
      font-size: 1rem;
    }
  }
`;

export const StyledInputLabel = styled(InputLabel)`
  && {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.0713rem;
    color: ${({ theme }) => theme.colors.white};
  }
  margin-bottom: 0.5rem;
  @media (max-width: 1440px) {
    && {
      font-size: 1rem;
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  && {
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    margin-right: 0.5rem;
  }
`;

export const EyeIcon = styled.img`
  right: 1rem;
`;

export const EyeIconButton = styled.button`
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;
  z-index: 5;
`;

export const CustomTextfieldWrapper = styled.div`
  position: relative;
  width: 100%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const ErrorTextWrapper = styled.div`
  margin-top: -30px;
  width: 100%;
  color: red;

  @media (max-width: 1440px) {
    margin-top: -15px;
  }
`;

export const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;