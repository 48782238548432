import { CircularProgress, DialogTitle, Divider, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  CreateBoardWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
  StyledFormControl,
  StyledInputLabel,
  StyledTextField,
} from "./style";
import { AdEyeIcon, CloseIcon, CrossEyeIcon } from "../../assets/Icons";
import { ResetPasswordProps } from "../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPasswordValidationSchema } from "../../utils/formValidationSchema";
import { ErrorMessage } from "../../pageComponents/auth/style";
import {
  openResetPasswordModalSelector,
  userDetailsSelector,
} from "../../store/account/selector";
import { AccountReducerAction } from "../../store/account/slice";
import { AccountSagaActions } from "../../store/account/sagas";
import { useContext, useState } from "react";
import { EyeIconButton } from "../commonComponents/formFields/style";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { logoutService } from "../../services/AuthServices";
import { AxiosResponse } from "axios";

const ResetPasswordModal = () => {
  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordProps>({
    mode: "onTouched",
    resolver: yupResolver(resetPasswordValidationSchema),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const user = useSelector(userDetailsSelector);
  const openModal = useSelector(openResetPasswordModalSelector);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { setAuthStatus } = useContext(AuthContext);

  const onSubmit = () => {
    const values = getValues();
    setLoading(true);
    dispatch(
      AccountSagaActions.resetPassword({
        id: user._id,
        body: values,
        handleResetState: handleResetState,
        handleLogout: handleLogout,
      })
    );
  };

  const handleResetState = () => {
    reset();
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setLoading(false);
  };

  const handleLogout = async () => {
    const response = (await logoutService()) as AxiosResponse;
    if (response?.status === 200) {
      setAuthStatus(false);
      localStorage.clear();
      setLoading(false);
      navigate(`/${routeConstant.login}`, {
        replace: true
      });
    }
  };

  const handleCloseModal = () => {
    dispatch(AccountReducerAction.setOpenResetPasswordModal(false));
    handleResetState();
  };

  const handleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  return (
    <StyledDialog
      onClose={handleCloseModal}
      open={openModal}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalTitle>Change Password</ModalTitle>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <CreateBoardWrapper>
        <StyledFormControl fullWidth>
          <StyledInputLabel
            htmlFor="oldPassword"
            style={{
              fontWeight: "600",
            }}
          >
            Old Password
          </StyledInputLabel>
          <StyledTextField
            type={showOldPassword ? "text" : "password"}
            id="oldPassword"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <EyeIconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleOldPassword();
                  }}
                >
                  {showOldPassword ? <AdEyeIcon /> : <CrossEyeIcon />}
                </EyeIconButton>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            {...register("oldPassword", { required: true })}
          />
          {errors.oldPassword?.message && (
            <ErrorMessage>{errors.oldPassword?.message}</ErrorMessage>
          )}
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledInputLabel
            htmlFor="newPassword"
            style={{
              fontWeight: "600",
            }}
          >
            New Password
          </StyledInputLabel>
          <StyledTextField
            type={showNewPassword ? "text" : "password"}
            id="newPassword"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <EyeIconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleNewPassword();
                  }}
                >
                  {showNewPassword ? <AdEyeIcon /> : <CrossEyeIcon />}
                </EyeIconButton>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            {...register("newPassword", { required: true })}
          />
          {errors.newPassword?.message && (
            <ErrorMessage>{errors.newPassword?.message}</ErrorMessage>
          )}
        </StyledFormControl>
        <StyledFormControl fullWidth>
          <StyledInputLabel
            htmlFor="confirmPassword"
            style={{
              fontWeight: "600",
            }}
          >
            Confirm Password
          </StyledInputLabel>
          <StyledTextField
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            variant="outlined"
            InputProps={{
              endAdornment: (
                <EyeIconButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmPassword();
                  }}
                >
                  {showConfirmPassword ? <AdEyeIcon /> : <CrossEyeIcon />}
                </EyeIconButton>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            {...register("confirmPassword", {
              required: true,
            })}
          />
          {errors.confirmPassword && (
            <ErrorMessage>{errors.confirmPassword?.message}</ErrorMessage>
          )}
        </StyledFormControl>
      </CreateBoardWrapper>
      <Divider sx={{ borderColor: "#E3E6EA" }} />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 2.375rem",
        }}
        onClick={handleSubmit(onSubmit)}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Change Password
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default ResetPasswordModal;
