import {
  BrandCountWrapper,
  BrandDetailWrapper,
  BrandImageWrapper,
  BrandLinkWrapper,
  BrandLogoWrapper,
  BrandName,
  FavouriteButton,
  LibraryButton,
  StyledNumberText,
  TopBrandCardContainer,
} from "./style";
import { FavouriteIcon, HeartIcon } from "../../../assets/Icons";
import { Link, Stack } from "@mui/material";
import FacebookIcon from "../../../assets/images/facebook-icon.png";
import { TopBrandCardProps } from "../../../utils/propTypes";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import { favouriteBrandIdsSelector } from "../../../store/metadata/selector";
import { isLoadingSelector } from "../../../store/discovery/selector";
import { brandItem } from "../../../store/metadata/types";
import { formatAdCount } from "../../../utils/helpers";
import { SwipeFileAdIcon } from "../swipeFileAdIcon";

const TopBrandCard = ({
  index,
  item,
  isDiscovery,
  buttonsToShow,
  disableFavourite,
  handleViewAd,
  showNumber = false,
}: TopBrandCardProps) => {
  const dispatch = useDispatch();
  const handleClick = (brandId?: string) => {
    if (handleViewAd) {
      brandId && handleViewAd(brandId);
    }
  };
  const RenderButton = () => {
    const cardButtons: {
      [key: string]: JSX.Element;
    } = {
      facebookButton: (
        <Link
          style={{
            textDecoration: "none",
          }}
          target={"_blank"}
          href={item?.url}
        >
          <LibraryButton fullWidth sx={{ padding: "0.5rem 0 !important" }}>
            FB Ad Library
            <img src={FacebookIcon} alt="" style={{ marginLeft: "0.5rem" }} />
          </LibraryButton>
        </Link>
      ),
      viewAdsButton: (
        <LibraryButton fullWidth onClick={() => handleClick(item?._id)}>
          View Ads
        </LibraryButton>
      ),
    };
    return (
      <>
        {Object.keys(cardButtons).map((buttonName) =>
          buttonsToShow.includes(buttonName) && cardButtons[buttonName] ? (
            cardButtons[buttonName]
          ) : (
            <></>
          )
        )}
      </>
    );
  };

  const handleFavourite = (brand: brandItem | undefined) => {
    brand && dispatch(MetaDataSagaActions.addBrandToFavourite(brand));
  };
  const metadataLoading = useSelector(isLoadingSelector);
  const favouriteBrandIds = useSelector(favouriteBrandIdsSelector);

  return (
    <TopBrandCardContainer>
      {showNumber && (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <StyledNumberText>{index}</StyledNumberText>
        </Stack>
      )}

      {/* <BrandGenre>Beauty</BrandGenre> */}
      <BrandImageWrapper>
        <FavouriteButton
          endIcon={
            favouriteBrandIds.includes(item?._id) ? (
              <FavouriteIcon />
            ) : (
              <HeartIcon />
            )
          }
          onClick={() => handleFavourite(item)}
          disabled={metadataLoading || disableFavourite}
        />

        <BrandDetailWrapper>
          <BrandLogoWrapper onClick={() => handleClick(item?._id)}>
            <img
              src={item?.logo}
              alt="brand"
              style={{
                maxWidth: "100%",
                minHeight: "100%",
                objectFit: "cover",
              }}
            />
          </BrandLogoWrapper>
          <BrandName fontWeight={600} onClick={() => handleClick(item?._id)}>
            {item?.name}
          </BrandName>
          {!isDiscovery && (
            <BrandCountWrapper>
              <SwipeFileAdIcon />
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "1.125rem",
                  lineHeight: "1.371rem",
                  padding: "0",
                  margin: "0",
                }}
              >
                {formatAdCount(item?.adsSaved ?? 0)} Saved
              </p>
            </BrandCountWrapper>
          )}
        </BrandDetailWrapper>
        <BrandLinkWrapper>
          <RenderButton />
        </BrandLinkWrapper>
      </BrandImageWrapper>
    </TopBrandCardContainer>
  );
};

export default TopBrandCard;
