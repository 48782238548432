import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.starredState || initialState;

export const starredTabIndexSelector = createSelector(
  selectDomain,
  (state) => state.starredTabIndex
);

export const starredListSelector = createSelector(
  selectDomain,
  (state) => state.starredList
);

export const loadingStarredListSelector = createSelector(
  selectDomain,
  (state) => state.loadingStarredList
);

export const starredListParamsSelector = createSelector(
  selectDomain,
  (state) => state.starredListsParams
);
