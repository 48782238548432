import styled from "styled-components";
import { FormGroup, Menu } from "@mui/material";

export const PopoverMenu = styled(Menu)`
  ul {
    padding: 0 !important;
  }
`;

export const StyledFormGroup = styled(FormGroup)<{ padding?: string }>`
  && {
    padding: ${({ padding }) => (padding ? padding : "0.813rem 1.25rem")};
    gap: 0.625rem;
    height: fit-content;
    max-height: 220px;
    flex-wrap: nowrap;
    overflow-y: auto;
    margin-right: -0.875rem;
    margin-left: -1rem;
    background-color: ${({ theme }) => theme.colors.background};
  }
  &.MuiFormGroup-root::-webkit-scrollbar {
    width: 0.25rem;
  }
  &.MuiFormGroup-root::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: ${({ theme }) => theme.colors.border3};
  }
`;
