import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import styled, { useTheme } from "styled-components";
import { SwipeLoungeIcon } from "../../assets/Icons";

const ErrorWrapper = styled("div")({
  maxWidth: "100%",
  margin: "0 auto",
  textAlign: "center",
});

const ErrorCard = styled(Card)({
  minHeight: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Maintenance = () => {
  const theme = useTheme();
  return (
    <ErrorCard
      sx={{
        background: theme.colors.black3,
        zIndex: '100'
      }}
    >
      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          <Box
            sx={{
              marginBottom: "3.438rem",
            }}
          >
            <SwipeLoungeIcon />
          </Box>
          <Grid item xs={12}>
            <ErrorWrapper>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: "600",
                      fontSize: "1.25rem",
                      letterSpacing: "0.038rem",
                      textAlign: "center",
                      marginBottom: "0.938rem",
                      color: theme.colors.white,
                    }}
                  >
                    We’re under maintenance.
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "400",
                      fontSize: "0.938rem",
                      letterSpacing: "0.6px",
                      textAlign: "center",
                      color: theme.colors.white,
                    }}
                  >
                    We will be back shortly. And we understand the inconvenience
                    caused. You can use extension to save ads and check them
                    later here. Cheers
                  </Typography>
                </Grid>
              </Grid>
            </ErrorWrapper>
          </Grid>
        </Grid>
      </CardContent>
    </ErrorCard>
  );
};

export default Maintenance;
