import { useContext, useEffect, useState } from "react";
import {
  BottomButtonWrapper,
  FormFieldWrapper,
  FormWrapper,
  LoginLayoutContainer,
  LogoWrapper,
  PageTitle,
  StyledLoginButton,
  CredentialSection,
  ErrorMessage,
  ImageSection,
} from "../style";
import Logo from "../../../assets/images/Logo.png";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import {
  EyeIcon,
  EyeIconButton,
} from "../../../components/commonComponents/formFields/style";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  LoginFormValuesProp,
  ResetPasswordFormValuesProp,
} from "../../../utils/propTypes";
import {
  loginValidationSchema,
  resetPasswordFormValidationSchema,
} from "../../../utils/formValidationSchema";
import { ResetPasswordService } from "../../../services/AuthServices";
import Carousel from "../../../components/commonComponents/carousel";
import { CarouselContext } from "../../../context/CarouselContext";
import { useNavigate, useParams } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { AxiosResponse } from "axios";
import {
  ForgotOrResetPasswordResponse,
  LoginSignupResponse,
} from "../../../types/AuthTypes";

import { ToastTypes, notify } from "../../../utils/helpers";
import routeConstant from "../../../utils/routeConstant";
import { CarouselType } from "../../../types/Carousel";

const ResetPassword = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    getValues,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ResetPasswordFormValuesProp>({
    resolver: yupResolver(resetPasswordFormValidationSchema),
  });
  const { carouselImages } = useContext(CarouselContext);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async () => {
    const values = getValues();
    setLoading(true);
    const reqBody = {
      token: params.token,
      password: values.password,
    };
    const response = (await ResetPasswordService(
      reqBody
    )) as AxiosResponse<ForgotOrResetPasswordResponse>;
    if (response.status === 200) {
      notify({ message: response?.data?.message, type: ToastTypes.SUCCESS });
      setLoading(false);
      reset();
      navigate(`/${routeConstant.login}`, { replace: true });
    } else {
      setLoading(false);
    }
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    submitHandler: () => void
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };

  const renderCarousel = (images: CarouselType[], direction: "ltr" | "rtl") => (
    <div className={`Marquee-content Marquee-content-${direction}`}>
      {[...Array(6)].flatMap((_, repetitionIndex) =>
        images.map((image, index) => (
          <div className="Marquee-tag" key={`${index}-${repetitionIndex}`}>
            <img src={image.imageUrl} alt="Vertical-Card-1" />
          </div>
        ))
      )}
    </div>
  );

  return (
    <LoginLayoutContainer container>
      <CredentialSection xs={12} md={6}>
        <LogoWrapper src={Logo} alt="app-logo" />
        <PageTitle
          style={{
            marginTop: "12%",
          }}
        >
          Reset Password
        </PageTitle>
        <FormWrapper>
          <FormFieldWrapper>
            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem !important",
                  color: "white",

                  zIndex: 2,
                },
                "& > div": {
                  border: "3px solid white",
                  borderRadius: "2px",
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{
                  width: "100%",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                htmlFor="outlined-adornment-password-login"
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? "text" : "password"}
                label="Password"
                endAdornment={
                  <EyeIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handlePassword();
                    }}
                  >
                    <EyeIcon
                      src={
                        showPassword
                          ? "/assets/images/eye-icon.svg"
                          : "/assets/images/eye-close.svg"
                      }
                      alt="Show-Hide-Password-Icon"
                    />
                  </EyeIconButton>
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                {...register("password", { required: true })}
              />
            </FormControl>
            {errors.password?.message && (
              <ErrorMessage>{errors.password?.message}</ErrorMessage>
            )}

            <FormControl
              fullWidth
              sx={{
                "& > label": {
                  top: 23,
                  left: 0,
                  color: "white",
                  '&[data-shrink="false"]': {
                    top: 5,
                  },
                },
                "& > div > input": {
                  padding: "1.906rem 0.875rem 0.719rem !important",
                  color: "white",

                  zIndex: 2,
                },
                "& > div": {
                  border: "3px solid white",
                  borderRadius: "2px",
                },
                "& legend": {
                  display: "none",
                },
                "& fieldset": {
                  top: 0,
                },
              }}
            >
              <InputLabel
                style={{
                  width: "100%",
                  color: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                htmlFor="outlined-adornment-confirm-password-login"
              >
                Confirm Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-confirm-password-login"
                type={showConfirmPassword ? "text" : "password"}
                label="Confirm Password"
                endAdornment={
                  <EyeIconButton
                    onClick={(e) => {
                      e.preventDefault();
                      handleConfirmPassword();
                    }}
                  >
                    <EyeIcon
                      src={
                        showConfirmPassword
                          ? "/assets/images/eye-icon.svg"
                          : "/assets/images/eye-close.svg"
                      }
                      alt="Show-Hide-Confirm-Password-Icon"
                    />
                  </EyeIconButton>
                }
                {...register("confirmPassword", { required: true })}
                onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
              />
            </FormControl>
            {errors.confirmPassword?.message && (
              <ErrorMessage>{errors.confirmPassword?.message}</ErrorMessage>
            )}
          </FormFieldWrapper>
          <BottomButtonWrapper>
            <StyledLoginButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              startIcon={
                loading && <CircularProgress size={20} color="inherit" />
              }
            >
              Reset Password
            </StyledLoginButton>
          </BottomButtonWrapper>
        </FormWrapper>
      </CredentialSection>
      <ImageSection xs={12} md={6}>
        {carouselImages.carousel_one?.length > 0 && (
          <>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_one, "ltr")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_two, "rtl")}
            </div>
            <div className="Marquee">
              {renderCarousel(carouselImages.carousel_three, "ltr")}
            </div>
          </>
        )}
      </ImageSection>
    </LoginLayoutContainer>
  );
};

export default ResetPassword;
