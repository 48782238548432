import { call, put, select } from "redux-saga/effects";
import { createSliceSaga } from "../saga-helper";
import { SwipeFileReducerAction } from "./slice";
import { AxiosResponse } from "axios";
import {
  deleteAdService,
  deleteMultipleAdService,
  getSwipeFileAdsList,
} from "../../services/SwipeFileServices";
import { TopBrandLists } from "../../services/BrandsService";
import { FilterOptions, swipeFileBrandsParamsType } from "./types";
import {
  swipefileFilterOptionSelector,
  swipeFileBrandsParamsSelector,
  swipeFilePageSearchSelector,
  selectedDeleteAdIdSelector,
  selectedTableItemsSelector,
} from "./selector";
import { AddMultipleBoardsToAdsService } from "../../services/BoardServices";
import { BoardItem, tagItem } from "../metadata/types";
import { TagsListsSelector, boardItemsSelector } from "../metadata/selector";
import { AddMultipleTagsToAdsService } from "../../services/TagsService";
import * as Sentry from "@sentry/react";

const saga = createSliceSaga({
  name: "swipeFileState",
  caseSagas: {
    *fetchSwipeFileList(action: {
      type: string;
      payload: {
        userId: string;
      };
    }) {
      try {
        yield put(
          SwipeFileReducerAction.setLoggingUserIdInFilterOption(
            action.payload.userId
          )
        );
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        yield put(SwipeFileReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getSwipeFileAdsList, data);
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSwipeFileTopBrandList(action: {
      payload: {
        limit: number;
        skip: number;
        favourite: boolean;
      };
      type: string;
    }) {
      try {
        if (action.payload.skip === 0) {
          yield put(SwipeFileReducerAction.setIsLoading(true));
        }
        const userDetails = JSON.parse(localStorage.getItem("User") as string);
        const response: AxiosResponse = yield call(TopBrandLists, {
          ...action.payload,
          userId: userDetails?.user?._id,
        });

        if (response?.status === 200) {
          yield put(
            SwipeFileReducerAction.setSwipeFileTopBrands({
              brandData: response?.data,
              skipValue: action.payload.skip,
            })
          );
          yield put(
            SwipeFileReducerAction.setSwipeFileBrandsParams(action.payload.skip)
          );
        }
        yield put(SwipeFileReducerAction.setIsLoading(false));
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *applyFilterOnSwipeFileList(action: any) {
      try {
        yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
        yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
        yield put(
          SwipeFileReducerAction.setSelectedFilterOptions(action.payload)
        );
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        yield put(SwipeFileReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getSwipeFileAdsList, data);
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSelectedBoardAds(action: {
      payload: {
        boardId: string;
        handleRedirectToSwipefile: Function;
      };
      type: string;
    }) {
      try {
        yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
        yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
        yield put(
          SwipeFileReducerAction.setSelectedBoardId(action.payload.boardId)
        );
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        yield put(SwipeFileReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getSwipeFileAdsList, data);
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setIsLoading(false));
          action.payload.handleRedirectToSwipefile();
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSortedSwipeFileList() {
      try {
        yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
        yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        yield put(SwipeFileReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getSwipeFileAdsList, data);

        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSwipeFileSearchTopBrandList(action: {
      payload: {
        handleScroll: Function;
      };
      type: string;
    }) {
      try {
        yield put(SwipeFileReducerAction.setSwipeFileBrandsParams(0));
        yield put(
          SwipeFileReducerAction.setSwipeFileTopBrandsToInitialState([])
        );
        const search: string = yield select(swipeFilePageSearchSelector);
        const swipeFileBrandsParams: swipeFileBrandsParamsType = yield select(
          swipeFileBrandsParamsSelector
        );
        const response: AxiosResponse = yield call(TopBrandLists, {
          ...swipeFileBrandsParams,
          search: search,
        });
        if (response?.status === 200) {
          yield put(
            SwipeFileReducerAction.setSwipeFileTopBrands({
              brandData: response?.data,
              skipValue: 0,
            })
          );
          yield put(
            SwipeFileReducerAction.setSwipeFileBrandsParams(
              swipeFileBrandsParams.skip + swipeFileBrandsParams.limit
            )
          );
          if (action?.payload?.handleScroll) {
            action.payload.handleScroll();
          }
        }
      } catch (error) {
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSearchedSwipeFileItema() {
      try {
        yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
        yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
        const search: string = yield select(swipeFilePageSearchSelector);
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        yield put(SwipeFileReducerAction.setIsLoading(true));
        const response: AxiosResponse = yield call(getSwipeFileAdsList, {
          ...data,
          search: search,
        });
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setIsLoading(false));
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchBrandSwipeFileItems(action: {
      payload: {
        brandId: string;
        handleLoadingState: Function;
      };
      type: string;
    }) {
      try {
        yield put(SwipeFileReducerAction.setSwipeFileBrandsLoading(true));
        yield put(
          SwipeFileReducerAction.setFilterOptionBrandId(action.payload.brandId)
        );
        yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
        yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        const response: AxiosResponse = yield call(getSwipeFileAdsList, data);
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setSwipeFileTabIndex(0));
          yield put(SwipeFileReducerAction.setSwipeFileBrandsLoading(false));
          action.payload.handleLoadingState &&
            action.payload.handleLoadingState();
          window.scrollTo(0, 0);
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *deleteAd() {
      try {
        yield put(SwipeFileReducerAction.setDeleteAdLoading(true));

        const selectedDeleteAdId: string = yield select(
          selectedDeleteAdIdSelector
        );

        const response: AxiosResponse = yield call(
          deleteAdService,
          selectedDeleteAdId
        );
        if (response?.status === 200) {
          // Reset the state
          yield put(SwipeFileReducerAction.setSelectedDeleteAdId(""));
          yield put(SwipeFileReducerAction.setDeleteAdLoading(false));
          yield put(SwipeFileReducerAction.setDeleteAdsModal(false));

          // Refetch Swipe File Items after deleting Ad
          yield put(SwipeFileReducerAction.setIsLoading(true));
          yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
          yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));

          const data: FilterOptions = yield select(
            swipefileFilterOptionSelector
          );

          const reFetchSwipeFileItems: AxiosResponse = yield call(
            getSwipeFileAdsList,
            data
          );
          if (reFetchSwipeFileItems?.status === 200) {
            yield put(
              SwipeFileReducerAction.setSwipeFileItems(
                reFetchSwipeFileItems?.data
              )
            );
            yield put(
              SwipeFileReducerAction.setFilterOptionSkipValue(
                data.skip + data.limit
              )
            );
            yield put(SwipeFileReducerAction.setIsLoading(false));
          }
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *fetchSelectedBrandSwipeFileItems(action: {
      payload: {
        brandId: string;
      };
      type: string;
    }) {
      try {
        yield put(SwipeFileReducerAction.setIsLoading(true));
        yield put(
          SwipeFileReducerAction.setFilterOptionBrandId(action.payload.brandId)
        );
        yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
        yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
        const data: FilterOptions = yield select(swipefileFilterOptionSelector);

        const response: AxiosResponse = yield call(getSwipeFileAdsList, data);
        if (response?.status === 200) {
          yield put(SwipeFileReducerAction.setSwipeFileItems(response?.data));
          yield put(
            SwipeFileReducerAction.setFilterOptionSkipValue(
              data.skip + data.limit
            )
          );
          yield put(SwipeFileReducerAction.setSwipeFileTabIndex(0));
          yield put(SwipeFileReducerAction.setIsLoading(false));
          window.scrollTo(0, 0);
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *addMultipleBoardsToMultipleAds(action: {
      payload: {
        adIds: string[];
        boardIds: string[];
      };
      type: string;
    }) {
      try {
        yield put(SwipeFileReducerAction.setOpenAddBoardModalLoading(true));
        const response: AxiosResponse = yield call(
          AddMultipleBoardsToAdsService,
          action.payload
        );
        if (response?.status === 200) {
          let boardItems: BoardItem[] = yield select(boardItemsSelector);
          const updatedBoardList = boardItems.filter((item) => {
            if (action.payload.boardIds.includes(item._id)) {
              return { _id: item._id, name: item.name };
            }
          });
          yield put(
            SwipeFileReducerAction.setUpdatedBoardsToMultipleAds({
              adIds: action.payload.adIds,
              boardIds: updatedBoardList,
            })
          );
          yield put(
            SwipeFileReducerAction.setSelectedTableItemsInitialState([])
          );
          yield put(SwipeFileReducerAction.setOpenAddBoardModalLoading(false));
          yield put(SwipeFileReducerAction.setOpenAddBoardModal(false));
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setOpenAddBoardModalLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *deleteMultipleAds() {
      try {
        yield put(SwipeFileReducerAction.setDeleteAdLoading(true));

        const adIds: string[] = yield select(selectedTableItemsSelector);

        const response: AxiosResponse = yield call(
          deleteMultipleAdService,
          adIds
        );
        if (response?.status === 200) {
          // Reset the state
          yield put(
            SwipeFileReducerAction.setSelectedTableItemsInitialState([])
          );
          yield put(SwipeFileReducerAction.setDeleteAdLoading(false));
          yield put(SwipeFileReducerAction.setDeleteAdsModal(false));

          // Refetch Swipe File Items after deleting Ad
          yield put(SwipeFileReducerAction.setIsLoading(true));
          yield put(SwipeFileReducerAction.setFilterOptionSkipValue(0));
          yield put(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));

          const data: FilterOptions = yield select(
            swipefileFilterOptionSelector
          );

          const reFetchSwipeFileItems: AxiosResponse = yield call(
            getSwipeFileAdsList,
            data
          );
          if (reFetchSwipeFileItems?.status === 200) {
            yield put(
              SwipeFileReducerAction.setSwipeFileItems(
                reFetchSwipeFileItems?.data
              )
            );
            yield put(
              SwipeFileReducerAction.setFilterOptionSkipValue(
                data.skip + data.limit
              )
            );
            yield put(SwipeFileReducerAction.setIsLoading(false));
          }
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setIsLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
    *addMultipleTagsToMultipleAds(action: {
      payload: {
        adIds: string[];
        tagIds: string[];
      };
      type: string;
    }) {
      try {
        yield put(SwipeFileReducerAction.setOpenAddTagModalLoading(true));
        const response: AxiosResponse = yield call(
          AddMultipleTagsToAdsService,
          action.payload
        );
        if (response?.status === 200) {
          let boardItems: tagItem[] = yield select(TagsListsSelector);
          const updatedTagList = boardItems.filter((item) => {
            if (action.payload.tagIds.includes(item._id)) {
              return { _id: item._id, name: item.name };
            }
          });
          yield put(
            SwipeFileReducerAction.setUpdatedTagsToMultipleAds({
              adIds: action.payload.adIds,
              tagIds: updatedTagList,
            })
          );
          yield put(
            SwipeFileReducerAction.setSelectedTableItemsInitialState([])
          );
          yield put(SwipeFileReducerAction.setOpenAddTagModalLoading(false));
          yield put(SwipeFileReducerAction.setOpenAddTagModal(false));
        }
      } catch (error) {
        yield put(SwipeFileReducerAction.setOpenAddTagModalLoading(false));
        Sentry.captureException(error);
        throw new Error();
      }
    },
  },
  sagaType: "takeEvery",
});

export const {
  actions: SwipeFileSagaActions,
  saga: SwipeFileSaga,
  name: SwipeFileSagaName,
} = saga;
