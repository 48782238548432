import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.tagsState || initialState;

export const tagStateSelector = createSelector(selectDomain, (state) => state);

export const selectedTagItemsSelector = createSelector(
  selectDomain,
  (state) => state.selectedTagItems
);

export const selectNewTageValueSelector = createSelector(
  selectDomain,
  (state) => state.newTagValue
);

export const tagsLoadingSelector = createSelector(
  selectDomain,
  (state) => state.loading
);

export const openTagsDropdownSelector = createSelector(
  selectDomain,
  (state) => state.openTagsDropdown
);
