import { createSlice } from "@reduxjs/toolkit";
import { StarredStateTypes, starredItem } from "./types";

export const initialState: StarredStateTypes = {
  starredTabIndex: 0,
  loadingStarredList: false,
  starredList: {
    data: [],
    total: 0,
  },
  starredListsParams: {
    skip: 0,
    limit: 10,
    search: "",
  },
};

const starred = createSlice({
  name: "starredState",
  initialState,
  reducers: {
    setStarredTabIndex(state, action) {
      state.starredTabIndex = action.payload;
    },
    setStarredList(state, action) {
      state.starredList.total = action.payload.total;
      state.starredList.data = [
        ...state.starredList.data,
        ...action.payload.data,
      ];
    },
    setStarredListParams(state, action) {
      state.starredListsParams = {
        ...state.starredListsParams,
        skip: action.payload,
      };
    },
    setLoadingStarredList(state, action) {
      state.loadingStarredList = action.payload;
    },
    setResetInitialSet(state, action) {
      state.starredList.total = 0;
      state.starredList.data = [];
      state.starredListsParams = {
        limit: 10,
        skip: 0,
        search: "",
      };
      state.loadingStarredList = false;
    },
    setUpdatedBoardIds(state, action) {
      const updatedSwipeFileItemData = state.starredList.data.map(
        (item: starredItem) => {
          if (item._id === action.payload.adId) {
            return {
              ...item,
              board: action.payload.updatedBoardList,
            };
          } else {
            return item;
          }
        }
      );
      state.starredList.data = updatedSwipeFileItemData;
    },
  },
});

export const {
  actions: StarredReducerAction,
  name: StarredReducerName,
  reducer: StarredReducer,
} = starred;
