import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) => state.swipeFileState || initialState;

export const swipeFileListSelector = createSelector(
  selectDomain,
  (state) => state.swipeFileListItems
);

export const selectedTableItemsSelector = createSelector(
  selectDomain,
  (state) => state.selectedTableItems
);

export const swipeFileViewTypeSelector = createSelector(
  selectDomain,
  (state) => state.viewType
);
export const swipefileSelectedSortOptionSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.sortValue
);

export const swipeFileTabIndexSelector = createSelector(
  selectDomain,
  (state) => state.swipeFileTabIndex
);

export const swipeFileTopBrandsSelector = createSelector(
  selectDomain,
  (state) => state.swipeFileTopBrandLists
);

export const swipefileFilterOptionSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions
);

export const swipeFileSelectedBrandsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPageIds
);

export const swipeFileSelectedPlatformsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedPlatformIds
);

export const swipeFileSelectedBoardsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedBoardIds
);

export const swipeFileBrandsParamsSelector = createSelector(
  selectDomain,
  (state) => state.swipeFileBrandsParams
);

export const swipeFilePageSearchSelector = createSelector(
  selectDomain,
  (state) => state.search
);
export const swipeFilesFavouriteBrandFilterSelector = createSelector(
  selectDomain,
  (state) => state.swipeFilesFavouriteBrandFilterEnabled
);

export const swipeFileLoadingSelector = createSelector(
  selectDomain,
  (state) => state.isLoading
);

export const swipeFileBrandsLoadingSelector = createSelector(
  selectDomain,
  (state) => state.swipeFileBrandsLoading
);

export const swipeFileFilterCountsSelector = createSelector(
  selectDomain,
  (state) => {
    const {
      selectedPlatformIds,
      selectedPageIds,
      selectedBoardIds,
      selectedTagIds,
    } = state.filterOptions;
    return (
      selectedPlatformIds.length +
      selectedPageIds.length +
      selectedBoardIds.length +
      selectedTagIds.length
    );
  }
);

export const selectedSidebarBoardIdSelector = createSelector(
  selectDomain,
  (state) => state.selectedSidebarBoardId
);

export const openDeleteAdsModalSelector = createSelector(
  selectDomain,
  (state) => state.openDeleteAdsModal
);

export const selectedDeleteAdIdSelector = createSelector(
  selectDomain,
  (state) => state.selectedDeleteAdId
);

export const deleteAdLoadingSelector = createSelector(
  selectDomain,
  (state) => state.deleteAdLoading
);

export const swipeFileSelectedTagsSelector = createSelector(
  selectDomain,
  (state) => state.filterOptions.selectedTagIds
);

export const openAddBoardModalSelector = createSelector(
  selectDomain,
  (state) => state.openAddBoardModal
);

export const openAddBoardModalLoadingSelector = createSelector(
  selectDomain,
  (state) => state.openAddBoardModalLoading
);

export const openAddTagModalSelector = createSelector(
  selectDomain,
  (state) => state.openAddTagModal
);

export const openAddTagModalLoadingSelector = createSelector(
  selectDomain,
  (state) => state.openAddTagModalLoading
);
