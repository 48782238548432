import { DownloadIcon } from "../../assets/Icons";
import { SettingsSectionWrapper1, SettingsSectionWrapper2 } from "../../pageComponents/settings/style";
import {
  InvoiceButton,
  PlanDescription,
  PlanDetailWrapper,
  PlanDetailsWrapper,
  PlanName,
  PlanPrice,
  PlanType,
  Wrapper,
} from "../planDetailsSection/style";

const PaymentHistory = () => {
  return (
    <SettingsSectionWrapper1>
      <SettingsSectionWrapper2>
        {Array.from({ length: 3 }, (_, index) => (
          <Wrapper key={index}>
            <PlanDetailsWrapper>
              <PlanDetailWrapper>
                <PlanName>Enterprise Plan Yearly</PlanName>
                <PlanDescription>Plan purchased on 15-06-24.</PlanDescription>
                <PlanPrice weight={400} size="0.875rem">
                  <PlanPrice weight={700} size="1rem">
                    $999
                  </PlanPrice>{" "}
                  /month
                </PlanPrice>
              </PlanDetailWrapper>

              <InvoiceButton>
                <DownloadIcon />
                Invoice
              </InvoiceButton>
            </PlanDetailsWrapper>
          </Wrapper>
        ))}
      </SettingsSectionWrapper2>
    </SettingsSectionWrapper1>
  );
};

export default PaymentHistory;
