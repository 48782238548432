import { useState } from "react";
import {
  BulkUploadWrapper,
  DragDropContainer,
  FontGrayNormal,
  StyledContainedButton,
} from "./style";
import { CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { CloudUploadIcon, ImageIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { useDropzone } from "react-dropzone";
import SelectBoardDropdown from "../commonComponents/selectBoardsDropdown";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { openSaveNewAdLoaderSelector } from "../../store/metadata/selector";
import { ToastTypes, notify } from "../../utils/helpers";
import { MetaDataReducerAction } from "../../store/metadata/slice";

const BulkUploadModal = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [selectBoardIds, setSelectBoardIds] = useState<string[]>([]);
  const loading = useSelector(openSaveNewAdLoaderSelector);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const validFileTypes = ["video/mp4", "image/jpeg", "image/png"];
      if (validFileTypes.includes(acceptedFiles[0].type)) {
        setUploadedFile(acceptedFiles[0]);
        return;
      }
      notify({
        message: "Only mp4, jpeg or png format media is allowed",
        type: ToastTypes.ERROR,
      });
      return;
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleSubmit = () => {
    const formData = new FormData();

    selectBoardIds.map((boardId, index) => {
      formData.append(`boardIds[${index}]`, boardId);
    });

    if (uploadedFile) {
      formData.append("file", uploadedFile);
    }

    dispatch(
      MetaDataSagaActions.manualUploadAd({
        body: formData,
      })
    );
  };

  const handleUpdateBoard = (
    selectedBoardIds: string[],
    removedBoardId: string[]
  ) => {
    setSelectBoardIds(selectedBoardIds);
    dispatch(MetaDataReducerAction.setOpenBoardDropdown("false"));
  };

  return (
    <BulkUploadWrapper>
      <Typography fontSize="1rem" fontWeight="500" color={theme.colors.gray1}>
        Use this function to upload your own content to Swipe Lounge for use in
        Boards.
      </Typography>
      <DragDropContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <CloudUploadIcon />
        <Typography
          fontSize="1.125rem"
          fontWeight="600"
          color={theme.colors.black2}
          margin="1.25rem 0 0.625rem"
        >
          Upload Content
        </Typography>
        <FontGrayNormal>
          Drag and drop your .mp4, .png or .jpeg file
        </FontGrayNormal>
      </DragDropContainer>

      {uploadedFile && (
        <Grid container spacing={2.5} sx={{ marginBottom: "1.875rem" }}>
          <Grid item sm={6}>
            <FontGrayNormal>Asset</FontGrayNormal>
          </Grid>
          <Grid item sm={6}>
            <FontGrayNormal>Add to Board</FontGrayNormal>
          </Grid>

          <>
            <Grid item sm={6}>
              <Stack direction="row" gap="0.625rem" alignItems="center">
                <ImageIcon />
                <Typography
                  fontSize="1rem"
                  fontWeight="500"
                  color={theme.colors.black2}
                  sx={{
                    overflowWrap: "break-word"
                  }}
                  maxWidth="20rem"
                >
                  {uploadedFile.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item sm={6}>
              <SelectBoardDropdown
                showSelectedItemCount={2}
                selectedBoards={[]}
                handleUpdateBoard={handleUpdateBoard}
              />
            </Grid>
          </>
        </Grid>
      )}
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          float: "right",
          padding: "0.313rem 2.688rem",
        }}
        onClick={handleSubmit}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        Done
      </StyledContainedButton>
    </BulkUploadWrapper>
  );
};

export default BulkUploadModal;
