import {
  ButtonText,
  HamburgerMenu,
  HeaderButton1,
  HeaderButton2,
  HeaderLeftMenuSection,
  HeaderMenuSection,
  HeaderRightMenuSection,
  HeaderSubTitle,
  HeaderTitle,
  HeaderTitleSection,
  NotificationBadge,
} from "../../pageComponents/style";
import {
  CreateNewIcon,
  MenuIcon,
  MenuOpenIcon,
  NotificationIcon,
  TutorialIcon,
} from "../../assets/Icons";
import useConfig from "../../hooks/useConfig";
import { Avatar, Badge, Box } from "@mui/material";
import ProfilePopover from "../profilePopover";
import { useState } from "react";
import CreateNewPopover from "../createNewPopover";
import { HeaderProps } from "../../utils/propTypes";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "../../store/account/selector";
import TutorialDrawer from "../tutorialDrawer";
import { useTheme } from "styled-components";
import NotificationPopover from "../notificationPopover";
import { checkNotificationStatusSelector } from "../../store/metadata/selector";

const Header = ({ content, hideCreateButton }: HeaderProps) => {
  const theme = useTheme();

  const { drawerOpen, onChangeDrawer } = useConfig();
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] =
    useState<Element | null>(null);
  const [createNewMenuAnchorEl, setCreateNewMenuAnchorEl] =
    useState<Element | null>(null);
  const [openTutorialDrawer, setOpenTutorialDrawer] = useState(false);
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] =
    useState<Element | null>(null);

  const userDetails = useSelector(userDetailsSelector);

  const checkNotificationStatus = useSelector(checkNotificationStatusSelector);

  const handleSidebarDrawer = () => {
    onChangeDrawer(!drawerOpen);
  };

  const handleCloseTutorialDrawer = () => {
    setOpenTutorialDrawer(false);
  };

  return (
    <>
      <HeaderMenuSection>
        <HeaderLeftMenuSection>
          <HamburgerMenu onClick={() => handleSidebarDrawer()}>
            {drawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </HamburgerMenu>
          <HeaderTitleSection>
            <HeaderTitle>{content?.tabTitle}</HeaderTitle>
            <HeaderSubTitle>{content?.tabSubTitle}</HeaderSubTitle>
          </HeaderTitleSection>
        </HeaderLeftMenuSection>
        <HeaderRightMenuSection>
          <HeaderButton1
            variant="outlined"
            size="small"
            onClick={() => setOpenTutorialDrawer(true)}
          >
            <TutorialIcon />
            <ButtonText>
              Tutorial
            </ButtonText>
          </HeaderButton1>
          {!hideCreateButton && (
            <HeaderButton2
              variant="contained"
              size="small"
              onClick={(event) => setCreateNewMenuAnchorEl(event.currentTarget)}
            >
              <CreateNewIcon fill="white" />
              <ButtonText>Create New</ButtonText>
            </HeaderButton2>
          )}
          <Box
            sx={{
              ":hover": {
                background: theme.colors.background4,
                cursor: "pointer",
                position: "relative",
              },
            }}
            onClick={(event) =>
              setNotificationMenuAnchorEl(event.currentTarget)
            }
          >
            {checkNotificationStatus && (
              <NotificationBadge
                variant="dot"
                margin="0.400rem 0.875rem 0 0"
              />
            )}
            <NotificationIcon />
          </Box>
          <Avatar
            sx={{ width: "3.143rem", height: "3.143rem", cursor: "pointer" }}
            src={
              userDetails?.profileImage
                ? userDetails?.profileImage
                : "assests/images/user-logo.png"
            }
            onClick={(event) => setProfileMenuAnchorEl(event.currentTarget)}
          />
        </HeaderRightMenuSection>
      </HeaderMenuSection>
      <ProfilePopover
        anchorEl={profileMenuAnchorEl}
        handlePopoverClose={() => setProfileMenuAnchorEl(null)}
      />
      <CreateNewPopover
        anchorEl={createNewMenuAnchorEl}
        handlePopoverClose={() => setCreateNewMenuAnchorEl(null)}
      />
      <TutorialDrawer
        open={openTutorialDrawer}
        handleClose={handleCloseTutorialDrawer}
      />
      <NotificationPopover
        anchorEl={notificationMenuAnchorEl}
        handlePopoverClose={() => setNotificationMenuAnchorEl(null)}
      />
    </>
  );
};

export default Header;
