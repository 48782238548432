import * as qs from "qs";
import axiosClient from "../apiClient";

export const fetchBrandsListService = async (params?: {
  search?: string;
  userId?: string;
}) => {
  try {
    const filterObject = {
      ...(params &&
        params?.search && { search: encodeURIComponent(params?.search) }),
      ...(params && params.userId && { userId: params.userId }),
    };
    const queryString = qs.stringify(filterObject);
    const apiUrl = queryString ? `/brand?${queryString}` : `/brand`;
    const response = await axiosClient.get(apiUrl);
    return response;
  } catch (error) {
    return error;
  }
};

export const TopBrandLists = async ({
  skip,
  limit,
  userId,
  search,
  favourite,
}: {
  skip: number;
  limit: number;
  userId?: string;
  search?: string;
  favourite?: boolean;
}) => {
  try {
    const filterObject = {
      skip,
      limit,
      ...(userId && { userId }),
      ...(search && { search: encodeURIComponent(search) }),
      ...(favourite && { favourite: true }),
    };
    const queryString = qs.stringify(filterObject);
    const response = await axiosClient.get(
      `/brand/list/top-list?${queryString}`
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const addBrandToFavouriteService = async (requestPayload: {
  brandId: string;
}) => {
  try {
    const response = await axiosClient.post("/brand/favourite", requestPayload);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFavouriteBrands = async () => {
  try {
    const response = await axiosClient.get(
      `/brand/list/top-list?favourite=true`
    );
    return response;
  } catch (error) {
    return error;
  }
};
