import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ArrowDownIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { ReactNode, useState } from "react";

const FavouriteBrandContainer = ({
  children,
  defaultExpanded,
  headerName
}: {
  children: ReactNode;
  defaultExpanded?: boolean;
  headerName?: string;
}) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(defaultExpanded);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={{
        "&.Mui-expanded": { m: 0 },
        boxShadow: "none",
        borderBottom: `1px solid ${theme.colors.border1}`,
        padding: "0 1rem",
      }}
    >
      <AccordionSummary expandIcon={<ArrowDownIcon />}>
        <Typography
          fontSize="1.25rem"
          color={theme.colors.black2}
          fontWeight={600}
        >
          {headerName ? headerName : 'Favourite Brands'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          gap: "1.25rem",
          display: "flex",
          justifyContent: "space-between",
          // flexDirection: "column",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default FavouriteBrandContainer;
