import { MenuPopoverProps } from "../../types/Popover";
import {
  Avatar,
  CircularProgress,
  Divider,
  Menu,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "styled-components";
import {
  HelpBlackIcon,
  LogoutIcon,
  SaveIcon,
  SettingsIcon,
} from "../../assets/Icons";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import routeConstant from "../../utils/routeConstant";
import { useSelector } from "react-redux";
import { userDetailsSelector } from "../../store/account/selector";
import { logoutService } from "../../services/AuthServices";
import { AxiosResponse } from "axios";

const ItemRow = ({
  children,
  handleClick,
}: {
  children: React.ReactNode;
  handleClick?: () => void;
}) => {
  return (
    <Stack
      direction="row"
      sx={{ alignItems: "center", gap: "0.625rem" }}
      onClick={handleClick}
    >
      {children}
    </Stack>
  );
};

const StyledText = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="0.938rem"
      fontWeight="500"
      color={theme.colors.black2}
      style={{
        cursor: "pointer",
      }}
    >
      {children}
    </Typography>
  );
};

const ProfilePopover = ({ anchorEl, handlePopoverClose }: MenuPopoverProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthStatus } = useContext(AuthContext);

  const handleLogout = async () => {
    setLoading(true);
    const response = (await logoutService()) as AxiosResponse;
    if (response?.status === 200) {
      setAuthStatus(false);
      localStorage.clear();
      setLoading(false);
      navigate(`/${routeConstant.login}`, {
        replace: true,
      });
    } else {
      setLoading(false);
    }
  };

  const userDetails = useSelector(userDetailsSelector);

  const handleSettings = () => {
    navigate(`/${routeConstant.settings}`);
  };

  const handleSavedAds = () => {
    navigate(routeConstant.swipeFiles, {
      replace: true,
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "0.938rem",
          width: "17.375rem",
        },
        ".MuiList-root": {
          display: "flex",
          flexDirection: "column",
          gap: "0.938rem",
          padding: "0.5rem 0",
        },
      }}
    >
      <ItemRow>
        <Avatar
          src={
            userDetails?.profileImage
              ? userDetails?.profileImage
              : "assests/images/user-logo.png"
          }
        />
        <Stack>
          <Typography
            fontSize="1rem"
            fontWeight="600"
            color={theme.colors.black2}
          >
            {userDetails?.fullName}
          </Typography>
          <Typography
            fontSize="0.75rem"
            fontWeight="400"
            color={theme.colors.gray1}
          >
            {userDetails?.email}
          </Typography>
        </Stack>
      </ItemRow>
      <Divider sx={{ marginInline: "-0.938rem" }} />
      <ItemRow handleClick={handleSettings}>
        <SettingsIcon />
        <StyledText>Settings</StyledText>
      </ItemRow>
      <ItemRow handleClick={handleSavedAds}>
        <SaveIcon />
        <StyledText>Saved Ads</StyledText>
      </ItemRow>
      {/* <ItemRow>
        <HelpBlackIcon />
        <StyledText>Help</StyledText>
      </ItemRow> */}
      <Divider sx={{ marginInline: "-0.938rem" }} />
      <ItemRow handleClick={handleLogout}>
        {loading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <LogoutIcon />
        )}
        <StyledText>Logout</StyledText>
      </ItemRow>
    </Menu>
  );
};

export default ProfilePopover;
