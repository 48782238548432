import axios from "axios";
import axiosClient from "../apiClient";
import { ResetPasswordParamType } from "../types/accountSettingsTypes";

export const UpdateUserDetails = async (params: any) => {
  try {
    const id = params.id;
    const reqBody = params.body;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: localStorage.getItem("AccessToken"),
      },
    };
    const response = await axios.put(
      `${process.env.REACT_APP_API_BASE_URL}/users/${id}`,
      reqBody,
      config
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const FetchUserDetail = async (userId: string) => {
  try {
    const response = await axiosClient.get(`/users/${userId}`);
    return response;
  } catch (error) {
    return error;
  }
};

export const ResetPassword = async ({
  params,
  id,
  handleResetState,
}: {
  params: ResetPasswordParamType;
  id: string;
  handleResetState: Function;
}) => {
  try {
    const response = await axiosClient.put(
      `/users/change-password/${id}`,
      params
    );
    return response;
  } catch (error) {
    handleResetState();
    return error;
  }
};
