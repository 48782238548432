import { Box, CircularProgress, Menu, Stack, Typography } from "@mui/material";
import { MenuPopoverProps } from "../../types/Popover";
import {
  ChangeEvent,
  KeyboardEventHandler,
  memo,
  useMemo,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { CheckMarkIcon } from "../../assets/Icons";
import FilterSearchbox from "../commonComponents/filterSearchbox";
import {
  tagStateSelector,
  tagsLoadingSelector,
} from "../../store/tags/selector";
import { useDispatch, useSelector } from "react-redux";
import { TagsReducerAction } from "../../store/tags/slice";
import { TagsListsSelector } from "../../store/metadata/selector";
import { TagsSagaActions } from "../../store/tags/sagas";
import { tagItem } from "../../store/metadata/types";

const AddTagPopover = ({
  anchorEl,
  handlePopoverClose,
  item,
}: MenuPopoverProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [selectedTagId, setSelectedTagId] = useState("");
  const { newTagValue } = useSelector(tagStateSelector);

  const tagsList = useSelector(TagsListsSelector);
  const tagsLoading = useSelector(tagsLoadingSelector);

  const handleChangeTag = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(TagsReducerAction.setNewTagValue(event.target.value));
  };

  const handleAddTag: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") dispatch(TagsSagaActions.createTag(item?._id));
  };

  const addRemoveTag = (tagId: string) => {
    setSelectedTagId(tagId);
    dispatch(TagsSagaActions.addOrRemoveTagsFromAd({ tagId, adId: item?._id }));
  };

  const selectedTagsId = useMemo(
    () => item?.Tags?.map((ele) => ele._id),
    [item?.Tags]
  );

  const getIcon = (itemId: string) => {
    if (tagsLoading && selectedTagId === itemId) {
      return <CircularProgress size={15} />;
    } else if (
      tagsLoading &&
      selectedTagId === itemId &&
      selectedTagsId?.includes(itemId)
    ) {
      return <CircularProgress size={15} />;
    } else if (selectedTagsId?.includes(itemId)) {
      return <CheckMarkIcon />;
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "1.375rem 1.25rem 1.25rem 1.25rem",
          maxHeight: "15rem",
        },
        ".MuiList-root": {
          padding: "0",
          display: "flex",
          flexDirection: "column",
          gap: "0.938rem",
        },
      }}
    >
      <FilterSearchbox
        placeholder="Type..."
        isTextField={true}
        handleChange={handleChangeTag}
        handleKeyDown={handleAddTag}
        value={newTagValue}
      />
      {tagsList.map((ele: tagItem) => (
        <Stack
          key={ele._id}
          direction="row"
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => addRemoveTag(ele._id)}
        >
          <Typography
            fontSize="0.938rem"
            fontWeight="500"
            color={theme.colors.black2}
          >
            {ele.name}
          </Typography>
          {getIcon(ele._id)}
        </Stack>
      ))}
    </Menu>
  );
};

export default memo(AddTagPopover);
