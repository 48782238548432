import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { AdDrawerStyledButton } from "./style";
import { FileIcon, NavigatePrevIcon } from "../../../assets/Icons";
import { StyledContainedButton } from "../../modals/style";
import { useTheme } from "styled-components";
import { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { DiscoveryItem } from "../../../store/discovery/types";
import { MetaDataSagaActions } from "../../../store/metadata/sagas";
import SaveTranscriptModal from "../../modals/saveTranscriptModal";
import { useNavigate } from "react-router-dom";

const AdDrawerHeader = ({
  libraryId,
  dataList,
  adId,
  transcriptObject,
}: {
  libraryId: string;
  dataList: DiscoveryItem[];
  adId: string;
  transcriptObject: boolean;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLoading = () => {
    setLoading(false);
  };

  const handleGenerateTranscript = () => {
    setLoading(true);
    dispatch(
      MetaDataSagaActions.generateTranscript({
        adId: adId,
        handleLoadingState: handleLoading,
      })
    );
  };

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding="1.875rem"
      >
        <Stack direction="row" alignItems="center" gap="0.625rem">
          <AdDrawerStyledButton onClick={() => navigate(-1)}>
            <NavigatePrevIcon />
            Back
          </AdDrawerStyledButton>
        </Stack>
        {libraryId && (
          <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <Typography
              fontSize="1.5rem"
              fontWeight="600"
              color={theme.colors.black2}
              sx={{ display: "inline-block" }}
            >
              Ad Details:
            </Typography>
            <Typography
              fontSize="1.5rem"
              fontWeight="500"
              color={theme.colors.lightBlue}
              sx={{ display: "inline-block" }}
            >
              ID {libraryId}
            </Typography>
          </Box>
        )}

        <Stack direction="row" alignItems="center" gap="0.625rem">
          {/* // TODO: may needed in future. */}
          {/* <AdDrawerStyledButton>  
          <CodeBlockIcon />
          Embed
        </AdDrawerStyledButton>
        <StyledContainedButton sx={{ paddingInline: "0.938rem", gap: "0.25rem" }}>
          <UploadIcon color="white" />
          Share
        </StyledContainedButton> */}
          {transcriptObject && (
            <StyledContainedButton
              sx={{ paddingInline: "0.938rem", gap: "0.25rem" }}
              onClick={handleGenerateTranscript}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <FileIcon color={"white"} />
              )}
              Generate Transcript
            </StyledContainedButton>
          )}
        </Stack>
      </Stack>
      <SaveTranscriptModal adId={adId} />
    </>
  );
};

export default memo(AdDrawerHeader);
