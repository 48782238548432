import {
  PlanDescription,
  PlanDetailWrapper,
  PlanDetailsWrapper,
  PlanName,
  PlanPrice,
  PlanType,
  UpgradePlanButton,
  Wrapper,
} from "./style";
import { useSelector } from "react-redux";
import { activePlanSelector } from "../../store/account/selector";
import routeConstant from "../../utils/routeConstant";
import { useNavigate } from "react-router-dom";

const PlanSection = () => {
  // Hooks
  const navigate = useNavigate();

  // Selectors
  const selectedPlan = useSelector(activePlanSelector);

  // Functions
  const handleRedirect = () => {
    navigate(`/${routeConstant.subscription}`);
  };

  return (
    <>
      {Object.keys(selectedPlan).length > 0 && (
        <Wrapper>
          <PlanType>Active Plan</PlanType>
          <PlanDetailsWrapper>
            <PlanDetailWrapper>
              <PlanName>{selectedPlan?.name}</PlanName>
              <PlanDescription>{selectedPlan?.description}</PlanDescription>
              {selectedPlan?.amount !== 0 && selectedPlan?.amount && (
                <PlanPrice weight={400} size="0.875rem">
                  <PlanPrice weight={700} size="1rem">
                    ${selectedPlan?.amount}
                  </PlanPrice>
                  / month
                </PlanPrice>
              )}
            </PlanDetailWrapper>
            <UpgradePlanButton onClick={handleRedirect}>
              Upgrade Plan
            </UpgradePlanButton>
          </PlanDetailsWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default PlanSection;
