import "./sentry";
import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { Flip, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import { MuiTheme, theme } from "./utils/theme";
import { Provider } from "react-redux";
import { store } from "./store";
import { AuthProvider } from "./context/AuthContext";
import { ConfigProvider } from "./context/ConfigContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import NotificationCloseButton from "./components/commonComponents/NotificationCloseButton";
import Maintenance from "./components/maintenance";


ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <Sentry.ErrorBoundary fallback={<Maintenance />}>
          <Provider store={store}>
            <BrowserRouter>
              <GoogleOAuthProvider
                clientId={process.env.REACT_APP_API_CLIENT_ID as string}
              >
                <AuthProvider>
                  <ConfigProvider>
                    <Routes>
                      <Route path="/*" element={<App />} />
                    </Routes>
                  </ConfigProvider>
                </AuthProvider>
              </GoogleOAuthProvider>
            </BrowserRouter>
            <ToastContainer
              autoClose={3000}
              newestOnTop={true}
              position="top-center"
              pauseOnHover
              theme="light"
              transition={Flip}
              limit={2}
              hideProgressBar
              closeButton={NotificationCloseButton}
            />
          </Provider>
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>
);
