// TODO: commented code needed in future.
import { useEffect, useMemo } from "react";
import { Container, HeaderSection, HeaderTitle, ListContainer } from "../style";
import { starredTabs } from "../../utils/constants";
import Header from "../../components/header";
import TabsHeader from "../../components/tabsHeader";
import AdCard from "../../components/commonComponents/adCard";
import CreateBoardModal from "../../components/modals/createBoardModal";
import { useDispatch, useSelector } from "react-redux";
import { DiscoveryItem } from "../../store/discovery/types";
import SaveAdPopover from "../../components/saveAdPopover";
import { Box, CircularProgress } from "@mui/material";
import CardListWrapper from "../../components/commonComponents/adCard/cardListWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  StarredReducer,
  StarredReducerAction,
  StarredReducerName,
} from "../../store/starred/slice";
import {
  loadingStarredListSelector,
  starredListSelector,
  starredTabIndexSelector,
} from "../../store/starred/selector";
import {
  StarredSaga,
  StarredSagaActions,
  StarredSagaName,
} from "../../store/starred/sagas";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { BoardItem } from "../../store/metadata/types";
import { useLocation } from "react-router-dom";

const Starred = () => {
  useInjectSaga({
    saga: StarredSaga,
    key: StarredSagaName,
  });

  useInjectReducer({
    reducer: StarredReducer,
    key: StarredReducerName,
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const starredAdItems = useSelector(starredListSelector);
  const isLoading = useSelector(loadingStarredListSelector);
  const starredTabIndex = useSelector(starredTabIndexSelector);

  const content = {
    tabTitle: "Starred Ads",
    tabSubTitle: ` ${
      starredAdItems?.total
        ? `Browse ${starredAdItems?.total}+ starred ads saved by you`
        : "No starred ads saved by you"
    } `,
  };

  useEffect(() => {
    dispatch(MetaDataSagaActions.fetchBoardList());
    // dispatch(MetaDataSagaActions.fetchBrandLists());
    dispatch(StarredSagaActions.fetchStarredAds());
    dispatch(MetaDataSagaActions.fetchTagLists());

    return () => {
      dispatch(StarredReducerAction.setResetInitialSet([]));
    };
  }, [dispatch]);

  const setStarredTabIndex = (tabIndex: number) => {
    dispatch(StarredReducerAction.setStarredTabIndex(tabIndex));
  };

  const fetchMoreStarredAdItem = () => {
    dispatch(StarredSagaActions.fetchStarredAds());
  };

  const refetchStarredFileItem = (adId: string, updatedBoardList: BoardItem) => {
    dispatch(
      StarredReducerAction.setUpdatedBoardIds({ adId, updatedBoardList })
    );
  };

  const RenderAdsComponent = useMemo(() => {
    const starredAdsLength = starredAdItems?.data?.length ?? 0;
    const hasMoreData = starredAdItems?.total > starredAdsLength;

    if (!isLoading && starredAdsLength === 0) {
      return (
        <ListContainer>
          <HeaderTitle textAlign="center">
            Your starred Ads will appear here
          </HeaderTitle>
        </ListContainer>
      );
    }

    return (
      <>
        <ListContainer>
          <CardListWrapper>
            {starredAdItems?.data?.map((item: DiscoveryItem, index: number) => (
              <AdCard
                item={item}
                key={item._id}
                index={index}
                dataList={starredAdItems?.data}
                locationPathname={location.pathname}
              />
            ))}
            {(isLoading || hasMoreData) &&
              [...Array(4)]?.map((index) => (
                <AdCard item={{} as DiscoveryItem} key={index} />
              ))}
          </CardListWrapper>
          <InfiniteScroll
            dataLength={starredAdsLength}
            next={fetchMoreStarredAdItem}
            hasMore={hasMoreData}
            loader={
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            }
            style={{ overflow: "hidden" }}
            key={starredAdItems?.data?.length}
          >
            <></>
          </InfiniteScroll>
        </ListContainer>
      </>
    );
  }, [starredAdItems?.data, starredAdItems?.total, isLoading]);

  const starredSections: {
    [key: number]: JSX.Element;
  } = {
    0: <>{RenderAdsComponent}</>,
  };

  return (
    <Container>
      <HeaderSection>
        <Header content={content} />
        <TabsHeader
          tabIndex={starredTabIndex}
          setTabIndex={setStarredTabIndex}
          data={starredTabs}
        />
        {starredSections[starredTabIndex]}
      </HeaderSection>
      <SaveAdPopover reFetchData={refetchStarredFileItem}/>
      <CreateBoardModal />
    </Container>
  );
};

export default Starred;
