import { createSelector } from "@reduxjs/toolkit";
import { initialState } from "./slice";

const selectDomain = (state: any) =>
  state.dashboardAnalyticsState || initialState;

export const dashboardAnalyticsSelector = createSelector(
  selectDomain,
  (state) => state.dashboardAnalytics
);

export const dashboardLoadingSelector = createSelector(
  selectDomain,
  (state) => state.dashboardLoading
);

export const dashboardAdAnalyticsSelector = createSelector(
  selectDomain,
  (state) => state.dashboardAdAnalytics
);

export const openViewTranscriptModalSelector = createSelector(
  selectDomain,
  (state) => state.openViewTranscriptModal
);

export const recommendedPlansSelector = createSelector(
  selectDomain,
  (state) => state.recommendedPlans
);

export const teamMemberSavedAdsSelector = createSelector(
  selectDomain,
  (state) => state.teamMemberSavedAds
);
