import FavouriteBrandContainer from "./favouriteBrandContainer";
import TopBrandCard from "../commonComponents/brandCards/topBrandCard";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { favouriteBrandsSelector } from "../../store/metadata/selector";
import { brandItem } from "../../store/metadata/types";
import { DiscoverySagaActions } from "../../store/discovery/sagas";
import { useTheme } from "styled-components";

const FavouriteBrandDiscovery = () => {
  const favouriteBrands = useSelector(favouriteBrandsSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const handleViewAd = (brandId?: string) => {
    dispatch(DiscoverySagaActions.fetchBrandDiscoveryItems({ brandId }));
  };
  return (
    <FavouriteBrandContainer defaultExpanded={favouriteBrands.length > 0}>
      {favouriteBrands.length === 0 ? (
        <Box
          margin={"auto"}
          color={theme.colors.black2}
          fontWeight={500}
          fontSize={"0.938rem"}
        >
          No Favourite Brands
        </Box>
      ) : (
        <Grid container>
          {favouriteBrands
            .slice(0, 5)
            .map((brand: brandItem, index: number) => (
              <Grid item xs={12 / 5} key={index}>
                <TopBrandCard
                  buttonsToShow={["viewAdsButton"]}
                  isDiscovery
                  index={`0${index + 1}`}
                  item={brand}
                  disableFavourite={true}
                  handleViewAd={handleViewAd}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </FavouriteBrandContainer>
  );
};

export default FavouriteBrandDiscovery;
