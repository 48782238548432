import { MenuPopoverProps } from "../../types/Popover";
import { Menu, Stack, Typography } from "@mui/material";
import { useTheme } from "styled-components";
import { ClockIcon } from "../../assets/Icons";
import { StyledDivider } from "../../globalStyle";
import { ActiveBadge, InActiveBadge } from "../../pageComponents/style";

const ItemRow = ({ children }: { children: React.ReactNode }) => (
  <Stack
    direction="row"
    sx={{ alignItems: "center", gap: "0.625rem", cursor: "pointer" }}
  >
    {children}
  </Stack>
);

const StyledText = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="0.938rem"
      fontWeight="500"
      color={theme.colors.black2}
    >
      {children}
    </Typography>
  );
};

 const CustomDivider = () => (
  <StyledDivider sx={{ marginInline: "-0.938rem" }} />
);

const ActiveStatusPopover = ({
  anchorEl,
  handlePopoverClose,
}: MenuPopoverProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handlePopoverClose}
      variant="selectedMenu"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      sx={{
        ".MuiMenu-paper": {
          borderRadius: "0.938rem",
          padding: "0.938rem",
        },
        ".MuiList-root": {
          display: "flex",
          flexDirection: "column",
          gap: "0.938rem",
          padding:'0.5rem 0'
        },
        pointerEvents: "none",
      }}
    >
      <ItemRow>
        <StyledText>Active Status</StyledText>
      </ItemRow>
      <CustomDivider />
      <ItemRow>
        <ActiveBadge variant="dot" margin="0.313rem 0.313rem 0 0" />
        <StyledText>Apr 16, 2023</StyledText>
      </ItemRow>
      <CustomDivider />
      <ItemRow>
        <InActiveBadge variant="dot" margin="0.313rem 0.313rem 0 0" />
        <StyledText>Sep 16, 2023</StyledText>
      </ItemRow>
      <CustomDivider />
      <ItemRow>
        <ClockIcon />
        <StyledText>29 Days</StyledText>
      </ItemRow>
    </Menu>
  );
};

export default ActiveStatusPopover;
