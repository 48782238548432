import {
  Avatar,
  Box,
  Button,
  DialogTitle,
  Grid,
  Modal,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
} from "../modals/style";
import { CustomButton, StyledDivider } from "../../globalStyle";
import { SearchInput, SearchWrapper } from "../../pageComponents/style";
import { useTheme } from "styled-components";
import { FolderIconDark } from "../../assets/Icons";

const data = [
  {
    name: "Eleanor Pena",
    email: "bill.sanders@example.com",
    profileImage: null,
  },
  {
    name: "Eleanor Pena",
    email: "bill.sanders@example.com",
    profileImage: null,
  },
  {
    name: "Eleanor Pena",
    email: "bill.sanders@example.com",
    profileImage: null,
  },
  {
    name: "Eleanor Pena",
    email: "bill.sanders@example.com",
    profileImage: null,
  },
  {
    name: "Eleanor Pena",
    email: "bill.sanders@example.com",
    profileImage: null,
  },
];

const AddUserModal = ({
  openAddUserModal,
  setOpenAddUserModal,
}: {
  openAddUserModal: boolean;
  setOpenAddUserModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();

  return (
    <Modal
      open={openAddUserModal}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        outline: "none",
      }}
      onClose={() => setOpenAddUserModal(false)}
    >
      <Box
        display="flex"
        sx={{ minWidth: "890px", background: "white", borderRadius: "15px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          padding="1.875rem"
          width="40%"
        >
          <ModalTitle
            fontSize="16px !important"
            display="flex"
            gap="8px"
            alignItems="center"
          >
            <FolderIconDark /> Fashion Accessories
          </ModalTitle>
          <StyledDivider />
          <Typography fontWeight="600 !important" fontSize="16px !important">
            Access:
          </Typography>
          <Box
            border="1px solid #EAECEE"
            borderRadius="8px"
            display="flex"
            flexDirection="column"
            gap="15px"
            padding="20px"
            paddingBottom={0}
            maxHeight="24.5rem"
            overflow="scroll"
          >
            {data?.map((item, index) => (
              <>
                <Grid item sm={12} xl={6} key={index}>
                  <Box
                    style={{
                      display: "flex",
                      gap: "0.857rem",
                      alignItems: "start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Avatar
                      src={
                        item?.profileImage
                          ? item?.profileImage
                          : "assests/images/user-logo.png"
                      }
                      sx={{
                        width: "2.5rem",
                        height: "2.5rem",
                        marginLeft: "0.5rem",
                      }}
                    />
                    <Box>
                      <Typography
                        fontSize="1rem"
                        fontWeight="500"
                        color={"black"}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        fontSize="0.875rem"
                        fontWeight="400"
                        color={theme.colors.gray1}
                      >
                        {item?.email}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {data?.length - 1 != index && <StyledDivider />}
              </>
            ))}
          </Box>
        </Box>
        <StyledDivider orientation="vertical" variant="middle" flexItem />
        <Box
          display="flex"
          flexDirection="column"
          gap="15px"
          padding="1.875rem"
          width="60%"
        >
          <Box display="flex" flexDirection="column">
            <ModalTitle fontSize="16px !important">Add Users</ModalTitle>
            <FontGrayNormal>
              Search by name and email or invite new user.
            </FontGrayNormal>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            gap="10px"
            height="46px"
            width="100%"
          >
            <OutlinedInput
              placeholder="zuck@facebook.com"
              sx={{
                width: "60%",
                borderRadius: "20px !important",
                height: "100%",
                border: "1px solid #EAECEE !important",
                background: "#F6F7F8 !important",
                input: {
                  "&::placeholder": {
                    border: 0,
                    color: theme.colors.gray1,
                    fontWeight: 500,
                    opacity: 0.5,
                    outline: "none",
                  },
                },
              }}
            />
            <CustomButton
              style={{
                fontSize: "16px",
                color: theme.colors.gray1,
                borderRadius: "27px",
                border: "1px solid #EAECEE",
                padding: "14px 20px",
                height: "100%",
              }}
            >
              Send Invitation
            </CustomButton>
          </Box>
          <Box display="flex" flexDirection="column" gap="10px">
            <Typography fontWeight="600 !important" fontSize="16px !important">
              Team Members :
            </Typography>
            <Box
              border="1px solid #EAECEE"
              borderRadius="8px"
              display="flex"
              flexDirection="column"
              gap="15px"
              padding="20px"
              paddingBottom={0}
              maxHeight="19.5rem"
              overflow="scroll"
            >
              {data?.map((item, index) => (
                <>
                  <Grid item sm={12} xl={6} key={index}>
                    <Box
                      style={{
                        display: "flex",
                        gap: "0.857rem",
                        alignItems: "start",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Avatar
                        src={
                          item?.profileImage
                            ? item?.profileImage
                            : "assests/images/user-logo.png"
                        }
                        sx={{
                          width: "2.5rem",
                          height: "2.5rem",
                          marginLeft: "0.5rem",
                        }}
                      />
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          gap: "0.857rem",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            fontSize="1rem"
                            fontWeight="500"
                            color={"black"}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            fontSize="0.875rem"
                            fontWeight="400"
                            color={theme.colors.gray1}
                          >
                            {item?.email}
                          </Typography>
                        </Box>
                        <Box height="40px">
                          <CustomButton
                            style={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color: theme.colors.black2,
                              borderRadius: "20px",
                              border: "1px solid #EAECEE",
                              padding: "10px 20px",
                              height: "100%",
                            }}
                          >
                            Add
                          </CustomButton>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                  {data?.length - 1 != index && <StyledDivider />}
                </>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddUserModal;
