import {
  Avatar,
  Badge,
  Box,
  Card,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {
  CardContainer,
  CardDetailsWrapper,
  CardHeader,
  CardImage,
  CardMediaWrapper,
  SaveAdButton,
  ImageOverlay,
  VideoPlayButton,
  SaveAdButtonWrapper,
  CardDescription,
  SeeMoreButton,
  ViewDeatilsButton,
  VideoMuteButton,
} from "./style";
import { useTheme } from "styled-components";
import { useEffect, useRef, useState } from "react";
import {
  ArrowOutwardIcon,
  AdEyeIcon,
  MuteIcon,
  PauseIcon,
  PlayIcon,
  UnmuteIcon,
  AdShareIcon,
} from "../../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataReducerAction } from "../../../store/metadata/slice";
import { AdCardProps } from "../../../utils/propTypes";
import { formatCreatedDate, notify, ToastTypes } from "../../../utils/helpers";
import ImageSkeleton from "../../../assets/images/LoaderImage.jpg";
import { currentPlayingVideoSelector } from "../../../store/metadata/selector";
import AdSkeleton from "../adSkeleton";
import { NavLink } from "react-router-dom";

const AdCard = ({
  item,
  adDetailsCard,
  index,
  dataList,
  showRedirectIcon,
  hideSaveButton,
  handleViewAd,
  locationPathname,
}: AdCardProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [startVideo, setStartVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(false);
  const [seeMoreDescription, setSeeMoreDescription] = useState(false);

  const { currentPlayingVideo, setPlayButtonForCurrentVideo } = useSelector(
    currentPlayingVideoSelector
  );

  const handleSaveButtonClick = (event: React.SyntheticEvent) => {
    dispatch(MetaDataReducerAction.setSaveAdPopupAnchorEl(event.currentTarget));
    dispatch(MetaDataReducerAction.setSelectedSaveAdId(item._id));
    dispatch(MetaDataReducerAction.setAdSelectedBoardIds(item.board));
  };

  const videoRef = useRef<HTMLVideoElement>(null);

  const handleVideoPlay = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      const isPlaying =
        video.currentTime > 0 &&
        !video.paused &&
        !video.ended &&
        video.readyState > video.HAVE_CURRENT_DATA;

      if (!isPlaying) {
        setStartVideo(true);
        if (currentPlayingVideo && !(video === currentPlayingVideo)) {
          //Pause previous video before playing next
          currentPlayingVideo.pause();
          setPlayButtonForCurrentVideo(false);
        }
        dispatch(
          MetaDataReducerAction.setCurrentPlayingVideo({ video, setStartVideo })
        );
        video.play();
      } else {
        setStartVideo(false);
        video.pause();
      }
    }
  };

  const handleVideoMute = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      if (video.volume === 0) {
        setMuteVideo(false);
        video.volume = 1;
      } else {
        setMuteVideo(true);
        video.volume = 0;
      }
    }
  };

  const handleSeeMore = () => {
    setSeeMoreDescription(!seeMoreDescription);
  };

  useEffect(() => {
    // Reset video controls when item changes
    setStartVideo(false);
    setMuteVideo(false);
    if (currentPlayingVideo) {
      currentPlayingVideo.pause();
      setPlayButtonForCurrentVideo(false);
      dispatch(
        MetaDataReducerAction.setCurrentPlayingVideo({
          video: null,
          setStartVideo: false,
        })
      );
    }
  }, [item]);

  return (
    <>
      <CardContainer>
        {!item?._id ? (
          <AdSkeleton />
        ) : (
          <>
            <CardHeader>
              <CardDetailsWrapper>
                <div onClick={() => handleViewAd && handleViewAd(item?.pageId)}>
                  <Avatar
                    src={item?.page?.logo}
                    sx={{
                      width: "2.25rem",
                      height: "2.25rem",
                      cursor: handleViewAd ? "pointer" : "unset",
                    }}
                  ></Avatar>
                </div>
                <Stack width={"100%"}>
                  <div
                    onClick={() => handleViewAd && handleViewAd(item?.pageId)}
                  >
                    <Typography
                      fontSize="1rem"
                      color={theme.colors.black2}
                      fontWeight={700}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      whiteSpace={"nowrap"}
                      sx={{
                        cursor: handleViewAd ? "pointer" : "unset",
                      }}
                    >
                      {item.page.name}
                    </Typography>
                  </div>
                  <Stack
                    direction="row"
                    display={"flex"}
                    sx={{ alignItems: "baseline" }}
                    gap={"1.5rem"}
                  >
                    <Badge
                      variant="dot"
                      sx={{
                        ".MuiBadge-badge": {
                          top: "-0.35rem",
                          left: "-0.188rem",
                          backgroundColor: theme.colors.green,
                          boxShadow: `0 0 0 0.125rem rgba(59, 176, 113, 0.2)`,
                          height: "0.5rem",
                          minWidth: "0.5rem",
                        },
                      }}
                    />
                    <Typography
                      fontSize="0.938rem"
                      color={theme.colors.gray1}
                      fontWeight={400}
                    >
                      {formatCreatedDate(item.createdAt)}
                    </Typography>
                  </Stack>
                </Stack>
              </CardDetailsWrapper>
              <Box
                display={"flex"}
                alignItems={"center"}
                sx={{ marginLeft: "0.5rem" }}
              >
                {showRedirectIcon && (
                  <Link target={"_blank"} href={item.adTargetSourceURL}>
                    <ArrowOutwardIcon fill={theme.colors.black2} />
                  </Link>
                )}
                <ViewDeatilsButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${
                        window.location.origin + window.location.pathname
                      }/adDetails/${item._id}`
                    );
                    notify({
                      message: "Text copied to clipboard!",
                      type: ToastTypes.SUCCESS,
                    });
                  }}
                >
                  <AdShareIcon />
                </ViewDeatilsButton>
                {!adDetailsCard && dataList && (
                  <NavLink to={`${locationPathname}/adDetails/${item._id}`}>
                    <ViewDeatilsButton>
                      <AdEyeIcon />
                    </ViewDeatilsButton>
                  </NavLink>
                )}
              </Box>
            </CardHeader>
            {adDetailsCard && (
              <>
                <CardDescription>
                  <Typography
                    fontSize={"0.875rem"}
                    width={"100%"}
                    sx={{
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      ...(!seeMoreDescription && { WebkitLineClamp: 2 }),
                    }}
                    overflow={"hidden"}
                    color={theme.colors.black2}
                    fontWeight={400}
                  >
                    {item?.description}
                  </Typography>
                  <SeeMoreButton onClick={handleSeeMore}>
                    {item.description &&
                      (seeMoreDescription ? "See Less" : "See More")}
                  </SeeMoreButton>
                </CardDescription>
              </>
            )}
            <CardMediaWrapper>
              {!hideSaveButton && (
                <>
                  <SaveAdButtonWrapper onClick={handleSaveButtonClick}>
                    <SaveAdButton className="save-button">
                      {Array.isArray(item?.board) && item?.board?.length > 0
                        ? "Saved"
                        : "Save"}
                    </SaveAdButton>
                  </SaveAdButtonWrapper>
                  <ImageOverlay className="overlay" />
                </>
              )}
              <Card
                sx={{
                  width: "100%",
                  borderRadius: "0 0 10px 10px",
                  maxHeight: `${adDetailsCard && "40rem"}`,
                }}
              >
                {item.imageUrls[0] && (
                  <CardImage
                    src={item.imageUrls[0] ?? ImageSkeleton}
                    alt={"ad"}
                    loading="lazy"
                    style={{
                      objectFit:
                        adDetailsCard === true
                          ? "contain"
                          : adDetailsCard === false
                          ? "fill"
                          : undefined,
                    }}
                  />
                )}
                {item?.videoUrls[0] && (
                  <>
                    <video
                      ref={videoRef}
                      height="100%"
                      width="100%"
                      onEndedCapture={() => setStartVideo(false)}
                      key={item.videoUrls[0]}
                      style={{ display: "flex" }}
                    >
                      <source src={item.videoUrls[0]} type="video/webm" />
                    </video>
                    <VideoPlayButton onClick={handleVideoPlay}>
                      {startVideo ? <PauseIcon /> : <PlayIcon />}
                    </VideoPlayButton>
                    <VideoMuteButton onClick={handleVideoMute}>
                      {muteVideo ? <UnmuteIcon /> : <MuteIcon />}
                    </VideoMuteButton>
                  </>
                )}
              </Card>
            </CardMediaWrapper>
          </>
        )}
      </CardContainer>
    </>
  );
};

export default AdCard;
