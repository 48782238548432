import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import { useTheme } from "styled-components";
import { TextEditorButton, ToolbarContainer } from "./style";

const LowPriority = 1;

export default function ToolbarPlugin() {
  const theme = useTheme();
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
    }
  }, []);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  return (
    <ToolbarContainer>
      <Tooltip title="Bold">
        <TextEditorButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
          }}
          selected={isBold}
          aria-label="Format Bold"
        >
          <FormatBoldIcon sx={{ fill: theme.colors.black2 }} />
        </TextEditorButton>
      </Tooltip>
      <Tooltip title="Italic">
        <TextEditorButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
          }}
          selected={isItalic}
          aria-label="Format Italics"
        >
          <FormatItalicIcon sx={{ fill: theme.colors.black2 }} />
        </TextEditorButton>
      </Tooltip>
      <Tooltip title="Underline">
        <TextEditorButton
          onClick={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
          }}
          selected={isUnderline}
          aria-label="Format Underline"
        >
          <FormatUnderlinedIcon sx={{ fill: theme.colors.black2 }} />
        </TextEditorButton>
      </Tooltip>
    </ToolbarContainer>
  );
}
