import { Box, CircularProgress, DialogTitle, Stack } from "@mui/material";
import {
  CloseIconWrapper,
  FontGrayNormal,
  ModalIconWrapper,
  ModalTitle,
  StyledContainedButton,
  StyledDialog,
} from "./style";
import { CloseIcon, ImageGalleryIcon } from "../../assets/Icons";
import { useDispatch, useSelector } from "react-redux";

import { StyledDivider } from "../../globalStyle";
import {
  openAddTagModalLoadingSelector,
  openAddTagModalSelector,
  selectedTableItemsSelector,
} from "../../store/swipeFile/selector";
import TagsDropdown from "../commonComponents/tagsDropdown";
import { useState } from "react";
import { TagsReducerAction } from "../../store/tags/slice";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { SwipeFileSagaActions } from "../../store/swipeFile/sagas";

const AddTagModal = () => {
  const selectedTableItems = useSelector(selectedTableItemsSelector);
  const [selectedTagIds, setSelectedTagIds] = useState<string[]>([]);
  const dispatch = useDispatch();

  const open = useSelector(openAddTagModalSelector);
  const loading = useSelector(openAddTagModalLoadingSelector);

  const handleClose = () => {
    dispatch(SwipeFileReducerAction.setOpenAddTagModal(false));
  };

  const handleUpdateTags = (
    selectedTagsIds: string[],
  ) => {
    setSelectedTagIds(selectedTagsIds);
    dispatch(TagsReducerAction.setOpenTagsDropdown("false"));
  };

  const handleSubmit = () => {
    dispatch(
      SwipeFileSagaActions.addMultipleTagsToMultipleAds({
        adIds: selectedTableItems,
        tagIds: selectedTagIds,
      })
    );
  };

  return (
    <StyledDialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle sx={{ padding: "1.875rem 1.875rem 1.25rem 1.875rem" }}>
        <Stack direction="row" gap="0.75rem" alignItems="start">
          <ModalIconWrapper>
            <ImageGalleryIcon />
          </ModalIconWrapper>
          <Stack>
            <ModalTitle>
              Add Tags to {selectedTableItems?.length} Ads
            </ModalTitle>
            <FontGrayNormal>
              Add these tags to one or multiple ads
            </FontGrayNormal>
          </Stack>
        </Stack>
        <CloseIconWrapper aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      </DialogTitle>
      <StyledDivider />
      <Box sx={{ padding: "1.875rem" }}>
        <TagsDropdown
          showSelectedItemCount={2}
          selectedTags={[]}
          handleUpdateTag={handleUpdateTags}
        />
      </Box>
      <StyledDivider />
      <StyledContainedButton
        variant="contained"
        size="small"
        sx={{
          margin: "1.875rem 1.875rem 1.875rem auto",
          padding: "0.313rem 1.375rem",
        }}
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
        onClick={handleSubmit}
      >
        Add Tags
      </StyledContainedButton>
    </StyledDialog>
  );
};

export default AddTagModal;
