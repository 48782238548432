import {
  Avatar,
  Badge,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import Header from "../../components/header";
import { HeaderSection, ListContainer } from "../style";
import { useTheme } from "styled-components";
import { NotificationWrapper } from "./style";
import { CustomButton } from "../../globalStyle";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptJoinTeamLoaderSelector,
  notificationListSelector,
  notificationLoaderSelector,
  rejectJoinTeamLoaderSelector,
} from "../../store/metadata/selector";
import { notificationType } from "../../store/metadata/types";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { renderNotificationButtonText } from "../../utils/constants";
import { formatCreatedDate } from "../../utils/helpers";
import { useEffect } from "react";
import CreateBoardModal from "../../components/modals/createBoardModal";
import { convertHtmlToText } from "../../components/notificationPopover";

const content = {
  tabTitle: "Notifications",
  tabSubTitle: "All new updates, you receive here!",
};

const Notification = () => {
  const theme = useTheme();
  const notificationList = useSelector(notificationListSelector);
  const dispatch = useDispatch();
  const loading = useSelector(notificationLoaderSelector);

  const acceptInvitationLoader = useSelector(acceptJoinTeamLoaderSelector);
  const rejectInvitationLoader = useSelector(rejectJoinTeamLoaderSelector);

  const handleJoin = (
    notificationId: string,
    teamId: string,
    status: boolean
  ) => {
    dispatch(
      MetaDataSagaActions.joinTeam({
        notificationId: notificationId,
        teamId: teamId,
        status: status,
      })
    );
  };
  useEffect(() => {
    dispatch(MetaDataSagaActions.notificationList());
  }, []);
  return (
    <>
      <HeaderSection>
        <Header content={content} />
        <ListContainer>
          <NotificationWrapper>
            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CircularProgress />
              </Box>
            ) : (
              <>
                {notificationList?.map(
                  (notification: notificationType, index: number) => {
                    const titleText = convertHtmlToText(notification.title);
                    return (
                      <div
                        key={notification._id}
                        style={{
                          borderBottom: `1px solid ${theme.colors.whisper}`,
                        }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            position: "relative",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              margin: "4.25rem 0.75rem 1.25rem 1.125rem",
                              position: "relative",
                            }}
                          >
                            {!notification.read && (
                              <Badge
                                variant="dot"
                                sx={{
                                  ".MuiBadge-badge": {
                                    position: "absolute",
                                    backgroundColor: `${theme.colors.radicalRed}`,
                                    height: "0.5rem",
                                    minWidth: "0.5rem",
                                    top: "-1.2rem",
                                  },
                                }}
                              />
                            )}
                          </div>

                          <Avatar
                            src={
                              notification?.imageUrl ||
                              "assests/images/user-image.png"
                            }
                            sx={{
                              width: "4rem",
                              height: "4rem",
                              borderRadius: "50%",
                            }}
                          />
                          <Stack
                            sx={{
                              marginLeft: "0.938rem",
                              gap: "0.625rem",
                              width: "75%",
                              flexWrap: "wrap",
                            }}
                          >
                            <Typography
                              fontSize="1rem"
                              fontWeight="600"
                              color={theme.colors.black2}
                              lineHeight="1.524rem"
                            >
                              {titleText}
                            </Typography>
                            <Typography
                              fontSize="0.8rem"
                              fontWeight="400"
                              color={theme.colors.gray1}
                              lineHeight="1.524rem"
                            >
                              {notification.description}
                            </Typography>
                          </Stack>
                          <Typography
                            fontSize="0.8rem"
                            fontWeight="500"
                            whiteSpace="nowrap"
                            color={theme.colors.black2}
                            lineHeight="1.524rem"
                            alignSelf="start"
                            marginTop="0.7rem"
                          >
                            {formatCreatedDate(notification?.createdAt ?? "")}
                          </Typography>
                        </Stack>
                        {notification.type === renderNotificationButtonText && (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              gap: "0.625rem",
                              marginLeft: "6.6rem",
                              marginBottom: "1rem",
                            }}
                          >
                            <CustomButton
                              sx={{
                                padding: "0.375rem 0.938rem",
                                border: `1px solid ${theme.colors.border1}`,
                                background: `${theme.colors.white}`,
                                color: `${theme.colors.gray1}`,
                                borderRadius: "3.125rem",
                              }}
                              disabled={rejectInvitationLoader}
                              startIcon={
                                rejectInvitationLoader && (
                                  <CircularProgress size={22} color="inherit" />
                                )
                              }
                              onClick={() =>
                                handleJoin(
                                  notification._id,
                                  notification.teamId,
                                  true
                                )
                              }
                            >
                              Decline
                            </CustomButton>
                            <CustomButton
                              style={{
                                padding: "0.375rem 0.938rem",
                                border: `1px solid ${theme.colors.border1}`,
                                background: `${theme.colors.backgroundPrimary}`,
                                color: `${theme.colors.white}`,
                                borderRadius: "3.125rem",
                              }}
                              disabled={acceptInvitationLoader}
                              startIcon={
                                acceptInvitationLoader && (
                                  <CircularProgress size={22} color="inherit" />
                                )
                              }
                              onClick={() =>
                                handleJoin(
                                  notification._id,
                                  notification.teamId,
                                  true
                                )
                              }
                            >
                              Accept
                            </CustomButton>
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </>
            )}
          </NotificationWrapper>
        </ListContainer>
      </HeaderSection>
      <CreateBoardModal />
    </>
  );
};

export default Notification;
