import { createTheme } from "@mui/material";

export const theme = {
  colors: {
    white: "#FFFFFF",
    lightBlue: "#00AEEF",
    neutral100: "#0E0E0E",
    blue: "#1C75BC",
    gray1: "#58595B",
    darkGray: "#B7B8C2",
    black2: "#414042",
    border1: "#EAECEE",
    green: "#3BB071",
    black3: "#081720",
    border2: "#F5F5F5",
    border3: "#E6E6E6",
    neutral50: "#797575",
    link: "#2563EB",
    neutral80: "#383635",
    background: "#F9F9FA",
    background2: "#EFFAFF",
    background3: "#F1F1F1",
    background4: "#FAFAFA",
    red200: "#FD3867",
    brandRed: "#ED1C24",
    backgroundPrimary: "linear-gradient(135deg, #1c75bc, #00aeef)",
    whiteSmoke: "#EAEAEA",
    whisper: "#EEEEEE",
    radicalRed: "#FF3347",
    mintCream: "#EDFCF5",
    cyan: "#D4F7E6",
    green1: "#1EAF83",
    lavenderBlush: "#FFF0F2",
    mistyRose: "#FFE3E7",
    error: "#F44336",
    warning: "#FFA92B",
    info: "#47A8F5",
    richBlack: "#081720",
    gray97: "#F7F7F7",
  },
  fontSizes: {},
};

export const LexicalTextEditorTheme = {
  ltr: "ltr",
  text: {
    bold: "editor-text-bold",
    italic: "editor-text-italic",
    underline: "editor-text-underline",
  },
};

export const MuiTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiMenu: {
      defaultProps: {
        disableScrollLock: true,
      },
    },
  },
});
