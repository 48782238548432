import { CircularProgress, Stack, TextField, Tooltip } from "@mui/material";
import { CardContainer } from "../dashboardSections/style";
import { BoardIcon, DeleteIcon, EditIcon, EyeIcon } from "../../assets/Icons";
import { useTheme } from "styled-components";
import { StyledBoardTextField } from "../modals/style";
import { CustomButton } from "../../globalStyle";
import { BoardItem } from "../../store/metadata/types";
import { useForm } from "react-hook-form";
import { UpdateBoardValueProps } from "../../utils/propTypes";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateBoardValidationSchema } from "../../utils/formValidationSchema";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MetaDataSagaActions } from "../../store/metadata/sagas";
import { SwipeFileReducerAction } from "../../store/swipeFile/slice";
import { useNavigate } from "react-router-dom";
import routeConstant from "../../utils/routeConstant";
import { MetaDataReducerAction } from "../../store/metadata/slice";
import { userDetailsSelector } from "../../store/account/selector";
import { DiscoveryReducerAction } from "../../store/discovery/slice";

export const EditableBoardCard = ({
  item,
  folderId,
}: {
  item: BoardItem;
  folderId: string;
}) => {
  // use hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountDetails = useSelector(userDetailsSelector);

  // states
  const [editBoardName, setEditBoardName] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // form
  const { getValues, handleSubmit, register } = useForm<UpdateBoardValueProps>({
    defaultValues: {
      name: item.name,
    },
    resolver: yupResolver(updateBoardValidationSchema),
  });

  // functions
  const onSubmit = async () => {
    const values = getValues();
    setIsLoading(true);
    dispatch(
      MetaDataSagaActions.updateBoard({
        boardId: item._id,
        name: values.name,
        resetState: resetState,
        folderId: folderId,
      })
    );
  };

  const resetState = () => {
    setIsLoading(false);
    setEditBoardName(true);
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    submitHandler: () => void
  ) => {
    if (e.key === "Enter") {
      e.preventDefault();
      submitHandler();
    }
  };

  const handleDelete = () => {
    setIsLoading(true);
    dispatch(
      MetaDataSagaActions.deleteBoard({
        boardId: item._id,
        folderId: folderId,
        resetState: resetState,
      })
    );
  };

  const handleRedirection = (userId: string) => {
    if (userId !== accountDetails._id) {
      setEditBoardName(false);
      dispatch(DiscoveryReducerAction.setFilterOptionSkipValue(0));
      dispatch(DiscoveryReducerAction.setDiscoveryItemToInitialSet([]));
      dispatch(DiscoveryReducerAction.setSelectedBoardId(item._id));
      dispatch(DiscoveryReducerAction.setDiscoveryTabIndex(1));

      dispatch(MetaDataReducerAction.setFolderItem(null));
      dispatch(MetaDataReducerAction.setOpenViewFolderModal(false));
      navigate(routeConstant.discovery);
    } else {
      setEditBoardName(false);
      dispatch(SwipeFileReducerAction.setFilterOptionSkipValue(0));
      dispatch(SwipeFileReducerAction.setSwipeFileItemToInitialSet([]));
      dispatch(SwipeFileReducerAction.setSelectedBoardId(item._id));

      dispatch(MetaDataReducerAction.setFolderItem(null));
      dispatch(MetaDataReducerAction.setOpenViewFolderModal(false));
      navigate(routeConstant.swipeFiles);
    }
  };
  return (
    <CardContainer
      display={"flex"}
      justifyContent={"space-between"}
      padding={"0.938rem 0rem 0.938rem 1.25rem"}
      width={"29.125rem"}
    >
      <Stack direction="row" gap="0.625rem" alignItems={"center"}>
        {isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <BoardIcon fill={theme.colors.gray1} />
        )}
        <StyledBoardTextField
          id="name"
          disabled={editBoardName}
          variant="standard"
          InputProps={{
            disableUnderline: editBoardName,
          }}
          {...register("name", { required: true })}
          onKeyDown={(e) => onKeyDown(e, handleSubmit(onSubmit))}
        />
      </Stack>
      <Stack
        direction="row"
        gap="0.5rem"
        alignItems={"center"}
        marginRight={"0.938rem"}
        sx={{
          opacity: isLoading ? "0.4" : "1",
          pointerEvents: isLoading ? "none" : "auto",
        }}
      >
        <Tooltip title="View Ads">
          <CustomButton
            sx={{ ":hover": { background: theme.colors.background4 } }}
            onClick={() => handleRedirection(item.userId as string)}
          >
            <EyeIcon color="#414042" />
          </CustomButton>
        </Tooltip>
        <Tooltip title="Edit">
          <CustomButton
            sx={{ ":hover": { background: theme.colors.background4 } }}
            onClick={() => setEditBoardName(!editBoardName)}
            disabled={isLoading}
          >
            <EditIcon />
          </CustomButton>
        </Tooltip>
        <Tooltip title="Delete">
          <CustomButton
            sx={{ ":hover": { background: theme.colors.background4 } }}
            onClick={handleDelete}
            disabled={isLoading}
          >
            <DeleteIcon />
          </CustomButton>
        </Tooltip>
      </Stack>
    </CardContainer>
  );
};
