import axiosClient from "../apiClient";

type communityParamType = {
  userId?: string;
  team: boolean;
  selectedPlatformIds?: string[];
  selectedBoardIds?: string[];
  limit: number;
  skip: number;
  search?: string;
  createdAt?: number;
  startedRunningDate?: number;
  sort?: Object;
  selectedTagIds?: string[];
};

export const getCommunityAdsList = async (params: communityParamType) => {
  try {
    let response;
    response = await axiosClient.post("/ad/list", params);
    return response;
  } catch (error) {
    return error;
  }
};
